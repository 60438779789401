import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Modal, Snackbar } from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "80%",
    height: "80%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimplePopover(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const copyToClipboard = () => {
    let rowTxt = "";
    const dataToCopy = props.rows.map((row) => {
      rowTxt = "";
      props.columns.map((item, index) => {
        rowTxt =
          rowTxt +
          `${row[item.field]}` +
          (props.columns.length - 1 !== index ? "\t" : "");
      });
      return rowTxt;
    });

    let headerNames = "";
    props.columns.map((item, index) => {
      headerNames =
        headerNames +
        `${item.headerName}` +
        (props.columns.length - 1 !== index ? "\t" : "");
    });
    dataToCopy.unshift(headerNames);

    const textToCopy = dataToCopy.join("\n");

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setOpen(true);
      })
      .catch((error) => console.error("Copy failed: ", error));
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Data Copied To Clipboard !
        </Alert>
      </Snackbar>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Button
            onClick={copyToClipboard}
            variant="contained"
            color="primary"
            style={{ margin: "12px" }}
          >
            Copy
          </Button>
          <DataGrid
            rows={props.rows}
            columns={props.columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Modal>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
