import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import '../patient/Create-patient.css';
import { lightGreen } from '@material-ui/core/colors';
import { updateOnProps } from '../../helper/utilities';

const style = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        background: 'white',
        padding: 32,
    },
    formControl: {
        margin: theme.spacing(1),
        width: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(4),
    },
    textField: {
        margin: theme.spacing(2),
        width: 250,
    },
    addressTextField: {
        margin: theme.spacing(1),
        width: 250,
    },
    menu: {
        width: 150,
    },
    submitbutton: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText(lightGreen[500]),
        backgroundColor: lightGreen[500],
        '&:hover': {
            backgroundColor: lightGreen[600],
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    genituorinaryClass: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
});

class Socioeconomicstatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            socioEconomicStatus_housingFacility: this.props.data.socioEconomicStatus_housingFacility,
            socioEconomicStatus_waterSupply: this.props.data.socioEconomicStatus_waterSupply,
            socioEconomicStatus_sanitation: this.props.data.socioEconomicStatus_sanitation,
            socioEconomicStatus_monthlyIncome: this.props.data.socioEconomicStatus_monthlyIncome,
            socioEconomicStatus_travelHistory: this.props.data.socioEconomicStatus_travelHistory,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        updateOnProps(
            prevProps,
            [
                'socioEconomicStatus_housingFacility',
                'socioEconomicStatus_waterSupply',
                'socioEconomicStatus_sanitation',
                'socioEconomicStatus_monthlyIncome',
                'socioEconomicStatus_travelHistory',
            ],
            this
        );
    }

    handleChange(e) {
        this.props.handleChange(e);
    }
    handlechange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(evt) {
        evt.preventDefault();
    }

    componentDidMount() {}

    render() {
        const { classes } = this.props;

        return (
            <div>
                <TextField
                    label='Housing Facility'
                    value={this.state.socioEconomicStatus_housingFacility}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    name='socioEconomicStatus_housingFacility'
                    className={classes.textField}
                />
                <TextField
                    label='Water Supply'
                    value={this.state.socioEconomicStatus_waterSupply}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    name='socioEconomicStatus_waterSupply'
                    className={classes.textField}
                />
                <TextField
                    label='Sanitation'
                    value={this.state.socioEconomicStatus_sanitation}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    name='socioEconomicStatus_sanitation'
                    className={classes.textField}
                />
                <TextField
                    label='Monthly Income'
                    type='number'
                    value={this.state.socioEconomicStatus_monthlyIncome}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    name='socioEconomicStatus_monthlyIncome'
                    className={classes.textField}
                />

                <TextField
                    label='Travel History'
                    value={this.state.socioEconomicStatus_travelHistory}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    name='socioEconomicStatus_travelHistory'
                    className={classes.textField}
                />
            </div>
        );
    }
}

export default withStyles(style)(Socioeconomicstatus);
