import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import { updateOnProps } from "../../helper/utilities";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  spacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField2: {
    margin: theme.spacing(1),
  },
  formControl2: {
    margin: theme.spacing(1),
    width: "100%",
  },
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
    margin: theme.spacing(2),
  },
});

class Musculoskeletal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Muscoskeletal_system_muscle_wasting_result:
        this.props.data.Muscoskeletal_system_muscle_wasting_result,
      Muscoskeletal_system_muscle_wasting_Specify:
        this.props.data.Muscoskeletal_system_muscle_wasting_Specify,
      Muscoskeletal_system_weakness_result:
        this.props.data.Muscoskeletal_system_weakness_result,
      Muscoskeletal_system_weakness_Specify:
        this.props.data.Muscoskeletal_system_weakness_Specify,
      Muscoskeletal_system_hemihypertrophy_result:
        this.props.data.Muscoskeletal_system_hemihypertrophy_result,
      Muscoskeletal_system_hemihypertrophy_Specify:
        this.props.data.Muscoskeletal_system_hemihypertrophy_Specify,
      Muscoskeletal_system_joint_swelling_result:
        this.props.data.Muscoskeletal_system_joint_swelling_result,
      Muscoskeletal_system_joint_swelling_Specify:
        this.props.data.Muscoskeletal_system_joint_swelling_Specify,
      Muscoskeletal_system_spine_result:
        this.props.data.Muscoskeletal_system_spine_result,
      Muscoskeletal_system_spine_Specify:
        this.props.data.Muscoskeletal_system_spine_Specify,
      Muscoskeletal_system_sacrum_result:
        this.props.data.Muscoskeletal_system_sacrum_result,
      Muscoskeletal_system_sacrum_Specify:
        this.props.data.Muscoskeletal_system_sacrum_Specify,
      Muscoskeletal_system_limb_deformity_result:
        this.props.data.Muscoskeletal_system_limb_deformity_result,
      Muscoskeletal_system_limb_deformity_Specify:
        this.props.data.Muscoskeletal_system_limb_deformity_Specify,

      Muscoskeletal_system_wrist_swelling_result:
        this.props.data.Muscoskeletal_system_wrist_swelling_result,
      Muscoskeletal_system_wrist_swelling_Specify:
        this.props.data.Muscoskeletal_system_wrist_swelling_Specify,
      musculo_other: this.props.data.musculo_other,
      musculo_look: this.props.data.musculo_look,
      musculo_feel: this.props.data.musculo_feel,
      musculo_move: this.props.data.musculo_move,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "Muscoskeletal_system_muscle_wasting_result",
        "Muscoskeletal_system_muscle_wasting_Specify",
        "Muscoskeletal_system_weakness_result",
        "Muscoskeletal_system_weakness_Specify",
        "Muscoskeletal_system_hemihypertrophy_result",
        "Muscoskeletal_system_hemihypertrophy_Specify",
        "Muscoskeletal_system_joint_swelling_result",
        "Muscoskeletal_system_joint_swelling_Specify",
        "Muscoskeletal_system_spine_result",
        "Muscoskeletal_system_spine_Specify",
        "Muscoskeletal_system_sacrum_result",
        "Muscoskeletal_system_sacrum_Specify",
        "Muscoskeletal_system_limb_deformity_result",
        "Muscoskeletal_system_limb_deformity_Specify",

        "Muscoskeletal_system_wrist_swelling_result",
        "Muscoskeletal_system_wrist_swelling_Specify",
        "musculo_other",
        "musculo_look",
        "musculo_feel",
        "musculo_move",
      ],
      this,
    );
  }

  handleChange(e) {
    // this.setState({[e.target.name]:e.target.value});
    this.props.handleChange(e);
  }
  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.divStyle}>
          <h2>8.7 Msuculoskeletal System :</h2>
        </div>

        <div style={{ margin: "32px" }}>
          <TextField
            label="Look"
            value={this.state.musculo_look}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="musculo_look"
            className={classes.textfieldfull}
            fullWidth
          />

          <TextField
            label="Feel"
            value={this.state.musculo_feel}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="musculo_feel"
            className={classes.textfieldfull}
            fullWidth
          />
          <TextField
            label="Move"
            value={this.state.musculo_move}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="musculo_move"
            className={classes.textfieldfull}
            fullWidth
          />
          <TextField
            label="Other"
            value={this.state.musculo_other}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="musculo_other"
            fullWidth
          />
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Musculoskeletal);
