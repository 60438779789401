import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: 8,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {props.name}:
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {props.name} details
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{ whiteSpace: "pre-line" }}
        >
          {props.text}
        </Typography>
      </CardContent>
    </Card>
  );
}

SimpleCard.defaultProps = {
  name: "summary",
  text: "Details",
};
