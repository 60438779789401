import React, { Component } from "react";
// import axios from 'axios';
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
// import SearchAppBar from '../layout/Appbar';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { updateOnProps } from "../../helper/utilities";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  formControlNext: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  textField2: {
    margin: theme.spacing(1),
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  spacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  hide: {
    visibility: "hidden",
    margin: theme.spacing(1),
    width: 395,
  },
  marginRadio: {
    margin: theme.spacing(1),
  },
  formControlNew: {
    margin: theme.spacing(2),
    width: "100%",
  },
  formControlAlt: {
    // margin: theme.spacing(2),
    width: "100%",
  },
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
    margin: theme.spacing(2),
  },
});

class Chest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Chest_Inspection: this.props.data.Chest_Inspection,
      Chest_shapeofchest: this.props.data.Chest_shapeofchest,
      Chest_respiratoryPattern: this.props.data.Chest_respiratoryPattern,
      Chest_visibleImpulse: this.props.data.Chest_visibleImpulse,
      Chest_visibleImpulseSpecify: this.props.data.Chest_visibleImpulseSpecify,
      Chest_ScarMarks: this.props.data.Chest_ScarMarks,
      Chest_ScarMarksSpecify: this.props.Chest_ScarMarksSpecify,

      Chest_Palpation: this.props.data.Chest_Palpation,
      Chest_positionoftrachea: this.props.data.Chest_positionoftrachea,
      Chest_apexBeat: this.props.data.Chest_apexBeat,
      Chest_vocalFremitus: this.props.data.Chest_vocalFremitus,
      Chest_expansibility: this.props.data.Chest_expansibility,

      Chest_Percussion: this.props.data.Chest_Percussion,
      Chest_percussionNote: this.props.data.Chest_percussionNote,
      Chest_percussionNoteNext: this.props.data.Chest_percussionNoteNext,

      Chest_Auscultation: this.props.data.Chest_Auscultation,
      Chest_BreathSound: this.props.data.Chest_BreathSound,
      Chest_addedSound: this.props.data.Chest_addedSound,
      Chest_barrelShape_result: this.props.data.Chest_barrelShape_result,
      Chest_barrelShape_Specify: this.props.data.Chest_barrelShape_Specify,
      Chest_pectusShape_result: this.props.data.Chest_pectusShape_result,
      Chest_pectusShape_Specify: this.props.data.Chest_pectusShape_Specify,
      other: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "Chest_Inspection",
        "Chest_shapeofchest",
        "Chest_respiratoryPattern",
        "Chest_visibleImpulse",
        "Chest_visibleImpulseSpecify",
        "Chest_ScarMarks",
        "Chest_ScarMarksSpecify",

        "Chest_Palpation",
        "Chest_positionoftrachea",
        "Chest_apexBeat",
        "Chest_vocalFremitus",
        "Chest_expansibility",

        "Chest_Percussion",
        "Chest_percussionNote",
        "Chest_percussionNoteNext",

        "Chest_Auscultation",
        "Chest_BreathSound",
        "Chest_addedSound",
        "Chest_barrelShape_result",
        "Chest_barrelShape_Specify",
        "Chest_pectusShape_result",
        "Chest_pectusShape_Specify",
        "other",
      ],
      this,
    );
  }

  handleChange(e) {
    this.props.handleChange(e);
  }
  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (
      e.target.name === "Chest_percussionNote" &&
      e.target.value === "Other"
    ) {
      this.setState({ other: true });
    } else if (
      e.target.name === "Chest_percussionNote" &&
      e.target.value !== "Other"
    ) {
      this.setState({ other: false });
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.divStyle}>
          <h2>8.4 Respiratory System :</h2>
        </div>
        <div style={{ margin: "32px" }}>
          <h3>8.4.1 Inspection</h3>
          <TextField
            value={this.state.Chest_Inspection}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_Inspection"
            fullWidth
          />
          <TextField
            label="Shape Of Chest"
            value={this.state.Chest_shapeofchest}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_shapeofchest"
            fullWidth
          />
          <TextField
            label="Respiratory Pattern"
            value={this.state.Chest_respiratoryPattern}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_respiratoryPattern"
            fullWidth
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Visible Impulse</FormLabel>
            <RadioGroup
              aria-label="position"
              name="Chest_visibleImpulse"
              label="Chest_visibleImpulse"
              value={this.state.Chest_visibleImpulse}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              row
            >
              <FormControlLabel
                value={"PRESENT"}
                control={<Radio color="secondary" />}
                label="PRESENT"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"ABSENT"}
                control={<Radio color="secondary" />}
                label="ABSENT"
                labelPlacement="end"
              />
              <div className={classes.textField}></div>
              {/* <TextField
                label="Specify"
                value={this.state.Chest_visibleImpulseSpecify}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="Chest_visibleImpulseSpecify"
                className={classes.textField}
              /> */}
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Scar Marks</FormLabel>
            <RadioGroup
              aria-label="position"
              name="Chest_ScarMarks"
              label="Chest_ScarMarks"
              value={this.state.Chest_ScarMarks}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              row
            >
              <FormControlLabel
                value={"PRESENT"}
                control={<Radio color="secondary" />}
                label="PRESENT"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"ABSENT"}
                control={<Radio color="secondary" />}
                label="ABSENT"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.Chest_ScarMarksSpecify}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="Chest_ScarMarksSpecify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{ margin: "32px" }}>
          <h3>8.4.2 Palpation</h3>

          <TextField
            value={this.state.Chest_Palpation}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_Palpation"
            fullWidth
          />
          <TextField
            label="Position of Trachea"
            value={this.state.Chest_positionoftrachea}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_positionoftrachea"
            fullWidth
          />
          <TextField
            label="Apex Beat"
            value={this.state.Chest_apexBeat}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_apexBeat"
            fullWidth
          />
          <FormControl required className={classes.formControlAlt}>
            <InputLabel htmlFor="title">Vocal Fremitus</InputLabel>
            <Select
              onBlur={this.handleChange}
              value={this.state.Chest_vocalFremitus}
              onChange={this.handlechange}
              name="Chest_vocalFremitus"
              fullWidth
            >
              <MenuItem value={"Normal"}>Normal</MenuItem>
              <MenuItem value={"Increased"}>Increased</MenuItem>
              <MenuItem value={"Decreased"}>Decreased</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Chest Expansibility"
            value={this.state.Chest_expansibility}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_expansibility"
            fullWidth
          />

          <br />
        </div>

        <div style={{ margin: "32px" }}>
          <h3>8.4.3 Percussion</h3>
          <TextField
            value={this.state.Chest_Percussion}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_Percussion"
            fullWidth
          />

          <FormControl required margin="normal" style={{ width: "100%" }}>
            <InputLabel htmlFor="title">percussion Note</InputLabel>
            <Select
              onBlur={this.handleChange}
              value={this.state.Chest_percussionNote}
              onChange={this.handlechange}
              name="Chest_percussionNote"
              fullWidth
            >
              <MenuItem value={"Normal"}>Normal</MenuItem>
              <MenuItem value={"Increased"}>Increased</MenuItem>
              <MenuItem value={"Dull"}>Dull</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>

          <Box display={this.state.other ? "" : "none"}>
            <TextField
              label="Percussion Note Other"
              value={this.state.Chest_percussionNoteNext}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Chest_percussionNoteNext"
              fullWidth
            />
          </Box>
        </div>

        <br />
        <div style={{ margin: "32px" }}>
          <h3>8.4.4 Auscultation</h3>

          <TextField
            value={this.state.Chest_Auscultation}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Chest_Auscultation"
            fullWidth
          />
          <FormControl required margin="normal" style={{ width: "100%" }}>
            <InputLabel htmlFor="title">Breath Sound</InputLabel>
            <Select
              onBlur={this.handleChange}
              value={this.state.Chest_BreathSound}
              onChange={this.handlechange}
              name="Chest_BreathSound"
              fullWidth
            >
              <MenuItem value={"Vesicular"}>Vesicular</MenuItem>
              <MenuItem value={"Bronchial"}>Bronchial</MenuItem>
              <MenuItem value={"Vesicular with Prolonged Expiration"}>
                Vesicular with Prolonged Expiration
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl required margin="normal" style={{ width: "100%" }}>
            <InputLabel htmlFor="title">Added Sound</InputLabel>
            <Select
              onBlur={this.handleChange}
              value={this.state.Chest_addedSound}
              onChange={this.handlechange}
              name="Chest_addedSound"
              fullWidth
            >
              <MenuItem value={"Wheeze"}>Wheeze</MenuItem>
              <MenuItem value={"Wheez + Crepitation"}>
                Wheez + Crepitation
              </MenuItem>
              <MenuItem value={"Crepitation"}>Crepitation</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Chest);
