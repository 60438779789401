import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import { updateOnProps } from "../../helper/utilities";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  spacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField2: {
    margin: theme.spacing(1),
  },
  formControl2: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formControlNew: {
    margin: theme.spacing(2),
    width: "100%",
  },
  formControlAlt: {
    // margin: theme.spacing(2),
    width: "100%",
  },
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
    margin: theme.spacing(2),
  },
});

class CNS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cns_Inspection: this.props.data.cns_Inspection,
      cns_Palpation: this.props.data.cns_Palpation,
      cns_Percussion: this.props.data.cns_Percussion,
      cns_Auscultation: this.props.data.cns_Auscultation,
      CNS_mental_status_result: this.props.data.CNS_mental_status_result,
      CNS_mental_status_Specify: this.props.data.CNS_mental_status_Specify,
      CNS_tone_result: this.props.data.CNS_tone_result,
      CNS_tone_Specify: this.props.data.CNS_tone_Specify,
      CNS_posture_result: this.props.data.CNS_posture_result,
      CNS_posture_Specify: this.props.data.CNS_posture_Specify,
      CNS_reflexes_result: this.props.data.CNS_reflexes_result,
      CNS_reflexes_Specify: this.props.data.CNS_reflexes_Specify,

      CNS_co_ordination_result: this.props.data.CNS_co_ordination_result,
      CNS_co_ordination_Specify: this.props.data.CNS_co_ordination_Specify,
      cns_motorFunctionsAndReflexes:
        this.props.data.cns_motorFunctionsAndReflexes,
      cns_cerebellarFunction: this.props.data.cns_cerebellarFunction,
      cns_sensory: this.props.data.cns_sensory,
      cns_cranialNerves: this.props.data.cns_cranialNerves,
      cns_gait: this.props.data.cns_gait,
      General_Intellectual_Functions:
        this.props.data.General_Intellectual_Functions,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "cns_Inspection",
        "cns_Palpation",
        "cns_Percussion",
        "cns_Auscultation",
        "CNS_mental_status_result",
        "CNS_mental_status_Specify",
        "CNS_tone_result",
        "CNS_tone_Specify",
        "CNS_posture_result",
        "CNS_posture_Specify",
        "CNS_reflexes_result",
        "CNS_reflexes_Specify",

        "CNS_co_ordination_result",
        "CNS_co_ordination_Specify",
        "cns_motorFunctionsAndReflexes",
        "cns_cerebellarFunction",
        "cns_sensory",
        "cns_cranialNerves",
        "cns_gait",
        "General_Intellectual_Functions",
      ],
      this,
    );
  }
  handleChange(e) {
    this.props.handleChange(e);
  }
  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.divStyle}>
          <h2>8.6 CNS :</h2>
        </div>

        <div style={{ margin: "32px" }}>
          <TextField
            label="General Intellectual Functions"
            value={this.state.General_Intellectual_Functions}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="General_Intellectual_Functions"
            fullWidth
          />

          <TextField
            label="Motor Functions And Reflexes"
            value={this.state.cns_motorFunctionsAndReflexes}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="cns_motorFunctionsAndReflexes"
            fullWidth
          />
          <TextField
            label="Cerebellar Function"
            value={this.state.cns_cerebellarFunction}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="cns_cerebellarFunction"
            fullWidth
          />
          <TextField
            label="Sensory"
            value={this.state.cns_sensory}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="cns_sensory"
            fullWidth
          />
          <TextField
            label="Cranial Nerves"
            value={this.state.cns_cranialNerves}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="cns_cranialNerves"
            fullWidth
          />
          <TextField
            label="Gait"
            value={this.state.cns_gait}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="cns_gait"
            fullWidth
          />
        </div>
      </div>
    );
  }
}

export default withStyles(style)(CNS);
