import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: grey[50],
      grey3: grey[300],
      grey7: grey[700],
      grey8: grey[800],
      grey9: grey[900],
    },

    secondary: {
      main: "#8BC34A",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// registerServiceWorker();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// registerServiceWorker();
