import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { TextField, Button } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Comment';
import { grey } from '@material-ui/core/colors';
import axios from 'axios';

function getModalStyle() {
	const top = 85;
	const left = 95;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: ' 0 10px 40px 0 rgba(0, 0, 0, 0.07), 0 2px 9px 0 rgba(0, 0, 0, 0.06)',
		padding: theme.spacing(2, 4, 3),
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		borderRadius: '5px',
		outline: 'none !important',
	},
	margin: {
		margin: theme.spacing(1),
		color: 'white',
		backgroundColor: theme.palette.secondary.light,
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	bottomRight: {
		position: 'fixed',
		bottom: '5%',
		right: '0%',
		zIndex: '1000',
	},
	color: {
		color: 'white',
		marginBottom: theme.spacing(2),
	},
	marginh2: {
		marginBottom: theme.spacing(2),
	},
	grey9: {
		color: grey[800],
		display: 'flex',
	},
}));

export default function SimpleModal() {
	const classes = useStyles();

	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(false);
	let comment = '';

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (event) => {
		comment = event.target.value;
	};
	const handleSubmit = () => {
		const data = { comment: comment };
		axios
			.post(
				(process.env.NODE_ENV === 'development' ? 'http://localhost:40000' : '') +
					'/api/comment',
				data
			)
			.then(() => {})
			.catch((err) => {
				console.error(err);
			});
		setOpen(false);
	};

	return (
		<div>
			<div className={classes.bottomRight}>
				<Fab aria-label='add' className={classes.margin} type='button' onClick={handleOpen}>
					<AddIcon />
				</Fab>
			</div>
			<form onSubmit={handleSubmit}>
				<Modal
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					open={open}
					onClose={handleClose}
				>
					<div style={modalStyle} className={classes.paper}>
						<h2 id='simple-modal-title' className={classes.grey9}>
							Add a comment/complaint
						</h2>
						<TextField
							id='standard-multiline-static'
							label='Comment'
							multiline
							rows='4'
							placeholder='Write A Comment...'
							className={classes.textField}
							margin='normal'
							variant='outlined'
							name='comment'
							onChange={handleChange}
						/>
						<Button
							color='secondary'
							variant='contained'
							className={classes.color}
							type='Submit'
							value='Submit'
							name='submit'
							onClick={handleSubmit}
						>
							Submit
						</Button>
					</div>
				</Modal>
			</form>
		</div>
	);
}
