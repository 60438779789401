import React, { Component } from 'react';
import './card.css';
import SearchAppBar from '../layout/Appbar';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import NavBar from '../layout/Navbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { grey } from '@material-ui/core/colors';
import Accordian from './Accordian.js';

const style = (_) => ({
	linked: {
		textDecoration: 'none',
		color: 'black',
	},
	leftDashboardPanel: {
		width: '65%',
	},
	rightDashboardPanel: {
		width: '30%',
	},
	dashboardConatiner: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
});

class Dashboard extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div className='App'>
				<div>
					<NavBar />
				</div>
				<div className='Content'>
					<div className='Dashboard'>
						<div>
							<SearchAppBar />
						</div>
						<div className={classes.dashboardConatiner}>
							<div className={classes.leftDashboardPanel}>
								<div style={{ paddingLeft: '32px', color: grey[800] }}>
									<Typography variant='body1' noWrap>
										Click on any button to complete your action
									</Typography>
									<Divider />
								</div>
								<div className='CardContainer'>
									<Link
										to={{ pathname: '/newpatient', state: 'true' }}
										className={classes.linked}
									>
										<div className='card card-1'>
											<div className='login-top-img-content'>
												<img
													src='./images/addPatient.png'
													alt='addPatient'
												/>
											</div>
											<div>
												<h5>Add new patient</h5>
											</div>
										</div>
									</Link>
									<Link
										to={{ pathname: '/patientList', state: 'true' }}
										className={classes.linked}
									>
										<div className='card card-1'>
											<div className='login-top-img-content'>
												<img src='./images/listp.png' alt='listp' />
											</div>
											<div>
												<h5>All patients</h5>
											</div>
										</div>
									</Link>
								</div>
							</div>
							<div className={classes.rightDashboardPanel}>
								<div style={{ paddingLeft: '32px', color: grey[800] }}>
									<Typography variant='body1' noWrap>
										Welcome to patient management system
									</Typography>
									<Divider />
								</div>

								<Accordian />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withStyles(style)(Dashboard));
