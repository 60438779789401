import React, { Component } from 'react';
//text
import TextField from '@material-ui/core/TextField';
//button
import Button from '@material-ui/core/Button';
//Select
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const localStorageHistoryKey = 'searchHistoryLS';

export class TableSearchbar extends Component {
	static defaultProps = {
		sales: false,
	};
	id;
	phone;
	diagnosis;
	age;
	fname;

	isTimeOrDateTime() {
		return (
			new Date(this.props.searchValue).getTime() > 0 &&
			!isNaN(new Date(this.props.searchValue).getTime())
		);
	}

	constructor(props) {
		super(props);
		const notStored = localStorage.getItem(localStorageHistoryKey) === null;
		this.state = {
			searchValue: notStored
				? 'BSMMU-PN-'
				: JSON.parse(localStorage.getItem(localStorageHistoryKey)).find(
						(searchComponent) => searchComponent.active === true
				  ).value,
			searchBy: notStored
				? 'id'
				: JSON.parse(localStorage.getItem(localStorageHistoryKey)).find(
						(searchComponent) => searchComponent.active === true
				  ).key,
			searchHistory: notStored
				? [
						{ key: 'id', value: 'BSMMU-PN-', MenuItem: 'ID', active: true },
						{ key: 'phone', value: '', MenuItem: 'Contact', active: false },
						{
							key: 'diagnosis',
							value: '',
							MenuItem: 'Diagnosis',
							active: false,
						},
						{ key: 'age', value: '', MenuItem: 'Age', active: false },
						{ key: 'fname', value: '', MenuItem: 'Name', active: false },
						{
							key: 'date',
							value: new Date().toISOString().split('T')[0],
							MenuItem: 'Date',
							active: false,
						},
				  ]
				: JSON.parse(localStorage.getItem(localStorageHistoryKey)),
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		if (e.target.name === 'searchBy') {
			this.setState({
				searchValue: this.state.searchHistory.find(
					(searchComponent) => searchComponent.key === e.target.value
				).value,
				searchBy: e.target.value,
			});
		} else if (e.target.name === 'searchValue') {
			let val = e.target.value;
			this.setState({
				[e.target.name]: val,
			});
			this.setState((prevState) => ({
				searchHistory: prevState.searchHistory.map((el) =>
					el.key === this.state.searchBy ? { ...el, value: val } : el
				),
			}));
		} else {
			this.setState({
				[e.target.name]: e.target.value,
			});
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		if (this.state.searchBy === '') {
			alert('Please Select the Field');
		} else {
			let searchHistory = this.state.searchHistory;

			searchHistory.forEach((obj) => {
				obj.active = obj.key === this.state.searchBy;
				obj.value = obj.value.trim();
			});

			localStorage.setItem(localStorageHistoryKey, JSON.stringify(searchHistory));
			this.props.handleSearch(
				this.state.searchBy === 'date'
					? Date.parse(this.state.searchValue)
					: this.state.searchValue.trim(),
				this.state.searchBy
			);
		}
	};

	handleClear = () => {
		localStorage.removeItem(localStorageHistoryKey);
		this.setState({ searchValue: '', searchBy: 'id' });
		this.props.handleClear();
	};

	render() {
		return (
			<div style={{ width: '99%' }}>
				<form
					style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
					onSubmit={this.handleSubmit}
				>
					<React.Fragment>
						<FormControl>
							<TextField
								label='Search here...'
								value={this.state.searchValue}
								type={this.state.searchBy === 'date' ? 'date' : 'text'}
								onChange={this.handleChange}
								name='searchValue'
								variant='outlined'
								style={styles.textField}
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
							/>
						</FormControl>
					</React.Fragment>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex' }}>
							<React.Fragment>
								<FormControl style={styles.textFieldSelect}>
									<InputLabel id='searchBy' style={{ marginLeft: 16 }}>
										Search by
									</InputLabel>
									<Select
										// labelId="searchBy"
										id='searchBy'
										variant='outlined'
										name='searchBy'
										value={this.state.searchBy}
										onChange={this.handleChange}
									>
										{this.state.searchHistory.map((searchComponent) => (
											<MenuItem
												value={searchComponent.key}
												key={searchComponent.key}
											>
												{searchComponent.MenuItem}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</React.Fragment>
							<Button
								variant='contained'
								color='secondary'
								size={'large'}
								style={{ margin: 8, color: 'white' }}
								type='submit'
								disabled={
									this.state.searchValue.length > 1 ||
									this.state.searchBy === 'age'
										? false
										: true
								}
							>
								Search
							</Button>
							<Button
								variant='contained'
								size={'large'}
								style={{ margin: 8 }}
								onClick={this.handleClear}
							>
								Clear
							</Button>
						</div>
						<div>
							{this.props.count ? (
								<p>{`Showing ${this.props.count} result(s)`}</p>
							) : null}
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const styles = {
	formControl: {
		display: 'flex',
		margin: '8px',
	},
	textFieldSelect: {
		marginLeft: 8,
		marginRight: 8,
		marginBottom: 0,
		minWidth: 270,
	},
	textField: {
		margin: 8,
		minWidth: 270,
	},
};

export default TableSearchbar;
