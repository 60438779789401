import React, { Component } from "react";
// import axios from 'axios';
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
// import SearchAppBar from '../layout/Appbar';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { updateOnProps } from "../../helper/utilities";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(2),
    width: 250,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  marginRadio: {
    margin: theme.spacing(2),
  },
});

class Basic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referredby: this.props.data.referredby,
      title: this.props.data.title,
      typeofEnrollment: this.props.data.typeOfEnrollment,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
  }

  componentDidUpdate(prevProps) {
    updateOnProps(prevProps, ["referredby", "title", "typeOfEnrollment"], this);
  }

  handleChange(e) {
    this.props.handleChange(e);
  }
  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.handleChange(e);
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <FormControl
          required
          className={classes.formControl}
          error={!this.props.data.isFormValid}
          style={{ margin: "16px" }}
        >
          <InputLabel htmlFor="title">Purpose of Visit</InputLabel>
          <Select
            // onBlur={this.handleChange}
            value={this.state.title}
            onChange={this.handlechange}
            name="title"
          >
            <MenuItem value={"General"}>General Visit</MenuItem>
            <MenuItem value={"Blood Test"}>Report submission</MenuItem>
            <MenuItem value={"Follow Up"}>Follow Up</MenuItem>
          </Select>
          <FormHelperText>Please Select Your Purpose of visit</FormHelperText>
        </FormControl>

        <TextField
          label="Referred By"
          value={this.state.referredby}
          onBlur={this.handleChange}
          onChange={this.handlechange}
          margin="normal"
          name="referredby"
          className={classes.textField}
        />

        <FormControl component="fieldset" className={classes.marginRadio}>
          <FormLabel component="legend">Type Of Enrollment</FormLabel>
          <RadioGroup
            aria-label="position"
            name="typeOfEnrollment"
            value={this.props.data.typeOfEnrollment}
            onChange={this.handleChange}
            row
          >
            <FormControlLabel
              value={"in-patient"}
              control={<Radio color="secondary" />}
              label="in-patient"
              labelPlacement="end"
            />
            <FormControlLabel
              value={"out-patient"}
              control={<Radio color="secondary" />}
              label="out-patient"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(style)(Basic);
