export const CBC_Columns = [
  { field: "visitDate", headerName: "Visit Date", width: 140 },
  { field: "visitType", headerName: "Visit Type", width: 140 },
  { field: "Analysis_Hb_DOA", headerName: "Hb%", width: 105 },
  { field: "Analysis_tc_DOA", headerName: "TC", width: 105 },
  {
    field: "Analysis_neutrophil_DOA",
    headerName: "Neutrophil",
    width: 145,
  },
  {
    field: "Analysis_lymphocyte_DOA",
    headerName: "Lymphocyte",
    width: 155,
  },
  {
    field: "Analysis_eosinophil_DOA",
    headerName: "Eosinophil",
    width: 145,
  },
  { field: "Analysis_monocyte_DOA", headerName: "Monocyte", width: 145 },
  {
    field: "Analysis_Platlete_count_DOA",
    headerName: "Platelete Count",
    width: 175,
  },
  {
    field: "Analysis_reticulocyteCount_DOA",
    headerName: "Reticulocyte Count",
    width: 200,
  },
];
