import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import '../patient/Create-patient.css';
import { lightGreen } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { updateOnProps } from '../../helper/utilities';

const style = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        background: 'white',
        padding: 32,
    },
    formControl: {
        margin: theme.spacing(1),
        width: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(4),
    },
    textField: {
        margin: theme.spacing(2),
        width: 250,
    },
    addressTextField: {
        margin: theme.spacing(1),
        width: 250,
    },
    menu: {
        width: 150,
    },
    submitbutton: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText(lightGreen[500]),
        backgroundColor: lightGreen[500],
        '&:hover': {
            backgroundColor: lightGreen[600],
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    genituorinaryClass: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
});

class ChiefComplaints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chiefcomplains_complain1: this.props.data.chiefcomplains_complain1,
            chiefcomplains_complain2: this.props.data.chiefcomplains_complain2,
            chiefcomplains_complain3: this.props.data.chiefcomplains_complain3,
            chiefcomplains_complain4: this.props.data.chiefcomplains_complain4,
            chiefcomplains_complain5: this.props.data.chiefcomplains_complain5,
            chiefcomplains_complain6: this.props.data.chiefcomplains_complain6,
            swelling_days: this.props.data.swelling_days,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

    componentDidUpdate(prevProps) {
        updateOnProps(
            prevProps,
            [
                'chiefcomplains_complain1',
                'chiefcomplains_complain2',
                'chiefcomplains_complain3',
                'chiefcomplains_complain4',
                'chiefcomplains_complain5',
                'chiefcomplains_complain6',
                'swelling_days',
            ],
            this
        );
    }

    handleChange(e) {
        this.props.handleChange(e);
    }

    handleCheck(e) {
        this.props.handleCheck(e);
    }
    handlechange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(evt) {
        evt.preventDefault();
    }

    componentDidMount() {}

    render() {
        const { classes } = this.props;

        return (
            <div>
                <TextField
                    label='complain 1'
                    value={this.state.chiefcomplains_complain1}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    multiline
                    rowsMax='4'
                    name='chiefcomplains_complain1'
                    className={classes.textField}
                />

                <TextField
                    label='complain 2'
                    value={this.state.chiefcomplains_complain2}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    multiline
                    rowsMax='4'
                    name='chiefcomplains_complain2'
                    className={classes.textField}
                />
                <TextField
                    label='complain 3'
                    value={this.state.chiefcomplains_complain3}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    multiline
                    rowsMax='4'
                    name='chiefcomplains_complain3'
                    className={classes.textField}
                />
                <TextField
                    label='complain 4'
                    value={this.state.chiefcomplains_complain4}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    multiline
                    rowsMax='4'
                    name='chiefcomplains_complain4'
                    className={classes.textField}
                />
                <TextField
                    label='complain 5'
                    value={this.state.chiefcomplains_complain5}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin='normal'
                    multiline
                    rowsMax='4'
                    name='chiefcomplains_complain5'
                    className={classes.textField}
                />

                <FormControl component='fieldset' className={classes.textField}>
                    <FormLabel component='legend'>Swelling of the whole body</FormLabel>
                    <FormGroup aria-label='position' name='position' row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.chiefcomplains_complain6}
                                    onChange={this.handleCheck}
                                    value={this.state.chiefcomplains_complain6}
                                    name='chiefcomplains_complain6'
                                />
                            }
                            label='Yes'
                            labelPlacement='end'
                        />
                    </FormGroup>
                </FormControl>

                {this.state.chiefcomplains_complain6 ? (
                    <TextField
                        label='Number of Days'
                        value={this.state.swelling_days}
                        onChange={this.handlechange}
                        onBlur={this.handleChange}
                        margin='normal'
                        name='swelling_days'
                        className={classes.textField}
                        type='number'
                        InputProps={{ inputProps: { min: 0 } }}
                    />
                ) : (
                    <div></div>
                )}
            </div>
        );
    }
}

export default withStyles(style)(ChiefComplaints);
