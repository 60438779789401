import React, { Component } from 'react';
import './Login.css';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { lightGreen, grey } from '@material-ui/core/colors';
import CustomizedSnackbars from '../snackbars/CustomizedSnackbars';
import Loader from '../layout/Loader';
import axios from 'axios';
import { storeToken } from '../log-and-register/Auth';

const style = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
	},
	textField: {
		margin: theme.spacing(1),
		width: 400,
		borderColor: theme.palette.primary.grey8,
		fontColor: theme.palette.primary.grey9,
	},
	button: {
		margin: theme.spacing(1),
		width: 400,
		color: theme.palette.getContrastText(lightGreen[500]),
		text: grey[50],
		backgroundColor: lightGreen[600],
		'&:hover': {
			backgroundColor: lightGreen[700],
		},
	},
});

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			token: {},
			error: false,
			loading: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value, error: false });
	};

	handleSubmit(evt) {
		evt.preventDefault();
		this.setState({ loading: true });
		axios
			.post(
				(process.env.NODE_ENV === 'development' ? 'http://localhost:40000' : '') +
					'/api/authenticate',
				this.state
			)
			.then((res) => {
				storeToken(res.data);
				axios.defaults.headers.common = {
					token: res.data,
				};
				this.setState({ loading: false });
				if (res.status === 200) {
					this.props.history.push('/home');
				} else {
					const error = new Error(res.error);
					throw error;
				}
			})
			.catch((err) => {
				console.error(err);
				this.setState({ error: true, loading: false });
			});
	}

	render() {
		const { classes } = this.props;
		let buttonState = true;
		if (this.state.username === '' || this.state.password === '') {
			buttonState = true;
		} else {
			buttonState = false;
		}

		return (
			<div className='Login-panel'>
				<div className='container'>
					<form className={classes.root} onSubmit={this.handleSubmit}>
						<div className='login-top-img'>
							<div className='login-top-img-content'>
								<img src='./images/login-top.png' alt='login-top' />
							</div>
						</div>

						<TextField
							required
							label='User Name'
							value={this.state.username}
							type='text'
							onChange={this.handleChange}
							margin='normal'
							name='username'
							variant='outlined'
							className={classes.textField}
						/>

						<TextField
							required
							label='Password'
							value={this.state.password}
							type='password'
							onChange={this.handleChange}
							margin='normal'
							name='password'
							variant='outlined'
							className={classes.textField}
						/>
						{this.state.loading ? (
							<Loader />
						) : (
							<Button
								variant='contained'
								size='large'
								className={classes.button}
								disabled={buttonState}
								type='submit'
								name='submit'
							>
								<span className='white'>Login</span>
							</Button>
						)}
					</form>

					<CustomizedSnackbars
						type='error'
						message='Log in Falied'
						show={this.state.error}
					/>
				</div>
			</div>
		);
	}
}

export default withStyles(style)(Login);
