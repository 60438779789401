import React from "react";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import { NavLink, withRouter } from "react-router-dom";
import "./Navbar.css";
import { grey } from "@material-ui/core/colors";
import StatsIcon from "../icons/StatsIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    minWidth: "100px",
  },
  paper: {
    marginRight: theme.spacing(0.1),
    boxShadow:
      " 0 10px 40px 0 rgba(0, 0, 0, 0.07), 0 2px 9px 0 rgba(0, 0, 0, 0.06)",
    position: "fixed",
    left: "0",
    top: "0",
    height: "100%",
    minWidth: "96px",
  },
  nav: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "fit-content",
    marginTop: "150px",
  },
  tab: {
    display: "flex",
    justifyContent: "center",
    height: "64px",
  },
  navlinkcolor: {
    color: grey[300],
  },
  activenavlinkcolor: {
    color: grey[700],
    borderLeft: " 5px solid black",
  },
  "@global": {
    "html, body, #root, App": {
      height: "100%",
    },
  },
}));

export default withRouter(function MenuListComposition() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} square={true}>
        <NavLink to={{ pathname: "/home", state: "true" }}>
          <div className="login-top-img logoIconPadding">
            <div className="login-top-img-content">
              <img src="./images/iconLogo.png" alt="iconLogo" />
            </div>
          </div>
        </NavLink>

        <div className="ContainMenu">
          <MenuList className={classes.nav}>
            <NavLink
              to={{ pathname: "/home", state: "true" }}
              className={classes.navlinkcolor}
              activeClassName={classes.activenavlinkcolor}
            >
              <MenuItem className={classes.tab}>{<HomeIcon />}</MenuItem>
            </NavLink>
            <NavLink
              to={{ pathname: "/stats", state: "true" }}
              className={classes.navlinkcolor}
              activeClassName={classes.activenavlinkcolor}
            >
              <MenuItem className={classes.tab}>{<StatsIcon />}</MenuItem>
            </NavLink>
          </MenuList>
        </div>
      </Paper>
    </div>
  );
});
