import React, { Component } from "react";
import SearchAppBar from "../layout/Appbar";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import NavBar from "../layout/Navbar";
import Patienttable from "./PatientTable";
const style = (theme) => ({
  App: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  Content: {
    width: "100%",
    margin: 16,
  },
  root: {
    marginTop: theme.spacing(3),
    background: "#fff",
  },
  tableWrapper: {
    overflowX: "auto",
  },
});
class PatientList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log("Route State", this.props.location.state?.diagnosis);
    const { classes } = this.props;
    return (
      <div className={classes.App}>
        <div>
          <NavBar />
        </div>

        <div className={classes.Content}>
          <div style={{ margin: 8, marginTop: 0 }}>
            <SearchAppBar />
            <Patienttable
              startDate={this.props.location.state?.startDate}
              endDate={this.props.location.state?.endDate}
              typeOfEnrollment={this.props.location.state?.typeOfEnrollment}
              diagnosis={this.props.location.state?.diagnosis}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(PatientList));
