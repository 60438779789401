import React, { Component } from 'react';
import VisitTable from './VisitTable';
import SummaryView from './SummaryView';
import axios from 'axios';
import Loader from '../layout/Loader';

export class VisitingInformations extends Component {
	componentDidMount() {
		const id = this.props.patientId;
		this.setState({ visitInfoFetched: false }, () => {
			axios
				.get(
					(process.env.NODE_ENV === 'development' ? 'http://localhost:40000' : '') +
						`/api/visitinfo/${id}`
				)
				.then((res) => {
					this.setState({ allData: res.data, visitInfoFetched: true });
				})
				.catch((err) => {
					console.error(err);
				});
		});
	}

	constructor(props) {
		super(props);
		this.state = {
			id: this.props.patientId,
			allData: [],
			data: {
				summary: '',
				diagnosis: '',
				treatment: '',
			},
		};
		this.handleVisitChange = this.handleVisitChange.bind(this);
	}
	handleVisitChange(id) {
		axios
			.get(
				(process.env.NODE_ENV === 'development' ? 'http://localhost:40000' : '') +
					`/api/visit/${id}`
			)
			.then((res) => {
				this.setState({ data: { ...res.data } });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		return (
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					padding: '32px 0',
					width: '100%',
				}}
			>
				<div style={{ width: '70%' }}>
					<h5 style={{ marginLeft: '30px' }}>
						Copy and create a new visit with the corresponding visit information from a
						selected visit.
					</h5>
					{this.state.visitInfoFetched ? (
						<VisitTable
							data={this.state.allData}
							handleVisitChange={this.handleVisitChange}
							patientId={this.props.patientId}
						/>
					) : (
						<Loader />
					)}
				</div>

				<div style={{ width: '25%' }}>
					<SummaryView data={this.state.data} />
				</div>
			</div>
		);
	}
}

export default VisitingInformations;
