import React, { Component } from "react";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Loader from "../layout/Loader";
import Paper from "@material-ui/core/Paper";

const style = (theme) => ({
  button: {
    margin: theme.spacing(1, 1, 1, 0),
    color: "white",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

class VisitTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Name", field: "title" },
        { title: "Date of Visit", field: "date" },
        { title: "Outcome", field: "outcome" },
        {
          field: "_id",
          title: "View",
          render: (rowData) => (
            <Button onClick={() => this.handleChange(rowData._id)}>
              {<VisibilityIcon style={{ color: "black" }} />}
            </Button>
          ),
        },
        {
          field: "_id",
          title: "Edit",
          render: (rowData) => (
            <Link
              to={{
                pathname: "/addvisit",
                state: {
                  id: rowData._id,
                  patientId: this.props.patientId,
                  pageAction: "update",
                },
              }}
            >
              {<EditIcon style={{ color: "black" }} />}
            </Link>
          ),
        },
        {
          field: "_id",
          title: "Copy Visit",
          render: (rowData) => (
            <Link
              to={{
                pathname: "/addvisit",
                state: {
                  id: rowData._id,
                  patientId: this.props.patientId,
                  pageAction: "copy",
                },
              }}
            >
              {
                <Icon color="secondary" style={{ fontSize: 30 }}>
                  add_circle
                </Icon>
              }
            </Link>
          ),
        },
      ],
      data: this.props.data
        .sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          }
          if (a.date < b.date) {
            return 1;
          }
          return 0;
        })
        .map((item) => ({
          ...item,
          date: new Date(item.date).toLocaleString("en-BD"),
        })),
      addNewForm: false,
    };
    this.handleNewAddForm = this.handleNewAddForm.bind(this);
  }
  handleChange(id) {
    this.props.handleVisitChange(id);
  }

  handleNewAddForm() {
    this.setState({ addNewForm: true });
  }

  // Fix for material-table not being up to date with material library O_O
  PatchedPagination(props) {
    const {
      ActionsComponent,
      onChangePage,
      onChangeRowsPerPage,
      ...tablePaginationProps
    } = props;

    return (
      <TablePagination
        {...tablePaginationProps}
        // @ts-expect-error onChangePage was renamed to onPageChange
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={(subprops) => {
          const { onPageChange, ...actionsComponentProps } = subprops;
          return (
            // @ts-expect-error ActionsComponent is provided by material-table
            <ActionsComponent
              {...actionsComponentProps}
              onChangePage={onPageChange}
            />
          );
        }}
      />
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.addNewForm) {
      return (
        <Redirect
          to={{
            pathname: "/addvisit",
            state: { patientId: this.props.patientId, pageAction: "create" },
            prevPath: `/vinfo`,
          }}
        />
      );
    }
    if (this.state.data) {
      return (
        <div>
          <div className={classes.tablecontainer}>
            <MaterialTable
              title="Visit Basics"
              columns={this.state.columns}
              style={{ padding: "0 24px", boxShadow: "none" }}
              data={this.state.data}
              editable={{}}
              components={{
                Pagination: this.PatchedPagination,
              }}
            />
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={this.handleNewAddForm}
              >
                Add New Visit
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Paper
          style={{
            width: "100%",
            height: "750px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={classes.tableWrapper}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div style={{ minWidth: "300px" }}>
                <Loader />
              </div>
            </div>
          </div>
        </Paper>
      );
    }
  }
}
export default withStyles(style)(VisitTable);
