import React, { Component } from "react";
import {
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import FormLabel from "@material-ui/core/FormLabel";
import { updateOnProps } from "../../helper/utilities";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InfoIcon from "@material-ui/icons/Info";
import { CBC_Columns } from "./tableData/cbc";
import { Urine_Routine_Columns } from "./tableData/urine_routine";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  textfieldfull: {
    margin: theme.spacing(1),
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  centerized: {
    margin: theme.spacing(1),
  },
  formControlnew: {},
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
    margin: theme.spacing(2),
  },
  boxContainer: {
    display: "flex",
  },
});

class Analysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Analysis_UTP_DOA: this.props.data.Analysis_UTP_DOA,
      Analysis_UTP_DOD: this.props.data.Analysis_UTP_DOD,
      Analysis_spotPCR_DOA: this.props.data.Analysis_spotPCR_DOA,
      Analysis_spotPCR_DOD: this.props.data.Analysis_spotPCR_DOD,
      Analysis_micro_albuminuria_DOA:
        this.props.data.Analysis_micro_albuminuria_DOA,
      Analysis_micro_albuminuria_DOD:
        this.props.data.Analysis_micro_albuminuria_DOD,
      Analysis_urinary_electrolytes_DOA:
        this.props.data.Analysis_urinary_electrolytes_DOA,
      Analysis_urinary_electrolytes_DOD:
        this.props.data.Analysis_urinary_electrolytes_DOD,
      Analysis_Hb_DOA: this.props.data.Analysis_Hb_DOA,
      Analysis_Hb_DOD: this.props.data.Analysis_Hb_DOD,
      Analysis_tc_DOA: this.props.data.Analysis_tc_DOA,
      Analysis_tc_DOD: this.props.data.Analysis_tc_DOD,
      Analysis_neutrophil_DOA: this.props.data.Analysis_neutrophil_DOA,
      Analysis_neutrophil_DOD: this.props.data.Analysis_neutrophil_DOD,
      Analysis_lymphocyte_DOA: this.props.data.Analysis_lymphocyte_DOA,
      Analysis_lymphocyte_DOD: this.props.data.Analysis_lymphocyte_DOD,
      Analysis_eosinophil_DOA: this.props.data.Analysis_eosinophil_DOA,
      Analysis_eosinophil_DOD: this.props.data.Analysis_eosinophil_DOD,

      Analysis_monocyte_DOA: this.props.data.Analysis_monocyte_DOA,
      Analysis_monocyte_DOD: this.props.data.Analysis_monocyte_DOD,

      Analysis_Platlete_count_DOA: this.props.data.Analysis_Platlete_count_DOA,
      Analysis_Platlete_count_DOD: this.props.data.Analysis_Platlete_count_DOD,
      Analysis_24_Hours_Urinary_PO4:
        this.props.data.Analysis_24_Hours_Urinary_PO4,
      Analysis_24_Hours_Urinary_calcium:
        this.props.data.Analysis_24_Hours_Urinary_calcium,
      Analysis_24_Hours_Urinary_Oxalate:
        this.props.data.Analysis_24_Hours_Urinary_Oxalate,

      Analysis_reticulocyteCount_DOA:
        this.props.data.Analysis_reticulocyteCount_DOA,
      Analysis_reticulocyteCount_DOD:
        this.props.data.Analysis_reticulocyteCount_DOD,

      Analysis_S_Osmolality_DOA: this.props.data.Analysis_S_Osmolality_DOA,
      Analysis_S_Osmolality_DOD: this.props.data.Analysis_S_Osmolality_DOD,

      Analysis_HBsAg_DOA: this.props.data.Analysis_HBsAg_DOA,
      Analysis_HBsAg_DOD: this.props.data.Analysis_HBsAg_DOD,

      Analysis_AntiHCV_DOA: this.props.data.Analysis_AntiHCV_DOA,
      Analysis_AntiHCV_DOD: this.props.data.Analysis_AntiHCV_DOD,

      Analysis_S_Ferritin_DOA: this.props.data.Analysis_S_Ferritin_DOA,
      Analysis_S_Ferritin_DOD: this.props.data.Analysis_S_Ferritin_DOD,

      Analysis_s_albumin_DOA: this.props.data.Analysis_s_albumin_DOA,
      Analysis_s_albumin_DOD: this.props.data.Analysis_s_albumin_DOD,

      Analysis_s_cholestrol_DOA: this.props.data.Analysis_s_cholestrol_DOA,
      Analysis_s_cholestrol_DOD: this.props.data.Analysis_s_cholestrol_DOD,

      Analysis_s_creatinin_DOA: this.props.data.Analysis_s_creatinin_DOA,
      Analysis_s_creatinin_DOD: this.props.data.Analysis_s_creatinin_DOD,

      Analysis_blood_urea_DOA: this.props.data.Analysis_blood_urea_DOA,
      Analysis_blood_urea_DOD: this.props.data.Analysis_blood_urea_DOD,

      Analysis_s_electrolytesNa_DOA:
        this.props.data.Analysis_s_electrolytesNa_DOA,
      Analysis_s_electrolytesNa_DOD:
        this.props.data.Analysis_s_electrolytesNa_DOD,

      Analysis_s_electrolytesK_DOA:
        this.props.data.Analysis_s_electrolytesK_DOA,
      Analysis_s_electrolytesK_DOD:
        this.props.data.Analysis_s_electrolytesK_DOD,

      Analysis_s_electrolytesCL_DOA:
        this.props.data.Analysis_s_electrolytesCL_DOA,
      Analysis_s_electrolytesCL_DOD:
        this.props.data.Analysis_s_electrolytesCL_DOD,

      Analysis_s_electrolytesTCO_DOA:
        this.props.data.Analysis_s_electrolytesTCO_DOA,
      Analysis_s_electrolytesTCO_DOD:
        this.props.data.Analysis_s_electrolytesTCO_DOD,

      Analysis_s_calcium_DOA: this.props.data.Analysis_s_calcium_DOA,
      Analysis_s_calcium_DOD: this.props.data.Analysis_s_calcium_DOD,

      Analysis_s_phosphate_DOA: this.props.data.Analysis_s_phosphate_DOA,
      Analysis_s_phosphate_DOD: this.props.data.Analysis_s_phosphate_DOD,

      Analysis_s_alkainePhosphate_DOA:
        this.props.data.Analysis_s_alkainePhosphate_DOA,
      Analysis_s_alkainePhosphate_DOD:
        this.props.data.Analysis_s_alkainePhosphate_DOD,

      Analysis_PTH_DOA: this.props.data.Analysis_PTH_DOA,
      Analysis_PTH_DOD: this.props.data.Analysis_PTH_DOD,

      Analysis_s_c3_DOA: this.props.data.Analysis_s_c3_DOA,
      Analysis_s_c3_DOD: this.props.data.Analysis_s_c3_DOD,

      Analysis_s_c4_DOA: this.props.data.Analysis_s_c4_DOA,
      Analysis_s_c4_DOD: this.props.data.Analysis_s_c4_DOD,

      Analysis_ANA_DOA: this.props.data.Analysis_ANA_DOA,
      Analysis_ANA_DOD: this.props.data.Analysis_ANA_DOD,

      Analysis_anti_DS_DNA_DOA: this.props.data.Analysis_anti_DS_DNA_DOA,
      Analysis_anti_DS_DNA_DOD: this.props.data.Analysis_anti_DS_DNA_DOD,

      Analysis_c_ANCA_DOA: this.props.data.Analysis_c_ANCA_DOA,
      Analysis_c_ANCA_DOD: this.props.data.Analysis_c_ANCA_DOD,

      Analysis_p_ANCA_DOA: this.props.data.Analysis_p_ANCA_DOA,
      Analysis_p_ANCA_DOD: this.props.data.Analysis_p_ANCA_DOD,

      Analysis_CXR_DOA: this.props.data.Analysis_CXR_DOA,
      Analysis_CXR_DOD: this.props.data.Analysis_CXR_DOD,

      Analysis_USG_DOA: this.props.data.Analysis_USG_DOA,
      Analysis_USG_DOD: this.props.data.Analysis_USG_DOD,

      Analysis_MCUG_DOA: this.props.data.Analysis_MCUG_DOA,
      Analysis_MCUG_DOD: this.props.data.Analysis_MCUG_DOD,

      Analysis_DTPA_DOA: this.props.data.Analysis_DTPA_DOA,
      Analysis_DTPA_DOD: this.props.data.Analysis_DTPA_DOD,

      Analysis_DMSA_DOA: this.props.data.Analysis_DMSA_DOA,
      Analysis_DMSA_DOD: this.props.data.Analysis_DMSA_DOD,

      Analysis_OTHER_DOA: this.props.data.Analysis_OTHER_DOA,
      Analysis_OTHER_DOD: this.props.data.Analysis_OTHER_DOD,

      Analysis_Kidney_biopsy_DOA: this.props.data.Analysis_Kidney_biopsy_DOA,
      Analysis_Kidney_biopsy_DOD: this.props.data.Analysis_Kidney_biopsy_DOD,
      urineRoutineExam: this.props.data.urineRoutineExam,
      urineCSwithC_C: this.props.data.urineCSwithC_C,
      urineProtein: this.props.data.urineProtein,
      urinePuscul: this.props.data.urinePuscul,
      urineRbc: this.props.data.urineRbc,
      urinePh: this.props.data.urinePh,
      urineSpecificGravity: this.props.data.urineSpecificGravity,
      urineElectrolytesOption: this.props.data.urineElectrolytesOption,
      urineElectrolytes: this.props.data.urineElectrolytes,

      Analysis_TIBC: this.props.data.Analysis_TIBC,
      Analysis_S_IRON: this.props.data.Analysis_S_IRON,
      Analysis_Ferritin: this.props.data.Analysis_Ferritin,
      Analysis_S_Calcium: this.props.data.Analysis_S_Calcium,
      Analysis_Inorganic_Phosphate:
        this.props.data.Analysis_Inorganic_Phosphate,
      Analysis_PTH: this.props.data.Analysis_PTH,
      Analysis_S_Alkaline_Phosphate:
        this.props.data.Analysis_S_Alkaline_Phosphate,
      Analysis_Vitamin_D: this.props.data.Analysis_Vitamin_D,
      Analysis_Light_Microscopy: this.props.data.Analysis_Light_Microscopy,
      Analysis_DIF: this.props.data.Analysis_DIF,
      Analysis_Elective_Microscopy:
        this.props.data.Analysis_Elective_Microscopy,
      Analysis_USG_of_KUB: this.props.data.Analysis_USG_of_KUB,
      Analysis_Ivv: this.props.data.Analysis_Ivv,
      Analysis_MCU_RGV: this.props.data.Analysis_MCU_RGV,
      Analysis_CT_Scan_Abdomen_or_Brain:
        this.props.data.Analysis_CT_Scan_Abdomen_or_Brain,
      Analysis_CT_Urogram: this.props.data.Analysis_CT_Urogram,
      Analysis_DTPA: this.props.data.Analysis_DTPA,

      Analysis_PBF: this.props.data.Analysis_PBF,
      Analysis_RBC: this.props.data.Analysis_RBC,
      Analysis_WBC: this.props.data.Analysis_WBC,
      Analysis_Platelete: this.props.data.Analysis_Platelete,

      fasting_lipid_profile: this.props.data.fasting_lipid_profile,
      sgpt: this.props.data.sgpt,
      mt: this.props.data.mt,
      rbs: this.props.data.rbs,
      Urinary_Osmonality: this.props.data.Urinary_Osmonality,
      Lymphoctye: this.props.data.Lymphoctye,
      chestXRay: this.props.data.chestXRay,
      Analysis_KUB_DOA: this.props.data.Analysis_KUB_DOA,
      Analysis_KUB_DOD: this.props.data.Analysis_KUB_DOD,
      PlainXRayAbdomen: this.props.data.PlainXRayAbdomen,
      ivu: this.props.data.ivu,
      uricAcid: this.props.data.uricAcid,
      in: true,
      aso: this.props.data.aso,
      thyroidtest: this.props.data.thyroidtest,
      spoturinaryratio: this.props.data.spoturinaryratio,
      urinarycalcium24hrs: this.props.data.urinarycalcium24hrs,
      generalxray: this.props.data.generalxray,

      liver_sgpt: this.props.data.liver_sgpt || "",
      liver_sgot: this.props.data.liver_sgot || "",
      liver_ygt: this.props.data.liver_ygt || "",
      liver_s_bilirubin: this.props.data.liver_s_bilirubin || "",
      blood_sugar_after_breakfast:
        this.props.data.blood_sugar_after_breakfast || "",
      blood_sugar_fast: this.props.data.blood_sugar_fast || "",
      blood_sugar_random: this.props.data.blood_sugar_random || "",
      bun: this.props.data.bun || "",
      s_creatinine: this.props.data.s_creatinine || "",
      s_uric_acid: this.props.data.s_uric_acid || "",
      analysis_serum_total_protein:
        this.props.data.analysis_serum_total_protein || "",
      analysis_s_cholesterol: this.props.data.analysis_s_cholesterol || "",
      analysis_hdl: this.props.data.analysis_hdl || "",
      analysis_ldl: this.props.data.analysis_ldl || "",
      analysis_triglyceride: this.props.data.analysis_triglyceride || "",
      analysis_ft4: this.props.data.analysis_ft4 || "",
      analysis_t4: this.props.data.analysis_t4 || "",
      analysis_t3: this.props.data.analysis_t3 || "",
      analysis_tsh: this.props.data.analysis_tsh || "",
      analysis_hbs_ag: this.props.data.analysis_hbs_ag || "",
      analysis_hbs_ag: this.props.data.analysis_hbs_ag || "",
      analysis_anti_hbs: this.props.data.analysis_anti_hbs || "",
      analysis_anti_hcb: this.props.data.analysis_anti_hcb || "",
      analysis_anti_hav: this.props.data.analysis_anti_hav || "",
      analysis_hepatitis_b: this.props.data.analysis_hepatitis_b || "",
      analysis_anti_hev: this.props.data.analysis_anti_hev || "",
      analysis_toxoplasma_igg: this.props.data.analysis_toxoplasma_igg || "",
      analysis_toxoplasma_igm: this.props.data.analysis_toxoplasma_igm || "",
      analysis_rubella_igg: this.props.data.analysis_rubella_igg || "",
      analysis_rubella_igm: this.props.data.analysis_rubella_igm || "",
      analysis_cmv_igg: this.props.data.analysis_cmv_igg || "",
      analysis_cmv_igm: this.props.data.analysis_cmv_igm || "",
      analysis_syphillis: this.props.data.analysis_syphillis || "",
      analysis_complement_c3: this.props.data.analysis_complement_c3 || "",
      analysis_complement_c4: this.props.data.analysis_complement_c4 || "",
      analysis_complement_ch50: this.props.data.analysis_complement_ch50 || "",
      analysis_anca_p_anca: this.props.data.analysis_anca_p_anca || "",
      analysis_anca_ana: this.props.data.analysis_anca_ana || "",
      analysis_anca_anti_ds_dna:
        this.props.data.analysis_anca_anti_ds_dna || "",
      analysis_anca_ena_profile:
        this.props.data.analysis_anca_ena_profile || "",
      analysis_anca_coombs: this.props.data.analysis_anca_coombs || "",
      analysis_anca_mt: this.props.data.analysis_anca_mt || "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handle_CBC_Info_Click = this.handle_CBC_Info_Click.bind(this);
    this.handle_Urine_Examination_Info_Click =
      this.handle_Urine_Examination_Info_Click.bind(this);
  }

  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "Analysis_UTP_DOA",
        "Analysis_UTP_DOD",
        "Analysis_spotPCR_DOA",
        "Analysis_spotPCR_DOD",
        "Analysis_micro_albuminuria_DOA",
        "Analysis_micro_albuminuria_DOD",
        "Analysis_urinary_electrolytes_DOA",
        "Analysis_urinary_electrolytes_DOD",
        "Analysis_Hb_DOA",
        "Analysis_Hb_DOD",
        "Analysis_tc_DOA",
        "Analysis_tc_DOD",
        "Analysis_neutrophil_DOA",
        "Analysis_neutrophil_DOD",
        "Analysis_lymphocyte_DOA",
        "Analysis_lymphocyte_DOD",
        "Analysis_eosinophil_DOA",
        "Analysis_eosinophil_DOD",

        "Analysis_monocyte_DOA",
        "Analysis_monocyte_DOD",

        "Analysis_Platlete_count_DOA",
        "Analysis_Platlete_count_DOD",
        "Analysis_24_Hours_Urinary_PO4",
        "Analysis_24_Hours_Urinary_calcium",
        "Analysis_24_Hours_Urinary_Oxalate",

        "Analysis_reticulocyteCount_DOA",
        "Analysis_reticulocyteCount_DOD",

        "Analysis_S_Osmolality_DOA",
        "Analysis_S_Osmolality_DOD",

        "Analysis_HBsAg_DOA",
        "Analysis_HBsAg_DOD",

        "Analysis_AntiHCV_DOA",
        "Analysis_AntiHCV_DOD",

        "Analysis_S_Ferritin_DOA",
        "Analysis_S_Ferritin_DOD",

        "Analysis_s_albumin_DOA",
        "Analysis_s_albumin_DOD",

        "Analysis_s_cholestrol_DOA",
        "Analysis_s_cholestrol_DOD",

        "Analysis_s_creatinin_DOA",
        "Analysis_s_creatinin_DOD",

        "Analysis_blood_urea_DOA",
        "Analysis_blood_urea_DOD",

        "Analysis_s_electrolytesNa_DOA",
        "Analysis_s_electrolytesNa_DOD",

        "Analysis_s_electrolytesK_DOA",
        "Analysis_s_electrolytesK_DOD",

        "Analysis_s_electrolytesCL_DOA",
        "Analysis_s_electrolytesCL_DOD",

        "Analysis_s_electrolytesTCO_DOA",
        "Analysis_s_electrolytesTCO_DOD",

        "Analysis_s_calcium_DOA",
        "Analysis_s_calcium_DOD",

        "Analysis_s_phosphate_DOA",
        "Analysis_s_phosphate_DOD",

        "Analysis_s_alkainePhosphate_DOA",
        "Analysis_s_alkainePhosphate_DOD",

        "Analysis_PTH_DOA",
        "Analysis_PTH_DOD",

        "Analysis_s_c3_DOA",
        "Analysis_s_c3_DOD",

        "Analysis_s_c4_DOA",
        "Analysis_s_c4_DOD",

        "Analysis_ANA_DOA",
        "Analysis_ANA_DOD",

        "Analysis_anti_DS_DNA_DOA",
        "Analysis_anti_DS_DNA_DOD",

        "Analysis_c_ANCA_DOA",
        "Analysis_c_ANCA_DOD",

        "Analysis_p_ANCA_DOA",
        "Analysis_p_ANCA_DOD",

        "Analysis_CXR_DOA",
        "Analysis_CXR_DOD",

        "Analysis_USG_DOA",
        "Analysis_USG_DOD",

        "Analysis_MCUG_DOA",
        "Analysis_MCUG_DOD",

        "Analysis_DTPA_DOA",
        "Analysis_DTPA_DOD",

        "Analysis_DMSA_DOA",
        "Analysis_DMSA_DOD",

        "Analysis_OTHER_DOA",
        "Analysis_OTHER_DOD",

        "Analysis_PBF",
        "Analysis_RBC",
        "Analysis_WBC",
        "Analysis_Platelete",

        "Analysis_TIBC",
        "Analysis_S_IRON",
        "Analysis_Ferritin",
        "Analysis_S_Calcium",
        "Analysis_Inorganic_Phosphate",
        "Analysis_PTH",
        "Analysis_S_Alkaline_Phosphate",
        "Analysis_Vitamin_D",
        "Analysis_Light_Microscopy",
        "Analysis_DIF",
        "Analysis_Elective_Microscopy",
        "Analysis_USG_of_KUB",
        "Analysis_Ivv",
        "Analysis_MCU_RGV",
        "Analysis_CT_Scan_Abdomen_or_Brain",
        "Analysis_CT_Urogram",
        "Analysis_DTPA",

        "Analysis_Kidney_biopsy_DOA",
        "Analysis_Kidney_biopsy_DOD",
        "urineRoutineExam",
        "urineCSwithC_C",
        "urineElectrolytes",
        "urineElectrolytesOption",
        "urineProtein",
        "urinePuscul",
        "urineRbc",
        "urinePh",
        "urineSpecificGravity",

        "fasting_lipid_profile",
        "sgpt",
        "mt",
        "rbs",
        "Urinary_Osmonality",
        "Lymphoctye",
        "chestXRay",
        "Analysis_KUB_DOA",
        "Analysis_KUB_DOD",
        "PlainXRayAbdomen",
        "ivu",
        "uricAcid",
        "in",
        "aso",
        "thyroidtest",
        "spoturinaryratio",
        "urinarycalcium24hrs",
        "generalxray",
        "liver_sgpt",
        "liver_sgot",
        "liver_ygt",
        "liver_s_bilirubin",
        "blood_sugar_after_breakfast",
        "blood_sugar_fast",
        "blood_sugar_random",
        "bun",
        "s_creatinine",
        "s_uric_acid",
        "analysis_serum_total_protein",
        "analysis_s_cholesterol",
        "analysis_hdl",
        "analysis_ldl",
        "analysis_triglyceride",
        "analysis_ft4",
        "analysis_t4",
        "analysis_t3",
        "analysis_tsh",
        "analysis_hbs_ag",
        "analysis_anti_hbs",
        "analysis_anti_hcb",
        "analysis_anti_hav",
        "analysis_hepatitis_b",
        "analysis_anti_hev",
        "analysis_toxoplasma_igg",
        "analysis_toxoplasma_igm",
        "analysis_rubella_igg",
        "analysis_rubella_igm",
        "analysis_cmv_igg",
        "analysis_cmv_igm",
        "analysis_syphillis",
        "analysis_complement_c3",
        "analysis_complement_c4",
        "analysis_complement_ch50",
        "analysis_anca_p_anca",
        "analysis_anca_ana",
        "analysis_anca_anti_ds_dna",
        "analysis_anca_ena_profile",
        "analysis_anca_coombs",
        "analysis_anca_mt",
      ],
      this,
    );
  }

  handleChange(e) {
    this.props.handleChange(e);
  }
  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
  }

  handle_CBC_Info_Click() {
    this.props.handleSetModalStateChange(CBC_Columns);
    this.props.handlePopoverClick();
  }

  handle_Urine_Examination_Info_Click() {
    this.props.handleSetModalStateChange(Urine_Routine_Columns);
    this.props.handlePopoverClick();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.divStyle}>
          <h2>8.8 Investigation :</h2>
        </div>
        <div style={{ margin: "32px" }}>
          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <h3>8.8.1 Urine</h3>
            <IconButton
              onClick={this.handle_Urine_Examination_Info_Click}
              aria-label="info"
            >
              <InfoIcon />
            </IconButton>
          </div>
          <FormControl margin="normal" style={{ width: "100%" }}>
            <FormLabel component="legend">
              <h4> Urine routine examination</h4>
            </FormLabel>
          </FormControl>
          <FormControl margin="normal" style={{ width: "100%" }}>
            <InputLabel htmlFor="title">Protein</InputLabel>
            <Select
              onBlur={this.handleChange}
              value={this.state.urineProtein}
              onChange={this.handlechange}
              name="urineProtein"
              fullWidth
            >
              <MenuItem value={"Nill"}>Nill</MenuItem>
              <MenuItem value={"Trace"}>Trace</MenuItem>
              <MenuItem value={"+"}>+</MenuItem>
              <MenuItem value={"++"}>++</MenuItem>
              <MenuItem value={"+++"}>+++</MenuItem>
              <MenuItem value={"++++"}>++++</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "32px",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="Puscul"
                value={this.state.urinePuscul}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="urinePuscul"
                style={{ width: "250px" }}
              />
              <span style={{ marginBottom: "8px" }}>/HPF</span>
            </div>
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="RBC"
                value={this.state.urineRbc}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="urineRbc"
                style={{ width: "250px" }}
              />
              <span style={{ marginBottom: "8px" }}>/HPF</span>
            </div>
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="pH"
                value={this.state.urinePh}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="urinePh"
                style={{ width: "250px" }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="Specific Gravity"
                value={this.state.urineSpecificGravity}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="urineSpecificGravity"
                style={{ width: "250px" }}
              />
            </div>
          </div>
          {/* <TextField
            label="Urine Routine Exmination"
            value={this.state.urineRoutineExam}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="urineRoutineExam"
            fullWidth
          /> */}
          <FormControl component="fieldset" className={classes.formControlnew}>
            <FormLabel component="legend">
              <h5>Urine CS with C/C</h5>
            </FormLabel>
          </FormControl>

          <FormControl margin="normal" style={{ width: "100%" }}>
            <InputLabel htmlFor="title">Urine CS with C/C</InputLabel>
            <Select
              onBlur={this.handleChange}
              value={this.state.urineCSwithC_C}
              onChange={this.handlechange}
              name="urineCSwithC_C"
              fullWidth
            >
              <MenuItem value={"No Growth"}>No Growth</MenuItem>
              <MenuItem value={"Growth"}>Growth</MenuItem>
              <MenuItem value={"Colony Count"}>Colony Count</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="24 Hours Urinary Calcium"
            value={this.state.urinarycalcium24hrs}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="urinarycalcium24hrs"
            fullWidth
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <FormControl
              component="fieldset"
              className={classes.formControlnew}
            >
              <FormLabel component="legend">
                <h5>Spot PCR</h5>
              </FormLabel>
              <div className={classes.boxContainer}>
                <TextField
                  label="Result"
                  value={this.state.Analysis_spotPCR_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_spotPCR_DOA"
                  className={classes.textField}
                />
              </div>
            </FormControl>
            <FormControl
              component="fieldset"
              className={classes.formControlnew}
            >
              <FormLabel component="legend">
                <h5>Micro Albuminuria</h5>
              </FormLabel>
              <div className={classes.boxContainer}>
                <TextField
                  label="Result"
                  value={this.state.Analysis_micro_albuminuria_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_micro_albuminuria_DOA"
                  className={classes.textField}
                />
              </div>
            </FormControl>
            <FormControl
              component="fieldset"
              className={classes.formControlnew}
            >
              <FormLabel component="legend">
                <h5>24hrs UTP</h5>
              </FormLabel>
              <div className={classes.boxContainer}>
                <TextField
                  label="Result"
                  value={this.state.Analysis_UTP_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_UTP_DOA"
                  className={classes.textField}
                />
              </div>
            </FormControl>
          </div>
          <TextField
            label="Urinary Osmonality"
            value={this.state.Urinary_Osmonality}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Urinary_Osmonality"
            fullWidth
          />
          <FormControl margin="normal" style={{ width: "100%" }}>
            <InputLabel htmlFor="title">Urinary Electrolytes</InputLabel>
            <Select
              onBlur={this.handleChange}
              value={this.state.urineElectrolytesOption}
              onChange={this.handlechange}
              name="urineElectrolytesOption"
              fullWidth
            >
              <MenuItem value={"Na+"}>Na+</MenuItem>
              <MenuItem value={"K+"}>K+</MenuItem>
              <MenuItem value={"Cl+"}>Cl+</MenuItem>
            </Select>
          </FormControl>
          {this.state.urineElectrolytesOption && (
            <TextField
              label={`Value for Urinary Electrolytes (${this.state.urineElectrolytesOption})`}
              onBlur={this.handleChange}
              value={this.state.urineElectrolytes}
              onChange={this.handlechange}
              name="urineElectrolytes"
              margin="normal"
              fullWidth
            />
          )}

          <TextField
            label="Spot Urinary Calcium Creatinine Ratio"
            value={this.state.spoturinaryratio}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="spoturinaryratio"
            fullWidth
          />
          <TextField
            label="24 Hours Urinary PO4"
            value={this.state.Analysis_24_Hours_Urinary_PO4}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Analysis_24_Hours_Urinary_PO4"
            fullWidth
          />
          <TextField
            label="24 Hour Urinary Calcium"
            value={this.state.Analysis_24_Hours_Urinary_calcium}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Analysis_24_Hours_Urinary_calcium"
            fullWidth
          />
          <TextField
            label="24 Hour Urinary Oxalate"
            value={this.state.Analysis_24_Hours_Urinary_Oxalate}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Analysis_24_Hours_Urinary_Oxalate"
            fullWidth
          />

          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <h3>8.8.2 CBC</h3>
            <IconButton onClick={this.handle_CBC_Info_Click} aria-label="info">
              <InfoIcon />
            </IconButton>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Test Name</TableCell>
                    <TableCell>Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Hb%</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_Hb_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_Hb_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>TC</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_tc_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_tc_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Neutrophil</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_neutrophil_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_neutrophil_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Lymphocyte</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_lymphocyte_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_lymphocyte_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Eosinophil</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_eosinophil_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_eosinophil_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Monocyte</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_monocyte_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_monocyte_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Platelete Count</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_Platlete_count_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_Platlete_count_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Reticulocyte Count</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_reticulocyteCount_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_reticulocyteCount_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div>
            <h3>8.8.3 PBF</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TextField
              label="PBF"
              value={this.state.Analysis_PBF}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_PBF"
              className={classes.textField}
            />
            <TextField
              label="RBC"
              value={this.state.Analysis_RBC}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_RBC"
              className={classes.textField}
            />
            <TextField
              label="WBC"
              value={this.state.Analysis_WBC}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_WBC"
              className={classes.textField}
            />
            <TextField
              label="Platelete"
              value={this.state.Analysis_Platelete}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_Platelete"
              className={classes.textField}
            />
          </div>
          <div>
            <h3>8.8.4 Renal Function Test</h3>
          </div>
          <div
            className={classes.boxContainer}
            style={{ gap: "20px", flexWrap: "wrap" }}
          >
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="S.Creatinine"
                value={this.state.s_creatinine}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="s_creatinine"
                className={classes.textField}
              />
              <span>mg/dl</span>
            </div>
            <TextField
              label="BUN"
              value={this.state.bun}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="bun"
              className={classes.textField}
            />
            <TextField
              label="S.Uric Acid"
              value={this.state.s_uric_acid}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="s_uric_acid"
              className={classes.textField}
            />
          </div>
          <div>
            <h3>8.8.5 Liver Function Test</h3>
          </div>
          <div className={classes.boxContainer} style={{ flexWrap: "wrap" }}>
            <TextField
              label="SGPT"
              value={this.state.liver_sgpt}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="liver_sgpt"
              className={classes.textField}
            />
            <TextField
              label="SGOT"
              value={this.state.liver_sgot}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="liver_sgot"
              className={classes.textField}
            />
            <TextField
              label="yGT"
              value={this.state.liver_ygt}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="liver_ygt"
              className={classes.textField}
            />
            <FormControl className={classes.textField} margin="normal">
              <InputLabel htmlFor="title">S. Bilirubin</InputLabel>
              <Select
                onBlur={this.handleChange}
                value={this.state.liver_s_bilirubin}
                onChange={this.handlechange}
                name="liver_s_bilirubin"
                fullWidth
              >
                <MenuItem value={"Total"}>Total</MenuItem>
                <MenuItem value={"Trace"}>Direct</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: "30px" }}>
            <h3>8.8.6 Blood Sugar Level</h3>
          </div>
          <div
            className={classes.boxContainer}
            style={{ gap: "20px", flexWrap: "wrap" }}
          >
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="Fasting"
                value={this.state.blood_sugar_fast}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="blood_sugar_fast"
                className={classes.textField}
              />
              <span>mmol/L</span>
            </div>
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="2 Hours After Breakfast"
                value={this.state.blood_sugar_after_breakfast}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="blood_sugar_after_breakfast"
                className={classes.textField}
              />
              <span>mmol/L</span>
            </div>
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextField
                label="Random Blood Glucose"
                value={this.state.blood_sugar_random}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="blood_sugar_random"
                className={classes.textField}
              />
              <span>mmol/L</span>
            </div>
          </div>

          <div style={{ marginTop: "30px" }}>
            <h3>8.8.7 Lipid Profile</h3>
          </div>
          <div
            className={classes.boxContainer}
            style={{ gap: "20px", flexWrap: "wrap" }}
          >
            <TextField
              label="Serum Total Protein"
              value={this.state.analysis_serum_total_protein}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_serum_total_protein"
              className={classes.textField}
            />
            <TextField
              label="S. Cholesterol"
              value={this.state.analysis_s_cholesterol}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_s_cholesterol"
              className={classes.textField}
            />
            <TextField
              label="HDL"
              value={this.state.analysis_hdl}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_hdl"
              className={classes.textField}
            />
            <TextField
              label="LDL"
              value={this.state.analysis_ldl}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_ldl"
              className={classes.textField}
            />
            <TextField
              label="Triglyceride"
              value={this.state.analysis_triglyceride}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_triglyceride"
              className={classes.textField}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            <h3>8.8.8 Thyroid Function Test</h3>
          </div>
          <div
            className={classes.boxContainer}
            style={{ gap: "20px", flexWrap: "wrap" }}
          >
            <TextField
              label="FT4"
              value={this.state.analysis_ft4}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_ft4"
              className={classes.textField}
            />
            <TextField
              label="T4"
              value={this.state.analysis_t4}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_t4"
              className={classes.textField}
            />
            <TextField
              label="T3"
              value={this.state.analysis_t3}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_t3"
              className={classes.textField}
            />
            <TextField
              label="TSH"
              value={this.state.analysis_tsh}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_tsh"
              className={classes.textField}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            <h3>8.8.9 Viral Markers</h3>
          </div>
          <div
            className={classes.boxContainer}
            style={{ gap: "20px", flexWrap: "wrap" }}
          >
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">HBS Ag</FormLabel>
              <RadioGroup
                aria-label="position"
                name="analysis_hbs_ag"
                label="HBS Ag"
                value={this.state.analysis_hbs_ag}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"POSITIVE"}
                  control={<Radio color="secondary" />}
                  label="POSITIVE"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"NEGATIVE"}
                  control={<Radio color="secondary" />}
                  label="NEGATIVE"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Anti HBS</FormLabel>
              <RadioGroup
                aria-label="position"
                name="analysis_anti_hbs"
                label="Anti HBS"
                value={this.state.analysis_anti_hbs}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"POSITIVE"}
                  control={<Radio color="secondary" />}
                  label="POSITIVE"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"NEGATIVE"}
                  control={<Radio color="secondary" />}
                  label="NEGATIVE"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Anti HCB</FormLabel>
              <RadioGroup
                aria-label="position"
                name="analysis_anti_hcb"
                label="Anti HBS"
                value={this.state.analysis_anti_hcb}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"POSITIVE"}
                  control={<Radio color="secondary" />}
                  label="POSITIVE"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"NEGATIVE"}
                  control={<Radio color="secondary" />}
                  label="NEGATIVE"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Anti HAV</FormLabel>
              <RadioGroup
                aria-label="position"
                name="analysis_anti_hav"
                label="Anti HBS"
                value={this.state.analysis_anti_hav}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"POSITIVE"}
                  control={<Radio color="secondary" />}
                  label="POSITIVE"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"NEGATIVE"}
                  control={<Radio color="secondary" />}
                  label="NEGATIVE"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Hepatitis B</FormLabel>
              <RadioGroup
                aria-label="position"
                name="analysis_hepatitis_b"
                label="Anti HBS"
                value={this.state.analysis_hepatitis_b}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"POSITIVE"}
                  control={<Radio color="secondary" />}
                  label="POSITIVE"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"NEGATIVE"}
                  control={<Radio color="secondary" />}
                  label="NEGATIVE"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Anti HEV</FormLabel>
              <RadioGroup
                aria-label="position"
                name="analysis_anti_hev"
                label="Anti HBS"
                value={this.state.analysis_anti_hev}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"POSITIVE"}
                  control={<Radio color="secondary" />}
                  label="POSITIVE"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"NEGATIVE"}
                  control={<Radio color="secondary" />}
                  label="NEGATIVE"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h3>8.8.10 Torch Screening</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "flex-end",
              gap: "30px",
            }}
          >
            <div>
              <h3 style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.54)" }}>
                Toxoplasma
              </h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <FormControl
                  component="fieldset"
                  className={classes.formControlnew}
                >
                  <FormLabel component="legend">
                    <h5>IgG</h5>
                  </FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="analysis_toxoplasma_igg"
                    label="Anti HBS"
                    value={this.state.analysis_toxoplasma_igg}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"POSITIVE"}
                      control={<Radio color="secondary" />}
                      label="POSITIVE"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NEGATIVE"}
                      control={<Radio color="secondary" />}
                      label="NEGATIVE"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.formControlnew}
                >
                  <FormLabel component="legend">
                    <h5>IgM</h5>
                  </FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="analysis_toxoplasma_igm"
                    label="Anti HBS"
                    value={this.state.analysis_toxoplasma_igm}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"POSITIVE"}
                      control={<Radio color="secondary" />}
                      label="POSITIVE"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NEGATIVE"}
                      control={<Radio color="secondary" />}
                      label="NEGATIVE"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div>
              <h3 style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.54)" }}>
                Rubella
              </h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <FormControl
                  component="fieldset"
                  className={classes.formControlnew}
                >
                  <FormLabel component="legend">
                    <h5>IgG</h5>
                  </FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="analysis_rubella_igg"
                    label="Anti HBS"
                    value={this.state.analysis_rubella_igg}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"POSITIVE"}
                      control={<Radio color="secondary" />}
                      label="POSITIVE"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NEGATIVE"}
                      control={<Radio color="secondary" />}
                      label="NEGATIVE"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.formControlnew}
                >
                  <FormLabel component="legend">
                    <h5>IgM</h5>
                  </FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="analysis_rubella_igm"
                    label="Anti HBS"
                    value={this.state.analysis_rubella_igm}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"POSITIVE"}
                      control={<Radio color="secondary" />}
                      label="POSITIVE"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NEGATIVE"}
                      control={<Radio color="secondary" />}
                      label="NEGATIVE"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div>
              <h3 style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.54)" }}>
                CMV
              </h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <FormControl
                  component="fieldset"
                  className={classes.formControlnew}
                >
                  <FormLabel component="legend">
                    <h5>IgG</h5>
                  </FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="analysis_cmv_igg"
                    label="Anti HBS"
                    value={this.state.analysis_cmv_igg}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"POSITIVE"}
                      control={<Radio color="secondary" />}
                      label="POSITIVE"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NEGATIVE"}
                      control={<Radio color="secondary" />}
                      label="NEGATIVE"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.formControlnew}
                >
                  <FormLabel component="legend">
                    <h5>IgM</h5>
                  </FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="analysis_cmv_igm"
                    label="Anti HBS"
                    value={this.state.analysis_cmv_igm}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"POSITIVE"}
                      control={<Radio color="secondary" />}
                      label="POSITIVE"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NEGATIVE"}
                      control={<Radio color="secondary" />}
                      label="NEGATIVE"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <TextField
              style={{ width: "100%" }}
              label="Syphillis"
              value={this.state.analysis_syphillis}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="analysis_syphillis"
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            <h3>8.8.11 Complement</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TextField
              label="C3"
              value={this.state.analysis_complement_c3}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_complement_c3"
              className={classes.textField}
            />
            <TextField
              label="C4"
              value={this.state.analysis_complement_c4}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_complement_c4"
              className={classes.textField}
            />
            <TextField
              label="CH50"
              value={this.state.analysis_complement_ch50}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_complement_ch50"
              className={classes.textField}
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            <h3>8.8.12 ANCA</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">P- ANCA</FormLabel>
              <RadioGroup
                aria-label="position"
                name="analysis_anca_p_anca"
                label="Anti HBS"
                value={this.state.analysis_anca_p_anca}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"POSITIVE"}
                  control={<Radio color="secondary" />}
                  label="POSITIVE"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"NEGATIVE"}
                  control={<Radio color="secondary" />}
                  label="NEGATIVE"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="ANA"
              value={this.state.analysis_anca_ana}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_anca_ana"
              className={classes.textField}
            />
            <TextField
              label="Anti ds DNA"
              value={this.state.analysis_anca_anti_ds_dna}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_anca_anti_ds_dna"
              className={classes.textField}
            />
            <TextField
              label="ENA profile"
              value={this.state.analysis_anca_ena_profile}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_anca_ena_profile"
              className={classes.textField}
            />
            <FormControl className={classes.textField} margin="normal">
              <InputLabel htmlFor="title">Coombs</InputLabel>
              <Select
                onBlur={this.handleChange}
                value={this.state.analysis_anca_coombs}
                onChange={this.handlechange}
                name="analysis_anca_coombs"
                fullWidth
              >
                <MenuItem value={"Direct"}>Direct</MenuItem>
                <MenuItem value={"Indirect"}>Indirect</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="MT"
              value={this.state.analysis_anca_mt}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="analysis_anca_mt"
              className={classes.textField}
            />
          </div>

          <div>
            <h3>8.8.13 -prev Radio Isotope Scan</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <FormControl
              component="fieldset"
              className={classes.formControlnew}
            >
              <FormLabel component="legend">
                <h5>DMSA</h5>
              </FormLabel>
              <div className={classes.boxContainer}>
                <TextField
                  label="Result"
                  value={this.state.Analysis_DMSA_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_DMSA_DOA"
                  className={classes.textField}
                />
              </div>
            </FormControl>
            <FormControl
              component="fieldset"
              className={classes.formControlnew}
            >
              <FormLabel component="legend">
                <h5>DTPA</h5>
              </FormLabel>
              <div className={classes.boxContainer}>
                <TextField
                  label="Result"
                  value={this.state.Analysis_DTPA_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_DTPA_DOA"
                  className={classes.textField}
                />
              </div>
            </FormControl>
          </div>

          <div>
            <h3>8.8.14 Radiology</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <FormControl
              component="fieldset"
              className={classes.formControlnew}
            >
              <FormLabel component="legend">
                <h5>Ultrasonography (USG)</h5>
              </FormLabel>
              <div className={classes.boxContainer}>
                <TextField
                  label="Result"
                  value={this.state.Analysis_USG_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_USG_DOA"
                  className={classes.textField}
                />
              </div>
            </FormControl>
            <FormControl
              component="fieldset"
              className={classes.formControlnew}
            >
              <FormLabel component="legend">
                <h5>Ultrasonography (KUB)</h5>
              </FormLabel>
              <div className={classes.boxContainer}>
                <TextField
                  label="Result"
                  value={this.state.Analysis_KUB_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_KUB_DOA"
                  className={classes.textField}
                />
              </div>
            </FormControl>
          </div>
          <TextField
            label="chest X Ray"
            value={this.state.chestXRay}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="chestXRay"
            fullWidth
          />
          <TextField
            label="Plain X Ray Abdomen"
            value={this.state.PlainXRayAbdomen}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="PlainXRayAbdomen"
            fullWidth
          />

          <TextField
            label="Other X Ray"
            value={this.state.generalxray}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="generalxray"
            fullWidth
          />

          <FormControl component="fieldset" className={classes.formControlnew}>
            <FormLabel component="legend">
              <h5>MCUG</h5>
            </FormLabel>
            <div className={classes.boxContainer}>
              <TextField
                label="Result"
                value={this.state.Analysis_MCUG_DOA}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="Analysis_MCUG_DOA"
                className={classes.textField}
              />
            </div>
          </FormControl>
          <TextField
            label="IVU"
            value={this.state.ivu}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="ivu"
            fullWidth
          />
          {/* //others */}
          <div>
            <h3>8.8.15 Others</h3>
          </div>
          <TextField
            label="Uric Acid"
            value={this.state.uricAcid}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="uricAcid"
            fullWidth
          />
          <TextField
            label="Fasting Lipid Profile"
            value={this.state.fasting_lipid_profile}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="fasting_lipid_profile"
            fullWidth
          />
          <TextField
            label="SGPT"
            value={this.state.sgpt}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="sgpt"
            fullWidth
          />
          <TextField
            label="MT"
            value={this.state.mt}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="mt"
            fullWidth
          />
          <TextField
            label="RBS"
            value={this.state.rbs}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="rbs"
            fullWidth
          />

          {/* new */}
          <TextField
            label="ASO Titre"
            value={this.state.aso}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="aso"
            fullWidth
          />
          <TextField
            label="Thyroid Function Test"
            value={this.state.thyroidtest}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="thyroidtest"
            fullWidth
          />

          {/* new */}
        </div>
        <div style={{ margin: "32px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Test Name</TableCell>
                    <TableCell>Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Urinary Electrolytes</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_urinary_electrolytes_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_urinary_electrolytes_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Serum Osmonality</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_S_Osmolality_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_S_Osmolality_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>HBsAg</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_HBsAg_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_HBsAg_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Anti HCV</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_AntiHCV_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_AntiHCV_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. Ferritin</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_S_Ferritin_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_S_Ferritin_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. Albumin</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_albumin_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_albumin_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. Cholesterol</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_cholestrol_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_cholestrol_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. Creatinine</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_creatinin_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_creatinin_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Blood uria</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_blood_urea_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_blood_urea_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. Calcium</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_calcium_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_calcium_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Serum Inorganic Phosphate</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_phosphate_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_phosphate_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. Alkaline Phosphatase</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_alkainePhosphate_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_alkainePhosphate_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>PTH</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_PTH_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_PTH_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. C3</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_c3_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_c3_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>S. C4</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_s_c4_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_s_c4_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>ANA</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_ANA_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_ANA_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Anti ds DNA</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_anti_DS_DNA_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_anti_DS_DNA_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>C ANCA</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_c_ANCA_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_c_ANCA_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>P ANCA</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_p_ANCA_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_p_ANCA_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>CXR</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_CXR_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_CXR_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>kidney biopsy</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_Kidney_biopsy_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_Kidney_biopsy_DOA"
                        className={classes.textField}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <h5>Others</h5>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Result"
                        value={this.state.Analysis_OTHER_DOA}
                        onBlur={this.handleChange}
                        onChange={this.handlechange}
                        margin="normal"
                        name="Analysis_OTHER_DOA"
                        multiline
                        style={{ margin: 8, width: 450 }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <FormControl
                component="fieldset"
                className={classes.formControlnew}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <FormLabel component="legend">
                  <h5>S. Electrolyte</h5>
                </FormLabel>

                <TextField
                  label="Na+"
                  value={this.state.Analysis_s_electrolytesNa_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_s_electrolytesNa_DOA"
                  className={classes.textField}
                />
                <TextField
                  label="k+"
                  value={this.state.Analysis_s_electrolytesK_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_s_electrolytesK_DOA"
                  className={classes.textField}
                />
                <TextField
                  label="cl-"
                  value={this.state.Analysis_s_electrolytesCL_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_s_electrolytesCL_DOA"
                  className={classes.textField}
                />
                <TextField
                  label="TCO2"
                  value={this.state.Analysis_s_electrolytesTCO_DOA}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_s_electrolytesTCO_DOA"
                  className={classes.textField}
                />
              </FormControl>
            </div>

            <div>
              <h3>8.8.16 Iron Profile</h3>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <FormControl
                component="fieldset"
                className={classes.formControlnew}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "32px",
                }}
              >
                <TextField
                  label="TIBC"
                  value={this.state.Analysis_TIBC}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_TIBC"
                  className={classes.textField}
                />
                <TextField
                  label="S.Iron"
                  value={this.state.Analysis_S_IRON}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_S_IRON"
                  className={classes.textField}
                />
                <TextField
                  label="Ferritin (mg/ml)"
                  value={this.state.Analysis_Ferritin}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  margin="normal"
                  name="Analysis_Ferritin"
                  className={classes.textField}
                />
              </FormControl>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <TextField
              style={{ width: "100%" }}
              label="S. Calcium (mg/dl)"
              value={this.state.Analysis_S_Calcium}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_S_Calcium"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="Inorganic Phosphate"
              value={this.state.Analysis_Inorganic_Phosphate}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_Inorganic_Phosphate"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="PTH"
              value={this.state.Analysis_PTH}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_PTH"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="S. Alkaline Phosphate"
              value={this.state.Analysis_S_Alkaline_Phosphate}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_S_Alkaline_Phosphate"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="Vitamin D"
              value={this.state.Analysis_Vitamin_D}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_Vitamin_D"
              className={classes.textField}
            />
          </div>
          <div>
            <div>
              <h3>8.8.17 Renal Biopsy</h3>
            </div>

            <TextField
              style={{ width: "100%" }}
              label="Light Microscopy"
              value={this.state.Analysis_Light_Microscopy}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_Light_Microscopy"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="DIF"
              value={this.state.Analysis_DIF}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_DIF"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="Elective Microscopy"
              value={this.state.Analysis_Elective_Microscopy}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_Elective_Microscopy"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="USG of KUB"
              value={this.state.Analysis_USG_of_KUB}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_USG_of_KUB"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="Ivv"
              value={this.state.Analysis_Ivv}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_Ivv"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="MCU & RGV"
              value={this.state.Analysis_MCU_RGV}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_MCU_RGV"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="CT scan of Abdomen/Brain"
              value={this.state.Analysis_CT_Scan_Abdomen_or_Brain}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_CT_Scan_Abdomen_or_Brain"
              className={classes.textField}
            />
            <TextField
              style={{ width: "100%" }}
              label="CT Urogram"
              value={this.state.Analysis_CT_Urogram}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_CT_Urogram"
              className={classes.textField}
            />
          </div>
          <div>
            <div>
              <h3>8.8.18 DTPA</h3>
            </div>

            <TextField
              style={{ width: "100%" }}
              label="DTPA"
              value={this.state.Analysis_DTPA}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="Analysis_DTPA"
              className={classes.textField}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Analysis);
