import React from "react";
// import { withStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
// import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
// import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
// import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// const mAccordion = withStyles({
//   root: {
//     boxShadow: "none",
//     "&:not(:last-child)": {
//       borderBottom: 0,
//     },
//     "&:before": {
//       display: "none",
//     },
//     "&$expanded": {
//       margin: "auto",
//     },
//   },

//   expanded: {},
// })(Accordion);

// const mAccordionSummary = withStyles({
//   root: {
//     backgroundColor: "#FAFAFA",
//     minHeight: 56,
//     "&$expanded": {
//       minHeight: 56,
//     },
//   },
//   content: {
//     "&$expanded": {
//       margin: "12px 0",
//     },
//   },
//   expanded: {},
// })(AccordionSummary);

// const ExpansionPanelDetails = withStyles((_) => ({
//   root: {},
// }))(AccordionDetails);

export default function Accordian() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ padding: "16px" }}>
      <Accordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>How to add a patient</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ background: "#FAFAFA", fontFamily: "Nunito Sans" }}
        >
          <div>
            <Typography>
              Go to the home page and click on the ‘Add New patient’ button and
              fill the required form and press the submit button below to add a
              new patient in the BSMMU- Patient Record Management System.
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <ExpansionPanel
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        
        
      </ExpansionPanel> */}
      {/* <ExpansionPanel
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      > */}
      <Accordion
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>How to add a patient's visit information</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ background: "#FAFAFA", fontFamily: "Nunito Sans" }}
        >
          <Typography>
            Go to the home page and click on the ‘New Visit’ button which will
            take you to the ‘Patient List for Visit’ table. Click on the plus
            icon to add a new visit against the selected patient after which it
            will take you the visit information page where you would see a ‘Add
            New Visit’ button. Click on the ‘Add New Visit’ button and fill the
            required form and press the submit button below to add a new patient
            visit in the BSMMU- Patient Record Management System.
          </Typography>
        </AccordionDetails>
        {/* </ExpansionPanel> */}
      </Accordion>
      {/* <ExpansionPanel
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      > */}
      <Accordion
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography>How to update a patient's basic information</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ background: "#FAFAFA", fontFamily: "Nunito Sans" }}
        >
          <Typography>
            Go to the home page and click on the ‘All patient’ button which will
            take you to the ‘Patient List’ table. Click on the Edit icon to
            update the patient information in the BSMMU- Patient Record
            Management System.
          </Typography>
        </AccordionDetails>
        {/* </ExpansionPanel> */}
      </Accordion>
    </div>
  );
}
