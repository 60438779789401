import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./components/log-and-register/Login";
import Patient from "./components/patient/Patient";
import Dashboard from "./components/dashboard/Dashboard";
import PatientList from "./components/patient/PatientList";
import PatientVisit from "./components/Visit/PatientVisit";
import SimpleModal from "./components/layout/FeedbackModal";
import ViewPatient from "./components/patient/ViewPatient";
import SelectVInfo from "./components/Visit/SelectVInfo";
import withAuth from "./withAuth";
import Stats from "./components/stats/Stats";
import { getToken } from "./components/log-and-register/Auth";
import axios from "axios";

//Disable Console Log
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

// Multi Tab Auth
axios.defaults.headers.common = {
  token: getToken(),
};
class App extends Component {
  render() {
    return (
      <div className="App">
        <SimpleModal />
        <div className="Content">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/home" component={withAuth(Dashboard)} />
            <Route exact path="/stats" component={withAuth(Stats)} />
            <Route exact path="/newpatient" component={withAuth(Patient)} />
            <Route exact path="/editpatient" component={withAuth(Patient)} />
            <Route exact path="/viewpatient" component={withAuth(ViewPatient)} />
            <Route exact path="/patientList" component={withAuth(PatientList)} />
            <Route exact path="/addvisit" component={withAuth(PatientVisit)} />
            <Route exact path="/vinfo" component={withAuth(SelectVInfo)} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
