export function updateOnProps(prevProps, updates, component) {
  const setStateObject = {};
  updates.forEach((update) => {
    if (component.props.data[update] !== prevProps.data[update]) {
      setStateObject[update] = component.props.data[update];
    }
  });
  if (Object.keys(setStateObject).length !== 0) {
    component.setState(setStateObject);
  }
}
