import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import '../patient/Create-patient.css';
import SimpleCard from './SummaryViewCard';
import Typography from '@material-ui/core/Typography';

const style = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
    height: {
        height: '100%',
    },
    height2: {
        height: '100%',
    },
});

class SummaryView extends Component {
    componentDidUpdate(prevProps) {
        if (
            this.props.data.summary !== prevProps.data.summary ||
            this.props.data.diagnosis !== prevProps.data.diagnosis ||
            this.props.data.treatment !== prevProps.data.treatment
        ) {
            this.setState({
                summary: this.props.data.summary,
                diagnosis: this.props.data.diagnosis,
                treatment: this.props.data.treatment,
            });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            summary: '',
            diagnosis: '',
            treatment: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(evt) {
        evt.preventDefault();
        axios
            .put(
                (process.env.NODE_ENV === 'development' ? 'http://localhost:40000' : '') +
                    `/api/patient/${this.props.location.state}`,
                this.state
            )
            .then((_) => {
                this.setState({ redirectState: true });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    render() {
        return (
            <div>
                <Typography variant='h5' component='h2' style={{ margin: 8 }}>
                    Summary Details Cards:
                </Typography>
                <SimpleCard name={'Summary'} text={this.state.summary} />
                <SimpleCard name={'Diagnosis'} text={this.state.diagnosis} />
                <SimpleCard name={'Treatment'} text={this.state.treatment} />
            </div>
        );
    }
}

export default withStyles(style)(SummaryView);
