import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import SearchAppBar from "../layout/Appbar";
import SocioEconomicstatus from "./SocioEconomicstatus";
import Basic from "./Basic";
import ChiefComplaints from "./ChiefComplaints";
import GeneralExam from "./GeneralExam";
import Genituonary from "./Genituonary";
import CVS from "./CVS";
import Chest from "./Chest";
import ABDOMEN from "../Visit/Abdomen";
import CNS from "./CNS";
import Musculoskeletal from "./Musculoskeletal";
import Analysis from "./Analysis";
import NavBar from "../layout/Navbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { Redirect, Link } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import HistoryofIllness from "./HistoryofIllness";
import PastHistory from "./Pastmedicalhistory";
import FormControl from "@material-ui/core/FormControl";
import DrugHistory from "./Drughistory";
import FamilyHistory from "./Familyhistory";
import StatTableModal from "../Modal/StatTableModal";
import moment from "moment";
import isEqual from "lodash/isEqual";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  ContainsComponents: {
    width: "100%",
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 700,
  },
  expansion: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  accord: {
    background: "#f7f7f7",
  },
});

class Visit extends Component {
  obj = {};
  _timeout;
  event;
  value;

  constructor(props) {
    super(props);
    this.state = {
      summary: "",
      treatment: "",
      diagnosis: "",
      outcome: "",

      drugHistory: "",
      familyHistory: "",
      pastmedhis: "",
      presentIllnessHis: "",

      referredto: "",
      adviceforinvestigation: "",

      patient_id: this.props.location.state?.patientId,
      referredby: "",
      title: "",
      typeOfEnrollment: "",
      presentIllness: "",

      chiefcomplains_complain1: "",
      chiefcomplains_complain2: "",
      chiefcomplains_complain3: "",
      chiefcomplains_complain4: "",
      chiefcomplains_complain5: "",
      chiefcomplains_complain6: false,
      swelling_days: null,

      socioEconomicStatus_housingFacility: "",
      socioEconomicStatus_waterSupply: "",
      socioEconomicStatus_sanitation: "",
      socioEconomicStatus_monthlyIncome: "",
      socioEconomicStatus_travelHistory: "",

      general_appearance_specify: "",
      general_anemia_result: "",
      general_anemia_specify: "",
      general_jaundice_reslut: "", //jaundicep
      general_jaundice_specify: "", //jaundicep
      general_cyanosis_result: "",
      general_cyanosis_specify: "",
      general_clubbing_result: "", //clubbing
      general_clubbing_specify: "", //clubbing
      general_koilonychia_result: "",
      general_koilonychia_specify: "",
      general_leuconychia_result: "", //Leukonychia
      general_leuconychia_specify: "", //Leukonychia
      general_edema_result: "", //edema
      general_dehydration_result: "",
      generalSkinchange: "",
      general_bcg_mark_result: "",
      Signofmeningialirritation: false, //(present absent) [if present]
      neckrigidity: false, //checkbox
      kernigsSign: false, //checkbox
      brudzinskiSign: false, //checkbox
      general_jvp_specify: "", //header
      generalStatusRaised: "",
      generalStatusNormal: false,
      generalRaisedbymeasurement: "", // (open ended)
      generalBackandSpine: "", // (open ended)
      general_lymph_node_specify: "",
      general_bonyTenderness: "",
      general_bonyDeformity: "",
      VitalSigns: "",
      generalPulse: "",
      general_bp_specify: "",
      general_r_r_specify: "", //respiratory rate
      general_temperature_specify: "",
      generalEye: "", //(open ended)
      generalENT: "", //(open ended)
      ThroatExamResult: "",
      ThroatExamSpecify: "",
      bsua: "",
      urineColor: "",
      general_dedema_result: "", //edema
      general_dedema_specify: "", //edema
      general_anthropometry_wt_result: "",
      general_anthropometry_waz_result: "",
      general_anthropometry_ht_result: "",
      general_anthropometry_haz_result: "",
      general_anthropometry_ofc_result: "",
      general_anthropometry_muac_result: "",
      general_anthropometry_bmi_result: "",
      generalBodySurfaceArea: "",
      waistCircumference: "", //open ended
      general_bonyChange: "",
      general_swelling_result: "",
      general_swelling_specify: "",
      general_abdominal_girth_result: "",
      general_abdominal_girth_specify: "",
      general_gait_result: "",
      general_gait_specify: "",
      general_hr_result: "",
      general_hr_specify: "",
      general_side_effect_of_drug_result: "",
      general_side_effect_of_drug_specify: "",

      skinChange: "",
      eyes: "",
      teeth: "",
      genituorinary_fluidThrill_result: "",
      genituorinary_fluidThrill_desc: "",
      genituorinary_shiftingDullness_result: "",
      genituorinary_shiftingDullness_desc: "",
      genituorinary_colorOfUrin_result: "",
      genituorinary_colorOfUrin_desc: "",
      genituorinary_haematuria_result: "",
      genituorinary_haematuria_desc: "",
      genituorinary_frequency_result: "",
      genituorinary_frequency_desc: "",
      genituorinary_enuresis_result: "",
      genituorinary_enuresis_desc: "",
      genituorinary_Dysuria_result: "",
      genituorinary_Dysuria_desc: "",
      genituorinary_polyuria_result: "",
      genituorinary_polyuria_desc: "",
      genituorinary_frothy_result: "",
      genituorinary_frothy_desc: "",
      genituorinary_nocturia_result: "",
      genituorinary_nocturia_desc: "",
      genituorinary_abnormal_Genitalia_result: "",
      genituorinary_abnormal_Genitalia_desc: "",
      genituorinary_metal_Stenosis_result: "",
      genituorinary_metal_Stenosis_desc: "",
      genituorinary_phimosis_result: "",
      genituorinary_phimosis_desc: "",
      genituorinary_paraphemosis_result: "",
      genituorinary_paraphemosis_desc: "",
      genituorinary_epispadias_result: "",
      genituorinary_epispadias_desc: "",
      genituorinary_hypospadias_result: "",
      genituorinary_hypospadias_desc: "",
      genituorinary_character_of_straem_result: "",
      genituorinary_character_of_straem_desc: "",
      genituorinary_vaginal_discharge_abnormal_result: "",
      genituorinary_vaginal_discharge_abnormal_desc: "",
      genituorinary_bladder_control_result: "",
      genituorinary_bladder_control_desc: "",
      genituorinary_menstural_control_abnormal_result: "",
      genituorinary_menstural_control_abnormal_desc: "",

      csv_pulse_result: "",
      csv_pulse_Specify: "",
      csv_bp_result: "",
      csv_bp_Specify: "",
      csv_jvp_result: "",
      csv_jvp_Specify: "",
      csv_avFistula_result: "",
      csv_avFistula_Specify: "",
      csv_cvLine_result: "",
      csv_cvLine_Specify: "",
      csv_csv_cardiomegaly_result: "",
      csv_csv_cardiomegaly_Specify: "",

      geni_Inspection: "",
      geni_Palpation: "",
      geni_Palpation_kidney: "",
      geni_Palpation_ub: "",
      geni_Palpation_rt: "",
      geni_Palpation_genitalia: "",
      geni_Percussion: "",
      geni_Auscultation: "",

      csv_Inspection: "",
      csv_Palpation: "",
      csv_Percussion: "",
      csv_Auscultation: "",

      Chest_Inspection: "",
      Chest_shapeofchest: "",
      Chest_respiratoryPattern: "",
      Chest_visibleImpulse: "",
      Chest_visibleImpulseSpecify: "",
      Chest_ScarMarks: "",
      Chest_ScarMarksSpecify: "",

      Chest_barrelShape_result: "",
      Chest_barrelShape_Specify: "",
      Chest_pectusShape_result: "",
      Chest_pectusShape_Specify: "",

      Chest_Palpation: "",
      Chest_positionoftrachea: "",
      Chest_apexBeat: "",
      Chest_vocalFremitus: "",
      Chest_expansibility: "",

      Chest_Percussion: "",
      Chest_percussionNote: "",
      Chest_percussionNoteNext: "",

      Chest_Auscultation: "",
      Chest_BreathSound: "",
      Chest_addedSound: "",

      Abdomen_Inspection: "",
      gastro_any_distension: "",
      gastro_overlappingSkin: "",
      gastro_engorgedVein: "",
      gastro_scarMarks: "",
      gastro_pdCatheder: "",

      Abdomen_Palpation: "",
      gastro_tenderness: "",
      gastro_rigidity: "",
      gastro_liver: "",
      gastro_spleen: "",
      gastro_urinaryBladder: "",
      gastro_kidney: "",
      gastro_Lumps: "",

      Abdomen_Percussion: "",
      gastro_Shiftingdullness: "",
      gastro_FluidThrills: "",

      Abdomen_Auscultation: "",
      gastro_bowelSound: "",

      oralcavity: "",

      cns_motorFunctionsAndReflexes: "",
      cns_cerebellarFunction: "",
      cns_sensory: "",
      cns_cranialNerves: "",
      cns_gait: "",
      General_Intellectual_Functions: "",

      Muscoskeletal_system_muscle_wasting_result: "",
      Muscoskeletal_system_muscle_wasting_Specify: "",
      Muscoskeletal_system_weakness_result: "",
      Muscoskeletal_system_weakness_Specify: "",
      Muscoskeletal_system_hemihypertrophy_result: "",
      Muscoskeletal_system_hemihypertrophy_Specify: "",
      Muscoskeletal_system_joint_swelling_result: "",
      Muscoskeletal_system_joint_swelling_Specify: "",
      Muscoskeletal_system_spine_result: "",
      Muscoskeletal_system_spine_Specify: "",
      Muscoskeletal_system_sacrum_result: "",
      Muscoskeletal_system_sacrum_Specify: "",
      Muscoskeletal_system_limb_deformity_result: "",
      Muscoskeletal_system_limb_deformity_Specify: "",
      Muscoskeletal_system_wrist_swelling_result: "",
      Muscoskeletal_system_wrist_swelling_Specify: "",
      musculo_other: "",
      musculo_look: "",
      musculo_feel: "",
      musculo_move: "",

      urineRoutineExam: "",
      urineCSwithC_C: "",

      Analysis_UTP_DOA: "",
      Analysis_UTP_DOD: "",
      Analysis_spotPCR_DOA: "",
      Analysis_spotPCR_DOD: "",
      Analysis_micro_albuminuria_DOA: "",
      Analysis_micro_albuminuria_DOD: "",
      Analysis_urinary_electrolytes_DOA: "",
      Analysis_urinary_electrolytes_DOD: "",
      Analysis_Hb_DOA: "",
      Analysis_Hb_DOD: "",
      Analysis_tc_DOA: "",
      Analysis_tc_DOD: "",
      Analysis_neutrophil_DOA: "",
      Analysis_neutrophil_DOD: "",
      Analysis_lymphocyte_DOA: "",
      Analysis_lymphocyte_DOD: "",
      Analysis_eosinophil_DOA: "",
      Analysis_eosinophil_DOD: "",
      Analysis_monocyte_DOA: "",
      Analysis_monocyte_DOD: "",
      Analysis_Platlete_count_DOA: "",
      Analysis_Platlete_count_DOD: "",
      Analysis_reticulocyteCount_DOA: "",
      Analysis_reticulocyteCount_DOD: "",
      Analysis_S_Osmolality_DOA: "",
      Analysis_S_Osmolality_DOD: "",
      Analysis_HBsAg_DOA: "",
      Analysis_HBsAg_DOD: "",
      Analysis_AntiHCV_DOA: "",
      Analysis_AntiHCV_DOD: "",
      Analysis_S_Ferritin_DOA: "",
      Analysis_S_Ferritin_DOD: "",
      Analysis_s_albumin_DOA: "",
      Analysis_s_albumin_DOD: "",
      Analysis_s_cholestrol_DOA: "",
      Analysis_s_cholestrol_DOD: "",
      Analysis_s_creatinin_DOA: "",
      Analysis_s_creatinin_DOD: "",
      Analysis_blood_urea_DOA: "",
      Analysis_blood_urea_DOD: "",
      Analysis_s_electrolytesNa_DOA: "",
      Analysis_s_electrolytesNa_DOD: "",
      Analysis_s_electrolytesK_DOA: "",
      Analysis_s_electrolytesK_DOD: "",
      Analysis_s_electrolytesCL_DOA: "",
      Analysis_s_electrolytesCL_DOD: "",
      Analysis_s_electrolytesTCO_DOA: "",
      Analysis_s_electrolytesTCO_DOD: "",
      Analysis_s_calcium_DOA: "",
      Analysis_s_calcium_DOD: "",
      Analysis_s_phosphate_DOA: "",
      Analysis_s_phosphate_DOD: "",
      Analysis_s_alkainePhosphate_DOA: "",
      Analysis_s_alkainePhosphate_DOD: "",
      Analysis_PTH_DOA: "",
      Analysis_PTH_DOD: "",
      Analysis_s_c3_DOA: "",
      Analysis_s_c3_DOD: "",
      Analysis_s_c4_DOA: "",
      Analysis_s_c4_DOD: "",
      Analysis_ANA_DOA: "",
      Analysis_ANA_DOD: "",
      Analysis_anti_DS_DNA_DOA: "",
      Analysis_anti_DS_DNA_DOD: "",
      Analysis_c_ANCA_DOA: "",
      Analysis_c_ANCA_DOD: "",
      Analysis_p_ANCA_DOA: "",
      Analysis_p_ANCA_DOD: "",
      Analysis_CXR_DOA: "",
      Analysis_CXR_DOD: "",
      Analysis_USG_DOA: "",
      Analysis_USG_DOD: "",
      Analysis_MCUG_DOA: "",
      Analysis_MCUG_DOD: "",
      Analysis_DTPA_DOA: "",
      Analysis_DTPA_DOD: "",
      Analysis_DMSA_DOA: "",
      Analysis_DMSA_DOD: "",
      Analysis_OTHER_DOA: "",
      Analysis_OTHER_DOD: "",
      Analysis_Kidney_biopsy_DOA: "",
      Analysis_Kidney_biopsy_DOD: "",
      Analysis_24_Hours_Urinary_PO4: "",
      Analysis_24_Hours_Urinary_calcium: "",
      Analysis_24_Hours_Urinary_Oxalate: "",

      Analysis_PBF: "",
      Analysis_RBC: "",
      Analysis_WBC: "",
      Analysis_Platelete: "",
      Analysis_TIBC: "",
      Analysis_S_IRON: "",
      Analysis_Ferritin: "",
      Analysis_S_Calcium: "",
      Analysis_Inorganic_Phosphate: "",
      Analysis_PTH: "",
      Analysis_S_Alkaline_Phosphate: "",
      Analysis_Vitamin_D: "",
      Analysis_Light_Microscopy: "",
      Analysis_DIF: "",
      Analysis_Elective_Microscopy: "",
      Analysis_USG_of_KUB: "",
      Analysis_Ivv: "",
      Analysis_MCU_RGV: "",
      Analysis_CT_Scan_Abdomen_or_Brain: "",
      Analysis_CT_Urogram: "",
      Analysis_DTPA: "",

      submitted: false,
      fasting_lipid_profile: "",
      sgpt: "",
      mt: "",
      rbs: "",
      urineElectrolytes: "",
      urineElectrolytesOption: "",
      urineProtein: "",
      urinePuscul: "",
      urineRbc: "",
      urinePh: "",
      urineSpecificGravity: "",
      Urinary_Osmonality: "",
      chestXRay: "",
      Analysis_KUB_DOA: "",
      Analysis_KUB_DOD: "",
      PlainXRayAbdomen: "",
      ivu: "",
      uricAcid: "",
      crt: "",
      aso: "",
      thyroidtest: "",
      serumosmonality: "",
      spoturinaryratio: "",
      urinarycalcium24hrs: "",
      generalxray: "",
      modalOpen: false,
      allVisits: [],
      modalRows: [],
      modalColumns: [],
      isFormValid: false,
    };
    this.obj = this.state;
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSummaryChange = this.handleSummaryChange.bind(this);
    this.handleTreatmentChange = this.handleTreatmentChange.bind(this);
    this.handleDiagnosisChange = this.handleDiagnosisChange.bind(this);
    this.handleOutcomeChange = this.handleOutcomeChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleIllnessChange = this.handleIllnessChange.bind(this);
    this.handleMedicalHistoryChange =
      this.handleMedicalHistoryChange.bind(this);
    this.handleDrugHistoryChange = this.handleDrugHistoryChange.bind(this);
    this.handleFamilyHistoryChange = this.handleFamilyHistoryChange.bind(this);
    this.handleReferChange = this.handleReferChange.bind(this);
    this.handleAdviceChange = this.handleAdviceChange.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSetModalStateChange = this.handleSetModalStateChange.bind(this);
  }

  handleModalClick() {
    this.setState({ modalOpen: true });
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  handleSetModalStateChange(columns) {
    const rows = this.state.allVisits.map((visit) => {
      const row = {};
      row.id = visit._id;
      columns.map((item) => {
        row[item.field] = visit[item.field] || "_";
      });
      row.visitDate = moment(visit.date).format("MM-DD-YYYY");
      row.visitType = visit.title || "_";
      return row;
    });
    this.setState({ modalRows: rows, modalColumns: columns });
  }

  handleChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleCheck(e) {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  TimerFunction(value, name) {
    if (this._timeout) {
      //if there is already a timeout in process cancel it
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(() => {
      this._timeout = null;
      this.setState({
        [name]: value.trim(),
      });
    }, 0);
  }

  handleSummaryChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "summary");
  }
  handleTreatmentChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "treatment");
  }
  handleDiagnosisChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "diagnosis");
  }
  handleOutcomeChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "outcome");
  }

  handleMedicalHistoryChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "pastmedhis");
  }
  handleDrugHistoryChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "drugHistory");
  }
  handleFamilyHistoryChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "familyHistory");
  }

  handleIllnessChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "presentIllnessHis");
  }

  handleReferChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "referredto");
  }

  handleAdviceChange(e) {
    this.value = e.target.value;
    this.TimerFunction(this.value, "adviceforinvestigation");
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if (!this.state.patient_id) {
      alert("Can't create visit without patient ID");
      return;
    }
    const baseUrl =
      process.env.NODE_ENV === "development" ? "http://localhost:40000" : "";
    const routeState = this.props.location.state;
    const url =
      baseUrl +
      `/api/visit${
        routeState?.pageAction === "update" ? `/${routeState?.id}` : ""
      }`;

    this.setState({ submitting: true });
    const requestPromise =
      routeState?.pageAction === "update"
        ? axios.put(url, this.state)
        : axios.post(url, this.state);

    requestPromise
      .then(() => {
        this.setState({ submitting: false, submitted: true });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const prevWt = prevState.general_anthropometry_wt_result;
    const prevHt = prevState.general_anthropometry_ht_result;
    const currentWt = this.state.general_anthropometry_wt_result;
    const currentHt = this.state.general_anthropometry_ht_result;

    if (prevWt !== currentWt || prevHt !== currentHt) {
      if (currentWt && currentHt) {
        const heightMeter = currentHt * 0.01;
        const bmi = currentWt / (heightMeter * heightMeter);
        this.setState({ general_anthropometry_bmi_result: bmi.toFixed(1) });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      const isValid = !!this.state.title;
      this.setState({ isFormValid: isValid });
    }
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.setState({ submitted: true });
    }
    const pageAction = this.props.location.state?.pageAction;
    if (pageAction === "update" || pageAction === "copy") {
      const url =
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") +
        `/api/${pageAction === "copy" ? "visitc" : "visit"}/${
          this.props.location.state?.id
        }`;
      axios
        .get(url)
        .then((res) => {
          this.setState(res.data);
          this.obj = res.data;
        })
        .catch((err) => console.error(err));
    } else {
      axios
        .get(
          (process.env.NODE_ENV === "development"
            ? "http://localhost:40000"
            : "") + `/api/patient/${this.state.patient_id}`,
        )
        .then((res) => {
          this.setState({
            general_anthropometry_wt_result: res.data?.weight,
            general_anthropometry_ht_result: res.data?.height,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    axios
      .get(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") + `/api/visits/${this.state.patient_id}`,
      )
      .then((res) => {
        this.setState({ allVisits: res.data });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  // Comp did mount comment out for development

  render() {
    const { classes } = this.props;

    if (this.state.submitted) {
      // if (
      //   this.props.location.state?.pageAction === "update" ||
      //   this.props.location.state?.pageAction === "copy"
      // ) {
      return (
        <Redirect
          to={{
            pathname: "/vinfo",
            state: { id: this.state.patient_id },
          }}
        />
      );
      // }
      // return <Redirect to="/patientList" />;
    }

    return (
      <div className="Patient_Create">
        <div>
          <NavBar />
        </div>
        <StatTableModal
          open={this.state.modalOpen}
          handleClose={this.handleModalClose}
          rows={this.state.modalRows}
          columns={this.state.modalColumns}
        />
        <div className="layout">
          <div className="containesContent">
            <div className="patientAddFormHasSearch">
              <SearchAppBar />
            </div>
            <div className="patientAddFormContained">
              <div className="patientFormHoldsbasicInfo">
                <div className={classes.ContainsComponents}>
                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        1.Basic Information
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Basic
                        data={this.state}
                        handleChange={this.handleChange1}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        2.Chief Complaints
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ChiefComplaints
                        data={this.state}
                        handleChange={this.handleChange1}
                        handleCheck={this.handleCheck}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        3.History of Present Illness
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <HistoryofIllness
                        data={this.state}
                        handleChange={this.handleIllnessChange}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        4.Past Medical History
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <PastHistory
                        data={this.state}
                        handleChange={this.handleMedicalHistoryChange}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        5.Drug History
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <DrugHistory
                        data={this.state}
                        handleChange={this.handleDrugHistoryChange}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        6.Family History
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <FamilyHistory
                        data={this.state}
                        handleChange={this.handleFamilyHistoryChange}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        7.General Exam
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GeneralExam
                        data={this.state}
                        handleChange={this.handleChange1}
                        handleCheck={this.handleCheck}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        8.Systemic examination
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansion}>
                      <Genituonary
                        data={this.state}
                        handleChange={this.handleChange1}
                      />
                      <CVS
                        data={this.state}
                        handleChange={this.handleChange1}
                      />
                      <Chest
                        data={this.state}
                        handleChange={this.handleChange1}
                      />
                      <ABDOMEN
                        data={this.state}
                        handleChange={this.handleChange1}
                      />
                      <CNS
                        data={this.state}
                        handleChange={this.handleChange1}
                      />
                      <Musculoskeletal
                        data={this.state}
                        handleChange={this.handleChange1}
                      />
                      <Analysis
                        data={this.state}
                        handleChange={this.handleChange1}
                        handlePopoverClick={this.handleModalClick}
                        handleSetModalStateChange={
                          this.handleSetModalStateChange
                        }
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        9.Summary
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <TextField
                        className={classes.textField}
                        label="Summary"
                        fullWidth
                        multiline
                        margin="normal"
                        name="summary"
                        value={this.state.summary}
                        onChange={this.handleChange1}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        10.Diagnosis
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <TextField
                        className={classes.textField}
                        label="Diagnosis"
                        fullWidth
                        multiline
                        margin="normal"
                        value={this.state.diagnosis}
                        name="diagnosis"
                        onChange={this.handleChange1}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        11.Treatment
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <TextField
                        className={classes.textField}
                        label="Treatment"
                        fullWidth
                        multiline
                        margin="normal"
                        name="treatment"
                        value={this.state.treatment}
                        onChange={this.handleChange1}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        12.Referred to
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <TextField
                        className={classes.textField}
                        label="Referred to"
                        fullWidth
                        multiline
                        margin="normal"
                        name="referredto"
                        value={this.state.referredto}
                        onChange={this.handleChange1}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        13.Advice for investigation
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <TextField
                        className={classes.textField}
                        label="Advice for investigation"
                        fullWidth
                        multiline
                        margin="normal"
                        name="adviceforinvestigation"
                        value={this.state.adviceforinvestigation}
                        onChange={this.handleChange1}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={classes.accord}
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        14.Action
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Outcome</FormLabel>
                        <RadioGroup
                          aria-label="position"
                          name="outcome"
                          label="outcome"
                          value={this.state.outcome}
                          //his.state.Abdomen_scar_marks_result}
                          onChange={this.handleOutcomeChange}
                          row
                        >
                          <FormControlLabel
                            value={"Admitted"}
                            control={<Radio color="secondary" />}
                            label="Admission"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Treatment"}
                            control={<Radio color="secondary" />}
                            label="Treatment"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Follow-up"}
                            control={<Radio color="secondary" />}
                            label="Follow Up"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Referred"}
                            control={<Radio color="secondary" />}
                            label="Referred"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Discharge-with-advice"}
                            control={<Radio color="secondary" />}
                            label="Discharge with advice"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Discharge-on-request"}
                            control={<Radio color="secondary" />}
                            label="Discharge on request"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Discharge-on-risk bond"}
                            control={<Radio color="secondary" />}
                            label="Discharge on risk bond"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Referred-to-other-hospitals"}
                            control={<Radio color="secondary" />}
                            label="Referred to other hospitals"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value={"Death"}
                            control={<Radio color="secondary" />}
                            label="Death"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* ///state: this.state.currentModalData._id */}
                <br />
                <div className="patientFormHoldsButtonsss">
                  <Link
                    to={{ pathname: "/patientList", state: "true" }}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.button}
                    >
                      back
                    </Button>
                  </Link>
                  <Button
                    disabled={!this.state.isFormValid}
                    variant="contained"
                    size="large"
                    className={classes.submitbutton}
                    onClick={this.handleSubmit}
                    type="submit"
                    name="submit"
                  >
                    <span className="white">Submit</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(Visit));
