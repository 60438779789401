import React, { Component } from "react";
import SearchAppBar from "../layout/Appbar";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import NavBar from "../layout/Navbar";
import VisitInfo from "./VisitInfo";

const style = () => ({
  linked: {
    textDecoration: "none",
    color: "black",
  },
  leftDashboardPanel: {
    width: "65%",
  },
  rightDashboardPanel: {
    width: "30%",
  },
  dashboardConatiner: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  },
});

class SelectVInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state?.id,
    };
  }

  render() {
    return (
      <div className="App">
        <div>
          <NavBar />
        </div>
        <div className="Content">
          <div className="Dashboard">
            <div>
              <SearchAppBar />
            </div>
            <div style={{ margin: 8 }}>
              <VisitInfo
                patientId={this.state.id}
                defaultTabIdx={
                  this.props.location.prevPath === "/editPatient" ? 1 : 0
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(SelectVInfo));
