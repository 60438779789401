import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const style = () => ({
  tableContainer: {},
  cell: {
    width: "50%",
  },
});

const StatsTable = (props) => {
  const { classes, data } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = React.useState("count");
  const [order, setOrder] = React.useState("desc");

  const handleChangePage = (_, page) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleRowClick = (path, diagnosis, selectedTab) => {
    props.history.push({
      pathname: path,
      state: { diagnosis, typeOfEnrollment: selectedTab, startDate: props?.startDate, endDate: props?.endDate },
      prevPath: `/stats`,
    });
  };
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => {
      el.diagnosis = el.diagnosis.replace(/\r?\n|\r/g, "").replace(/^\s+|\s+$|\s+(?=\s)/g, "");
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    const sortedArray = stabilizedThis.map((el) => el[0]);
    return sortedArray;
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => {
          return descendingComparator(a, b, orderBy);
        }
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const descendingComparator = (a, b, orderBy) => {
    if (orderBy !== "count") {
      function iterate(obj) {
        const shallowObj = { ...obj };
        for (let key in shallowObj) {
          if (shallowObj[key] !== null && typeof shallowObj[key] === "object") {
            iterate(shallowObj[key]);
          } else if (shallowObj[key] !== null && typeof shallowObj[key] === "string") {
            shallowObj[key] = shallowObj[key].toUpperCase();
          }
        }
        return shallowObj; //Returning copy of uppercase values to ignore case Sensitivity when sorting.
      }

      var nameA = iterate(a).diagnosis; // ignore upper and lowercase
      var nameB = iterate(b).diagnosis; // ignore upper and lowercase

      // ALPHANUMERIC SORTING WITH NO CASE SENSITIVITY
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    // NUMBER OF DIAGNOSIS COUNT BASED SORTING
    else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }

    return 0;
  };

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <TableSortLabel
                onClick={() => handleRequestSort("diagnosis")}
                active={orderBy === "diagnosis"}
                direction={orderBy === "diagnosis" ? order : "asc"}
              >
                Diagnosis
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel onClick={() => handleRequestSort("count")} active={orderBy === "count"} direction={orderBy === "count" ? order : "asc"}>
                Patient Count
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <TableRow style={{ cursor: "pointer" }} onClick={() => handleRowClick("/patientList", row.diagnosis, row?.category)} hover key={row.id}>
                <TableCell align="left" className={classes.cell}>
                  {row.diagnosis}
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  {row.count}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={8}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};
export default withRouter(withStyles(style)(StatsTable));
