import React from "react";

const StatsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.596"
      height="16.899"
      viewBox="0 0 18.596 16.899"
    >
      <path
        id="Path_1880"
        data-name="Path 1880"
        d="M14.3,31.081a.8.8,0,0,0,1.113-.205L18.438,26.5l3.2,3.054a.8.8,0,0,0,.635.217.815.815,0,0,0,.58-.333l5.309-7.531.192,2.519a.8.8,0,0,0,1.595-.12l-.337-4.43a.794.794,0,0,0-.858-.729l-4.478.333a.791.791,0,1,0,.121,1.578l2.547-.19-4.866,6.9-3.209-3.062a.788.788,0,0,0-.635-.217.8.8,0,0,0-.58.341L14.092,29.98a.786.786,0,0,0,.208,1.1Z"
        transform="translate(-11.583 -17.287)"
        fill="#616161"
      />
      <path
        id="Path_1881"
        data-name="Path 1881"
        d="M-3514.462,9125.917v15.4h17.1"
        transform="translate(3515.212 -9125.167)"
        fill="none"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default StatsIcon;
