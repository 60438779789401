import React, { Component } from "react";
import { TextField, Select, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import { updateOnProps } from "../../helper/utilities";
import MaskedInput from "react-text-mask";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(2),
    width: 250,
  },
  textField2: {
    margin: theme.spacing(2),
    width: 400,
  },
  textField3: {
    width: 250,
    marginLeft: theme.spacing(1),
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  spacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  spacing2: {
    display: "flex",
    flexWrap: "wrap",
  },
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
  },
  maskedBP: {
    border: "none",
    borderBottom: "1px solid black",
    marginTop: "45px",
    "&:focus": {
      outline: "none",
    },
  },
});

const maskArray = [/[1-9]/, /\d/, /\d/, " ", "/", " ", /\d/, /\d/, /\d/];
class GeneralExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      general_appearance_specify: this.props.data.general_appearance_specify,
      general_anemia_result: this.props.data.general_anemia_result,
      general_anemia_specify: this.props.data.general_anemia_specify,
      general_jaundice_reslut: this.props.data.general_jaundice_reslut,
      general_jaundice_specify: this.props.data.general_jaundice_specify,
      general_cyanosis_result: this.props.data.general_cyanosis_result,
      general_cyanosis_specify: this.props.data.general_cyanosis_specify,
      general_clubbing_result: this.props.data.general_clubbing_result,
      general_clubbing_specify: this.props.data.general_clubbing_specify,
      general_koilonychia_result: this.props.data.general_koilonychia_result,
      general_koilonychia_specify: this.props.data.general_koilonychia_specify,
      general_leuconychia_result: this.props.data.general_leuconychia_result,
      general_leuconychia_specify: this.props.data.general_leuconychia_specify,
      general_edema_result: this.props.data.general_edema_result,
      general_dehydration_result: this.props.data.general_dehydration_result,
      generalSkinchange: this.props.data.generalSkinchange,
      general_bcg_mark_result: this.props.data.general_bcg_mark_result,
      Signofmeningialirritation: this.props.data.Signofmeningialirritation,
      neckrigidity: this.props.data.neckrigidity,
      kernigsSign: this.props.data.kernigsSign,
      brudzinskiSign: this.props.data.brudzinskiSign,
      general_jvp_specify: this.props.data.general_jvp_specify,
      generalStatusRaised: this.props.data.generalStatusRaised,
      generalStatusNormal: this.props.data.generalStatusNormal,
      generalRaisedbymeasurement: this.props.data.generalRaisedbymeasurement,
      generalBackandSpine: this.props.data.generalBackandSpine,
      general_lymph_node_specify: this.props.data.general_lymph_node_specify,
      general_bonyTenderness: this.props.data.general_bonyTenderness,
      general_bonyDeformity: this.props.data.general_bonyDeformity,
      VitalSigns: this.props.data.VitalSigns,
      generalPulse: this.props.data.generalPulse,
      general_bp_specify: this.props.data.general_bp_specify,
      general_r_r_specify: this.props.data.general_r_r_specify,
      general_temperature_specify: this.props.data.general_temperature_specify,
      generalEye: this.props.data.generalEye,
      generalENT: this.props.data.generalENT,
      ThroatExamResult: this.props.data.ThroatExamResult,
      ThroatExamSpecify: this.props.data.ThroatExamSpecify,
      bsua: this.props.data.bsua,
      urineColor: this.props.data.urineColor,
      general_dedema_result: this.props.data.general_dedema_result,
      general_dedema_specify: this.props.data.general_dedema_specify,
      general_anthropometry_wt_result:
        this.props.data.general_anthropometry_wt_result,
      general_anthropometry_waz_result:
        this.props.data.general_anthropometry_waz_result,
      general_anthropometry_ht_result:
        this.props.data.general_anthropometry_ht_result,
      general_anthropometry_haz_result:
        this.props.data.general_anthropometry_haz_result,
      general_anthropometry_ofc_result:
        this.props.data.general_anthropometry_ofc_result,
      general_anthropometry_muac_result:
        this.props.data.general_anthropometry_muac_result,
      general_anthropometry_bmi_result:
        this.props.data.general_anthropometry_bmi_result,
      generalBodySurfaceArea: this.props.data.generalBodySurfaceArea,
      waistCircumference: this.props.data.waistCircumference,
      general_bonyChange: this.props.data.general_bonyChange,
      general_swelling_result: this.props.data.general_swelling_result,
      general_swelling_specify: this.props.data.general_swelling_specify,
      general_abdominal_girth_result:
        this.props.data.general_abdominal_girth_result,
      general_abdominal_girth_specify:
        this.props.data.general_abdominal_girth_specify,
      general_gait_result: this.props.data.general_gait_result,
      general_gait_specify: this.props.data.general_gait_specify,
      general_hr_result: this.props.data.general_hr_result,
      general_hr_specify: this.props.data.general_hr_specify,
      general_side_effect_of_drug_result:
        this.props.data.general_side_effect_of_drug_result,
      general_side_effect_of_drug_specify:
        this.props.data.general_side_effect_of_drug_specify,
      skinChange: this.props.data.skinChange,
      crt: this.props.data.crt,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "general_appearance_specify",
        "general_anemia_result",
        "general_anemia_specify",
        "general_jaundice_reslut",
        "general_jaundice_specify",
        "general_cyanosis_result",
        "general_cyanosis_specify",
        "general_clubbing_result",
        "general_clubbing_specify",
        "general_koilonychia_result",
        "general_koilonychia_specify",
        "general_leuconychia_result",
        "general_leuconychia_specify",
        "general_edema_result",
        "general_dehydration_result",
        "generalSkinchange",
        "general_bcg_mark_result",
        "Signofmeningialirritation",
        "neckrigidity",
        "kernigsSign",
        "brudzinskiSign",
        "general_jvp_specify",
        "generalStatusRaised",
        "generalStatusNormal",
        "generalRaisedbymeasurement",
        "generalBackandSpine",
        "general_lymph_node_specify",
        "general_bonyTenderness",
        "general_bonyDeformity",
        "VitalSigns",
        "generalPulse",
        "general_bp_specify",
        "general_r_r_specify",
        "general_temperature_specify",
        "generalEye",
        "generalENT",
        "ThroatExamResult",
        "ThroatExamSpecify",
        "bsua",
        "urineColor",
        "general_dedema_result",
        "general_dedema_specify",
        "general_anthropometry_wt_result",
        "general_anthropometry_waz_result",
        "general_anthropometry_ht_result",
        "general_anthropometry_haz_result",
        "general_anthropometry_ofc_result",
        "general_anthropometry_muac_result",
        "general_anthropometry_bmi_result",
        "generalBodySurfaceArea",
        "waistCircumference",
        "general_bonyChange",
        "general_swelling_result",
        "general_swelling_specify",
        "general_abdominal_girth_result",
        "general_abdominal_girth_specify",
        "general_gait_result",
        "general_gait_specify",
        "general_hr_result",
        "general_hr_specify",
        "general_side_effect_of_drug_result",
        "general_side_effect_of_drug_specify",
        "skinChange",
        "crt",
      ],
      this,
    );
  }

  handleChange(e) {
    this.props.handleChange(e);
  }

  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheck(e) {
    this.props.handleCheck(e);
  }
  handleSubmit(evt) {
    evt.preventDefault();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div style={{ margin: "16px", display: "flex", flexWrap: "wrap" }}>
        <div className={classes.divStyle}>
          <h2>7.1 General Examination</h2>
        </div>
        <React.Fragment>
          <TextField
            label="Appearance"
            value={this.state.general_appearance_specify}
            onChange={this.handlechange}
            onBlur={this.handleChange}
            margin="normal"
            name="general_appearance_specify"
            fullWidth
          />
        </React.Fragment>
        <div style={{ margin: "16px" }}></div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Anemia</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_anemia_result"
              label="general_anemia_result"
              value={this.state.general_anemia_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_anemia_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_anemia_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">Jaundice</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_jaundice_reslut"
              label="general_jaundice_reslut"
              value={this.state.general_jaundice_reslut}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_jaundice_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_jaundice_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">Cyanosis</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_cyanosis_result"
              label="general_cyanosis_result"
              value={this.state.general_cyanosis_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_cyanosis_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_cyanosis_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">Clubbing</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_clubbing_result"
              label="general_clubbing_result"
              value={this.state.general_clubbing_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_clubbing_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_clubbing_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Koilonychia</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_koilonychia_result"
              label="general_koilonychia_result"
              value={this.state.general_koilonychia_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_koilonychia_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_koilonychia_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Leukonychia</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_leuconychia_result"
              label="Leuchonychia"
              value={this.state.general_leuconychia_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_leuconychia_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_leuconychia_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          {/* <FormControl component="fieldset">
            <FormLabel component="legend">Leuchonychia</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_leuconychia_result"
              label="general_leuconychia_result"
              value={this.state.general_leuconychia_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_leuconychia_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_leuconychia_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl> */}

          <FormControl component="fieldset">
            <FormLabel component="legend">Edema</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_edema_result"
              label="general_edema_result"
              value={this.state.general_edema_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />
              <div style={{ visibility: "hidden" }}>
                <TextField
                  label="specify"
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  className={classes.textField}
                />
              </div>
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">Dehydration</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_dehydration_result"
              label="general_dehydration_result"
              value={this.state.general_dehydration_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"no sign"}
                control={<Radio color="secondary" />}
                label="No sign"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"some sign"}
                control={<Radio color="secondary" />}
                label="Some sign"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"severe"}
                control={<Radio color="secondary" />}
                label="Severe"
                labelPlacement="end"
              />
              <div style={{ visibility: "hidden", width: 165 }}>
                <TextField
                  label="specify"
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  //className={classes.textField}
                />
              </div>
            </RadioGroup>
          </FormControl>
          {/*New  */}

          <FormControl component="fieldset">
            <FormLabel component="legend">CRT</FormLabel>
            <RadioGroup
              aria-label="position"
              name="crt"
              label="general_dehydration_result"
              value={this.state.crt}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"less than 3s"}
                control={<Radio color="secondary" />}
                label="Less than 3s"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"more than 3s"}
                control={<Radio color="secondary" />}
                label="More than 3s"
                labelPlacement="end"
              />
              <div style={{ visibility: "hidden", width: 200 }}>
                <TextField
                  label="specify"
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  //className={classes.textField}
                />
              </div>
            </RadioGroup>
          </FormControl>

          {/*  */}
          <React.Fragment>
            <TextField
              label="Skin Change"
              value={this.state.generalSkinchange}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="generalSkinchange"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <FormControl component="fieldset" style={{ marginTop: "16px" }}>
            <FormLabel component="legend">BCG MARK</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_bcg_mark_result"
              label="general_bcg_mark_result"
              value={this.state.general_bcg_mark_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
          <div style={{ display: "flex", width: "80%" }}>
            <FormControl component="fieldset" className={classes.textField}>
              <FormLabel component="legend">
                Sign of meningeal irritation
              </FormLabel>
              <FormGroup aria-label="position" name="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.Signofmeningialirritation}
                      onChange={this.handleCheck}
                      value={this.state.Signofmeningialirritation}
                      name="Signofmeningialirritation"
                    />
                  }
                  label="Present"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>

            {this.state.Signofmeningialirritation ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "16px",
                }}
              >
                <FormControl component="fieldset" className={classes.textField}>
                  <FormGroup aria-label="position" name="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.neckrigidity}
                          onChange={this.handleCheck}
                          value={this.state.neckrigidity}
                          name="neckrigidity"
                        />
                      }
                      label="Neck Rigidity"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.textField}>
                  <FormGroup aria-label="position" name="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.kernigsSign}
                          onChange={this.handleCheck}
                          value={this.state.kernigsSign}
                          name="kernigsSign"
                        />
                      }
                      label="kernig's Sign"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.textField}>
                  <FormGroup aria-label="position" name="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.brudzinskiSign}
                          onChange={this.handleCheck}
                          value={this.state.brudzinskiSign}
                          name="brudzinskiSign"
                        />
                      }
                      label="brudzinski Sign"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            ) : (
              <div
                style={{
                  margin: "16px",
                }}
              ></div>
            )}
          </div>
          <React.Fragment>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="bsua">Bedside urine for albumin</InputLabel>
              <Select
                value={this.state.bsua}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                name="bsua"
                fullWidth
              >
                <MenuItem value={"nil"}>NIL</MenuItem>
                <MenuItem value={"trace"}>trace</MenuItem>
                <MenuItem value={"+"}>+</MenuItem>
                <MenuItem value={"++"}>++</MenuItem>
                <MenuItem value={"+++"}>+++</MenuItem>
                <MenuItem value={"++++"}>++++</MenuItem>
                <MenuItem value={"Not done"}>Not done</MenuItem>
              </Select>
              <FormHelperText>Please trace albumin for urine</FormHelperText>
            </FormControl>
          </React.Fragment>

          <React.Fragment>
            <TextField
              style={{ margin: "16px 0px" }}
              label="Urine Color"
              value={this.state.urineColor}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              name="urineColor"
              fullWidth
            />
          </React.Fragment>
          <React.Fragment>
            <div className={classes.divStyle}>
              <h2>7.2 JVP</h2>
            </div>

            {/* <TextField
              label="JVP Informations"
              value={this.state.general_jvp_specify}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="general_jvp_specify"
              className={classes.textfieldfull}
              fullWidth
            /> */}
          </React.Fragment>
          <FormControl component="fieldset" style={{ marginTop: 24 }}>
            {/* <FormLabel component="legend">
              <h3>6.2.1 Status</h3>
            </FormLabel> */}
            <RadioGroup
              aria-label="position"
              name="generalStatusRaised"
              label="generalStatusRaised"
              value={this.state.generalStatusRaised}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"Normal"}
                control={<Radio color="secondary" />}
                label="Normal"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"Raised"}
                control={<Radio color="secondary" />}
                label="Raised"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>

          {this.state.generalStatusRaised === "Raised" ? (
            <FormControl
              component="fieldset"
              style={{
                //margin: "32px 0px 0px",
                marginTop: 16,
                width: "80%",
              }}
            >
              <TextField
                label="Raised by measurement"
                value={this.state.generalRaisedbymeasurement}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                //margin="normal"
                name="generalRaisedbymeasurement"
                //className={classes.textfieldfull}
                fullWidth
              />
            </FormControl>
          ) : (
            <div></div>
          )}
          <React.Fragment>
            <TextField
              label="Back and Spine"
              value={this.state.generalBackandSpine}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="generalBackandSpine"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <React.Fragment>
            <TextField
              label="Lymph Nodes"
              value={this.state.general_lymph_node_specify}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="general_lymph_node_specify"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <React.Fragment>
            <TextField
              label="Bony Tenderness"
              value={this.state.general_bonyTenderness}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="general_bonyTenderness"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <React.Fragment>
            <TextField
              label="Bony Deformity"
              value={this.state.general_bonyDeformity}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="general_bonyDeformity"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <React.Fragment>
            <TextField
              label="Eye"
              value={this.state.generalEye}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="generalEye"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <div className={classes.divStyle}>
            <h2>7.3 Vital Signs</h2>
          </div>
          <React.Fragment>
            <TextField
              label="Vital Signs Information"
              value={this.state.VitalSigns}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="VitalSigns"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <FormControl component="fieldset">
              <div style={{ display: "flex", alignItems: "end" }}>
                <TextField
                  label="Pulse"
                  value={this.state.generalPulse}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="generalPulse"
                  //className={classes.textfieldfull}
                  //fullWidth
                  style={{ width: "250px" }}
                />
                <span style={{ marginBottom: "8px" }}>b/min</span>
              </div>
            </FormControl>
            <FormControl component="fieldset" style={{ margin: "0px 16px" }}>
              <div style={{ display: "flex", alignItems: "end" }}>
                <TextField
                  label="BP"
                  value={this.state.general_bp_specify}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="general_bp_specify"
                  //className={classes.textfieldfull}
                  //fullWidth
                  style={{ width: "250px" }}
                />
                {/* <MaskedInput
                  className={classes.maskedBP}
                  placeholder="BP"
                  mask={maskArray}
                  value={this.state.general_bp_specify}
                  name="general_bp_specify"
                  onChange={this.handleChange}
                /> */}
                <span>mm of Hg</span>
              </div>
            </FormControl>
            <FormControl component="fieldset" style={{ margin: "0px 16px" }}>
              <div style={{ display: "flex", alignItems: "end" }}>
                <TextField
                  label="Respiratory rate"
                  value={this.state.general_r_r_specify}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="general_r_r_specify"
                  //className={classes.textfieldfull}
                  //fullWidth
                  style={{ width: "250px" }}
                />
                <span style={{ marginBottom: "8px" }}>breathe/min</span>
              </div>
            </FormControl>
            <FormControl component="fieldset" style={{ margin: "0px 16px" }}>
              <div style={{ display: "flex", alignItems: "end" }}>
                <TextField
                  label="Temperature"
                  value={this.state.general_temperature_specify}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="general_temperature_specify"
                  //className={classes.textfieldfull}
                  //fullWidth
                  style={{ width: "250px" }}
                />
                <span style={{ marginBottom: "8px" }}>&#8457;</span>
              </div>
            </FormControl>
          </div>
          {/* <React.Fragment>
            <TextField
              label="Eye"
              value={this.state.generalEye}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="generalEye"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment> */}
          <div className={classes.divStyle}>
            <h2>7.4 ENT</h2>
          </div>
          <React.Fragment>
            <TextField
              label="ENT Information"
              value={this.state.generalENT}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="generalENT"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment>
          <FormControl component="fieldset" style={{ margin: "16px" }}>
            <FormLabel component="legend">
              <h3>7.4.1 Throat</h3>
            </FormLabel>
            <RadioGroup
              aria-label="position"
              name="ThroatExamResult"
              label="ThroatExamResult"
              value={this.state.ThroatExamResult}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"congested"}
                control={<Radio color="secondary" />}
                label="Congested"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"not congested"}
                control={<Radio color="secondary" />}
                label="Not congested"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.ThroatExamSpecify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="ThroatExamSpecify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <div>
            <div className={classes.divStyle}>
              <h2>7.5 Anthropometry</h2>
            </div>
            <FormControl component="fieldset">
              <div className={classes.genituorinaryClass}>
                <TextField
                  label="Wt"
                  value={this.props.data.general_anthropometry_wt_result}
                  onChange={this.handleChange}
                  // onBlur={this.handleChange}
                  margin="normal"
                  name="general_anthropometry_wt_result"
                  className={classes.textField}
                />

                <TextField
                  label="Waz"
                  value={this.state.general_anthropometry_waz_result}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="general_anthropometry_waz_result"
                  className={classes.textField}
                />

                <TextField
                  label="Ht"
                  value={this.props.data.general_anthropometry_ht_result}
                  onChange={this.handleChange}
                  // onBlur={this.handleChange}
                  margin="normal"
                  name="general_anthropometry_ht_result"
                  className={classes.textField}
                />

                <TextField
                  label="Haz"
                  value={this.state.general_anthropometry_haz_result}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="general_anthropometry_haz_result"
                  className={classes.textField}
                />

                <TextField
                  label="OFC<3 years"
                  value={this.state.general_anthropometry_ofc_result}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="general_anthropometry_ofc_result"
                  className={classes.textField}
                />

                <TextField
                  label="MUAC"
                  value={this.state.general_anthropometry_muac_result}
                  onChange={this.handlechange}
                  onBlur={this.handleChange}
                  margin="normal"
                  name="general_anthropometry_muac_result"
                  className={classes.textField}
                />

                <TextField
                  label="BMI"
                  value={this.props.data.general_anthropometry_bmi_result}
                  disabled
                  margin="normal"
                  name="general_anthropometry_bmi_result"
                  className={classes.textField}
                />
                <React.Fragment>
                  <TextField
                    label="Body Surface Area"
                    value={this.state.generalBodySurfaceArea}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin="normal"
                    name="generalBodySurfaceArea"
                    className={classes.textField}
                  />
                </React.Fragment>
                <React.Fragment>
                  <TextField
                    label="Waist circumference"
                    value={this.state.waistCircumference}
                    onChange={this.handlechange}
                    onBlur={this.handleChange}
                    margin="normal"
                    name="waistCircumference"
                    className={classes.textField}
                  />
                </React.Fragment>
              </div>
            </FormControl>
          </div>

          {/* <React.Fragment>
            <TextField
              label="Bony Change"
              value={this.state.general_bonyChange}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              margin="normal"
              name="general_bonyChange"
              className={classes.textfieldfull}
              fullWidth
            />
          </React.Fragment> */}

          {/* <div style={{ margin: "16px", width: "100%" }}></div>
          <FormControl component="fieldset">
            <FormLabel component="legend">Swelling</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_swelling_result"
              label="general_swelling_result"
              value={this.state.general_swelling_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_swelling_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_swelling_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Abdominal girth</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_abdominal_girth_result"
              label="general_abdominal_girth_result"
              value={this.state.general_abdominal_girth_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_abdominal_girth_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_abdominal_girth_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gait</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_gait_result"
              label="general_gait_result"
              value={this.state.general_gait_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_gait_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_gait_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">HR</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_hr_result"
              label="general_hr_result"
              value={this.state.general_hr_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_hr_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_hr_specify"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Any Side Effects of drugs</FormLabel>
            <RadioGroup
              aria-label="position"
              name="general_side_effect_of_drug_result"
              label="general_side_effect_of_drug_result"
              value={this.state.general_side_effect_of_drug_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="Present"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="Absent"
                labelPlacement="end"
              />

              <TextField
                label="specify"
                value={this.state.general_side_effect_of_drug_specify}
                onChange={this.handlechange}
                onBlur={this.handleChange}
                margin="normal"
                name="general_side_effect_of_drug_specify"
                className={classes.textField}
                multiline
                rowsMax="4"
              />
            </RadioGroup>
          </FormControl> */}
        </div>
      </div>
    );
  }
}

export default withStyles(style)(GeneralExam);
