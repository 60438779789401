import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import { updateOnProps } from "../../helper/utilities";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  formControlnext: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  spacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formControlNew: {
    margin: theme.spacing(2),
    width: "100%",
  },
  formControlAlt: {
    // margin: theme.spacing(2),
    width: "100%",
  },
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
    margin: theme.spacing(2),
  },
});

class ABDOMEN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Abdomen_Inspection: this.props.data.Abdomen_Inspection,
      gastro_any_distension: this.props.data.gastro_any_distension,
      gastro_overlappingSkin: this.props.data.gastro_overlappingSkin,
      gastro_engorgedVein: this.props.data.gastro_engorgedVein,
      gastro_scarMarks: this.props.data.gastro_scarMarks,
      gastro_pdCatheder: this.props.data.gastro_pdCatheder,

      Abdomen_Palpation: this.props.data.Abdomen_Palpation,
      gastro_tenderness: this.props.data.gastro_tenderness,
      gastro_rigidity: this.props.data.gastro_rigidity,
      gastro_liver: this.props.data.gastro_liver,
      gastro_spleen: this.props.data.gastro_spleen,
      gastro_urinaryBladder: this.props.data.gastro_urinaryBladder,
      gastro_kidney: this.props.data.gastro_kidney,
      gastro_Lumps: this.props.data.gastro_Lumps,

      Abdomen_Percussion: this.props.data.Abdomen_Percussion,
      gastro_Shiftingdullness: this.props.data.gastro_Shiftingdullness,
      gastro_FluidThrills: this.props.data.gastro_FluidThrills,

      Abdomen_Auscultation: this.props.data.Abdomen_Auscultation,
      gastro_bowelSound: this.props.data.gastro_bowelSound,

      oralcavity: this.props.data.oralcavity,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "Abdomen_Inspection",
        "gastro_any_distension",
        "gastro_overlappingSkin",
        "gastro_engorgedVein",
        "gastro_scarMarks",
        "gastro_pdCatheder",

        "Abdomen_Palpation",
        "gastro_tenderness",
        "gastro_rigidity",
        "gastro_liver",
        "gastro_spleen",
        "gastro_urinaryBladder",
        "gastro_kidney",
        "gastro_Lumps",

        "Abdomen_Percussion",
        "gastro_Shiftingdullness",
        "gastro_FluidThrills",

        "Abdomen_Auscultation",
        "gastro_bowelSound",

        "oralcavity",
      ],
      this,
    );
  }

  handleChange(e) {
    this.props.handleChange(e);
  }
  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.divStyle}>
          <h2>8.5 Gastrointestinal System :</h2>
        </div>

        <div style={{ margin: "24px" }}>
          <h3>8.5.1 Oral Cavity</h3>
        </div>

        <div style={{ margin: "32px" }}>
          <TextField
            label="Oral Cavity"
            value={this.state.oralcavity}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="oralcavity"
            fullWidth
          />
        </div>

        <div style={{ margin: "24px" }}>
          <h3>8.5.2 Abdomen</h3>
        </div>

        <div style={{ margin: "32px" }}>
          <h4 style={{ color: "#bdbdbd" }}>8.5.2.1 Inspection</h4>

          <TextField
            value={this.state.Abdomen_Inspection}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Abdomen_Inspection"
            fullWidth
          />

          <TextField
            label="Any Distension"
            value={this.state.gastro_any_distension}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_any_distension"
            fullWidth
          />
          <TextField
            label="Overlaying Skin"
            value={this.state.gastro_overlappingSkin}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_overlappingSkin"
            fullWidth
          />
          <TextField
            label="Engorged Vein"
            value={this.state.gastro_engorgedVein}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_engorgedVein"
            fullWidth
          />
          <TextField
            label="Scar Marks"
            value={this.state.gastro_scarMarks}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_scarMarks"
            fullWidth
          />
          <TextField
            label="Pd Catheder"
            value={this.state.gastro_pdCatheder}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_pdCatheder"
            fullWidth
          />
          <br />
        </div>
        <div style={{ margin: "32px" }}>
          <h4 style={{ color: "#bdbdbd" }}>8.5.2.2 Palpation</h4>

          <TextField
            value={this.state.Abdomen_Palpation}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Abdomen_Palpation"
            fullWidth
          />

          <TextField
            label="Tenderness"
            value={this.state.gastro_tenderness}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_tenderness"
            fullWidth
          />
          <TextField
            label="Rigidity"
            value={this.state.gastro_rigidity}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_rigidity"
            fullWidth
          />
          <TextField
            label="Liver"
            value={this.state.gastro_liver}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_liver"
            fullWidth
          />
          <TextField
            label="Spleen"
            value={this.state.gastro_spleen}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_spleen"
            fullWidth
          />
          <TextField
            label="Urinary Bladder"
            value={this.state.gastro_urinaryBladder}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_urinaryBladder"
            fullWidth
          />
          <TextField
            label="Kidney"
            value={this.state.gastro_kidney}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_kidney"
            fullWidth
          />
          <TextField
            label="Lumps"
            value={this.state.gastro_Lumps}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_Lumps"
            fullWidth
          />

          <br />
        </div>
        <div style={{ margin: "32px" }}>
          <h4 style={{ color: "#bdbdbd" }}>8.5.2.3 Percussion</h4>
          <TextField
            value={this.state.Abdomen_Percussion}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Abdomen_Percussion"
            fullWidth
          />
          <TextField
            label="Shifting Dullness"
            value={this.state.gastro_Shiftingdullness}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_Shiftingdullness"
            fullWidth
          />
          <TextField
            label="Fluid Thrills"
            value={this.state.gastro_FluidThrills}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_FluidThrills"
            fullWidth
          />
          <br />
        </div>
        <div style={{ margin: "32px" }}>
          <h4 style={{ color: "#bdbdbd" }}>8.5.2.4 Auscultation</h4>

          <TextField
            value={this.state.Abdomen_Auscultation}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="Abdomen_Auscultation"
            fullWidth
          />

          <TextField
            label="Bowel Sounds"
            value={this.state.gastro_bowelSound}
            onBlur={this.handleChange}
            onChange={this.handlechange}
            margin="normal"
            name="gastro_bowelSound"
            fullWidth
          />
        </div>
      </div>
    );
  }
}

export default withStyles(style)(ABDOMEN);
