import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import NavBar from "../layout/Navbar";
import SearchAppBar from "../layout/Appbar";
import Loader from "../layout/Loader";
import { grey } from "@material-ui/core/colors";
import Filters, { filtersFormId } from "./Filters";
import FilterTabs, { ETabs } from "./FilterTabs";
import axios from "axios";
import StatsTable from "./StatsTable";

const style = (theme) => ({
  statsContainer: {
    marginLeft: 24,
    boxShadow: "0 2px 5px rgba(0,0,0,0.08)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    maxWidth: 1080,
  },
  appContainer: {
    display: "flex",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0 32px 0 32px",
    width: "100%",
    paddingBottom: 160,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    fontSize: "20px",
    fontWeight: "normal",
    color: grey[800],
    fontFamily: "Nunito Sans",
  },
  countContainer: {
    margin: "24px 32px",
  },
  subheading: {
    color: "rgb(66,66,66)",
    fontSize: 12,
    fontFamily: "Nunito Sans",
  },
  count: {
    color: "black",
    fontSize: 20,
    fontWeight: "500",
    fontFamily: "Nunito Sans",
  },
});

const currentDate = new Date();
let currentMonth = currentDate.getMonth() + 1;
if (currentMonth < 10) {
  currentMonth = "0" + currentMonth;
}
export const defaultStartDate = `${currentDate.getFullYear()}-${currentMonth}-01`;
export const defaultEndDate = new Date().toISOString().split("T")[0];
class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inPatients: null,
      outPatients: null,
      otherPatients: null,
      loading: true,
      selectedTab: Object.values(ETabs)[0],
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    };
  }
  loadStats(startDate, endDate) {
    this.setState({ loading: true });
    axios
      .get(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") + `/api/visitStats?startDate=${startDate}&&endDate=${endDate}`,
      )
      .then((res) => {
        const inPatients = res.data["in-patient"];
        const outPatients = res.data["out-patient"];
        const otherPatients = {
          count:
            (res.data[""] || { count: 0 }).count +
            (res.data["undefined"] || { count: 0 }).count,
          diagnosis: {
            ...(res.data[""] || { diagnosis: {} }).diagnosis,
            ...(res.data["undefined"] || { diagnosis: {} }).diagnosis,
          },
        };
        this.setState({
          inPatients,
          outPatients,
          otherPatients,
        });
      })
      .catch((err) => {
        console.error("visit stats err ", err);
      })
      .finally(() => this.setState({ loading: false }));
  }
  componentDidMount() {
    this.loadStats(defaultStartDate, defaultEndDate);
  }
  render() {
    const { classes } = this.props;

    const onSubmitFilters = (event) => {
      event.preventDefault();
      const startDate = event.target.elements.startDate.value;
      const endDate = event.target.elements.endDate.value;
      this.loadStats(startDate, endDate);
      this.setState({ startDate, endDate });
    };
    const clearFilters = () => {
      document.getElementById(filtersFormId).reset();
    };
    const changeTabHandler = (tab) => {
      this.setState({ selectedTab: tab });
    };

    let patientData;
    let patientCount;
    if (
      !this.state.loading &&
      (this.state.inPatients ||
        this.state.outPatients ||
        this.state.otherPatients)
    ) {
      switch (this.state.selectedTab) {
        case ETabs.indoor:
          patientData = this.state.inPatients;
          patientCount = this.state.inPatients.count;
          break;
        case ETabs.outdoor:
          patientData = this.state.outPatients;
          patientCount = this.state.outPatients.count;
          break;
        case ETabs.other:
          patientData = this.state.otherPatients;
          patientCount = this.state.otherPatients.count;
          break;
        default:
      }
    }
    const tableData =
      patientData &&
      Object.keys(patientData.diagnosis).map((key, index) => {
        return {
          diagnosis: key,
          count: patientData.diagnosis[key]?.count,
          id: index,
          category: patientData.location,
        };
      });
    return (
      <div className={classes.appContainer}>
        <NavBar />
        <div className={classes.contentContainer}>
          <div>
            <SearchAppBar title="Patient Statistics" />
          </div>
          {this.state.loading ? (
            <Loader />
          ) : (
            <div className={classes.statsContainer}>
              <Filters
                onSubmitFilters={onSubmitFilters}
                clearFilters={clearFilters}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
              <FilterTabs
                selectedTab={this.state.selectedTab}
                onChangeTab={changeTabHandler}
              />
              <div className={classes.countContainer}>
                <p className={classes.subheading}>Total Patients</p>
                <p className={classes.count}>{patientCount}</p>
              </div>
              <StatsTable
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                data={tableData}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(Stats));
