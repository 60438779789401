import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import '../patient/Create-patient.css';
import { lightGreen } from '@material-ui/core/colors';
import { updateOnProps } from '../../helper/utilities';

const style = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        background: 'white',
        padding: 32,
    },
    formControl: {
        margin: theme.spacing(1),
        width: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(4),
    },
    textField: {
        margin: theme.spacing(2),
    },
    addressTextField: {
        margin: theme.spacing(1),
        width: 250,
    },
    menu: {
        width: 150,
    },
    submitbutton: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText(lightGreen[500]),
        backgroundColor: lightGreen[500],
        '&:hover': {
            backgroundColor: lightGreen[600],
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    genituorinaryClass: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    marginRadio: {
        margin: theme.spacing(2),
    },
});

class PastHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pastmedhis: this.props.data.pastmedhis,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlechange = this.handlechange.bind(this);
    }

    componentDidUpdate(prevProps) {
        updateOnProps(prevProps, ['pastmedhis'], this);
    }

    handleChange(e) {
        this.props.handleChange(e);
    }
    handlechange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { classes } = this.props;

        return (
            <div style={{ width: '98%' }}>
                <TextField
                    className={classes.textField}
                    label='Past Medical History'
                    fullWidth
                    multiline
                    margin='normal'
                    name='pastmedhis'
                    value={this.state.pastmedhis}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                />
            </div>
        );
    }
}

export default withStyles(style)(PastHistory);
