import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import ViewPatient from '../patient/ViewPatient';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { withStyles } from '@material-ui/core/styles';
import VisitingInformations from './VisitingInformations';
import { Redirect } from 'react-router-dom';

const style = (theme) => ({
	root: {
		padding: theme.spacing(3, 2),
	},
});
class VisitInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.patientId,
			error: false,
		};
	}
	static getDerivedStateFromProps(props, state) {
		if (props.patientId === undefined) {
			this.setState({ error: true });
		}
		return state;
	}
	render() {
		const { classes } = this.props;

		if (this.state.error) {
			return <Redirect to='/home' />;
		}

		return (
			<div>
				<AppBar />
				<Paper className={classes.root}>
					<Tabs defaultIndex={this.props.defaultTabIdx}>
						<TabList>
							<Tab>Visit Info</Tab>
							<Tab>Patient Info</Tab>
						</TabList>
						<TabPanel>
							<div>
								<VisitingInformations patientId={this.state.id} />
							</div>
						</TabPanel>
						<TabPanel>
							<div>
								<ViewPatient patientId={this.state.id} />
							</div>
						</TabPanel>
					</Tabs>
				</Paper>
			</div>
		);
	}
}

export default withStyles(style)(VisitInfo);
