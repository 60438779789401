import React, { Component } from "react";
// import axios from 'axios';
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";

import { lightGreen } from "@material-ui/core/colors";
// import SearchAppBar from '../layout/Appbar';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import "../patient/Create-patient.css";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { updateOnProps } from "../../helper/utilities";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    // width: 250,
  },
  formControlNext: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: "100%",
  },
  textfieldUp: {
    margin: theme.spacing(1),
    width: 400,
  },
  textfieldUp2: {
    width: 400,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  spacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  hide: {
    visibility: "hidden",
    margin: theme.spacing(1),
    width: 395,
  },
  textField2: {
    margin: theme.spacing(1),
  },
  formControlNew: {
    margin: theme.spacing(2),
    width: "100%",
  },
  formControlAlt: {
    width: "100%",
  },
  bp: {
    display: "flex",
  },
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
    margin: theme.spacing(2),
  },
});

class CVS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      general_bp_specify: this.props.data.general_bp_specify || "",
      cvsPulse: this.props.data.cvsPulse || "",
      cvspulseRate: this.props.data.cvspulseRate || "",
      cvspulseRythm: this.props.data.cvspulseRythm || "",
      cvspulseVolume: this.props.data.cvspulseVolume || "",
      cvspulseCharacter: this.props.data.cvspulseCharacter || "",
      cvspulseConditionOfTheArterialWall:
        this.props.data.cvspulseConditionOfTheArterialWall || "",
      cvsBPSystolic: this.props.data.cvsBPSystolic || "",
      cvsBPDiastolic: this.props.data.cvsBPDiastolic || "",
      generalStatusRaised: this.props.data.generalStatusRaised || "",
      generalRaisedbymeasurement:
        this.props.data.generalRaisedbymeasurement || "",
      cvsPrecordiumInspection: this.props.data.cvsPrecordiumInspection || "",
      cvsPrecordiumInspectionShape:
        this.props.data.cvsPrecordiumInspectionShape || "",
      cvsPrecordiumInspectionVisibleImpluse:
        this.props.data.cvsPrecordiumInspectionVisibleImpluse || "",
      cvsPrecordiumInspectionVisibleImpluseSpecify:
        this.props.data.cvsPrecordiumInspectionVisibleImpluseSpecify || "",
      cvsPrecordiumInspectionScarMark:
        this.props.data.cvsPrecordiumInspectionScarMark || "",
      cvsPrecordiumInspectionScarMarkSpecify:
        this.props.data.cvsPrecordiumInspectionScarMarkSpecify || "",
      cvsPalpation: this.props.data.cvsPalpation || "",
      cvsPalpationApexBeatLocation:
        this.props.data.cvsPalpationApexBeatLocation || "",
      cvsPalpationApexBeatCharacter:
        this.props.data.cvsPalpationApexBeatCharacter || "",
      cvsPalpationApexBeatThrill:
        this.props.data.cvsPalpationApexBeatThrill || "",
      cvsPalpationApexBeatThrillSpecify:
        this.props.data.cvsPalpationApexBeatThrillSpecify || "",
      cvsAusculation: this.props.data.cvsAusculation || "",
      cvsAusculationHeartSound: this.props.data.cvsAusculationHeartSound || "",
      cvsAusculationHeartSoundMitralAreaMurmur:
        this.props.data.cvsAusculationHeartSoundMitralAreaMurmur || "",
      cvsAusculationHeartSoundMitralAreaMurmurSpecify:
        this.props.data.cvsAusculationHeartSoundMitralAreaMurmurSpecify || "",
      cvsAusculationHeartSoundTricuspidAreaMurmur:
        this.props.data.cvsAusculationHeartSoundTricuspidAreaMurmur || "",
      cvsAusculationHeartSoundTricuspidAreaMurmurSpecify:
        this.props.data.cvsAusculationHeartSoundTricuspidAreaMurmurSpecify ||
        "",
      cvsAusculationHeartSoundAorticAreaMurmur:
        this.props.data.cvsAusculationHeartSoundAorticAreaMurmur || "",
      cvsAusculationHeartSoundAorticAreaMurmurSpecify:
        this.props.data.cvsAusculationHeartSoundAorticAreaMurmurSpecify || "",
      cvsAusculationHeartSoundPulmonaryAreaMurmur:
        this.props.data.cvsAusculationHeartSoundPulmonaryAreaMurmur || "",
      cvsAusculationHeartSoundPulmonaryAreaMurmurSpecify:
        this.props.data.cvsAusculationHeartSoundPulmonaryAreaMurmurSpecify ||
        "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkIfBPIsProperlyAssigned =
      this.checkIfBPIsProperlyAssigned.bind(this);
  }
  checkIfBPIsProperlyAssigned() {
    if (this.state.general_bp_specify) {
      const BP = this.state.general_bp_specify.split("/");
      if (
        !isNaN(Number(BP[0])) &&
        typeof Number(BP[0] === "number") &&
        !isNaN(Number(BP[1])) &&
        BP[0] !== "" &&
        BP[1] !== "" &&
        typeof Number(BP[1] === "number")
      ) {
        return true;
      }
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "general_bp_specify",
        "cvsPulse",
        "cvspulseRate",
        "cvspulseRythm",
        "cvspulseVolume",
        "cvspulseCharacter",
        "cvspulseConditionOfTheArterialWall",
        "cvsBPSystolic",
        "cvsBPDiastolic",
        "generalStatusRaised",
        "generalRaisedbymeasurement",
        "cvsPrecordiumInspection",
        "cvsPrecordiumInspectionShape",
        "cvsPrecordiumInspectionVisibleImpluse",
        "cvsPrecordiumInspectionVisibleImpluseSpecify",
        "cvsPrecordiumInspectionScarMark",
        "cvsPrecordiumInspectionScarMarkSpecify",
        "cvsPalpation",
        "cvsPalpationApexBeatLocation",
        "cvsPalpationApexBeatCharacter",
        "cvsPalpationApexBeatThrill",
        "cvsPalpationApexBeatThrillSpecify",
        "cvsAusculation",
        "cvsAusculationHeartSound",
        "cvsAusculationHeartSoundMitralAreaMurmur",
        "cvsAusculationHeartSoundMitralAreaMurmurSpecify",
        "cvsAusculationHeartSoundTricuspidAreaMurmur",
        "cvsAusculationHeartSoundTricuspidAreaMurmurSpecify",
        "cvsAusculationHeartSoundAorticAreaMurmur",
        "cvsAusculationHeartSoundAorticAreaMurmurSpecify",
        "cvsAusculationHeartSoundPulmonaryAreaMurmur",
        "cvsAusculationHeartSoundPulmonaryAreaMurmurSpecify",
      ],
      this,
    );
  }

  handleChange(e) {
    // this.setState({[e.target.name]:e.target.value});
    this.props.handleChange(e);
  }

  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <React.Fragment>
          <div className={classes.divStyle}>
            <h2>8.2 CSV</h2>
          </div>
          <div style={{ margin: "16px" }}>
            <div style={{ margin: "16px" }}>
              <div>
                <h3>8.2.1 Pulse :</h3>
              </div>
              <div style={{ width: "100%" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControlAlt}
                >
                  <TextField
                    label="Pulse"
                    value={this.state.cvsPulse}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsPulse"
                    fullWidth
                  />
                </FormControl>
              </div>
              <div style={{ width: "100%" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControlAlt}
                >
                  <TextField
                    label="Pulse Rate"
                    value={this.state.cvspulseRate}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvspulseRate"
                    fullWidth
                  />
                </FormControl>
              </div>
              <FormControl required className={classes.formControlAlt}>
                <InputLabel htmlFor="title">Pulse Rhythm</InputLabel>
                <Select
                  onBlur={this.handleChange}
                  value={this.state.cvspulseRythm}
                  onChange={this.handlechange}
                  name="cvspulseRythm"
                  fullWidth
                >
                  <MenuItem value={"Regular"}>Regular</MenuItem>
                  <MenuItem value={"Irregular"}>Irregular</MenuItem>
                </Select>
              </FormControl>
              <FormControl required className={classes.formControlAlt}>
                <InputLabel htmlFor="title">Pulse Volume</InputLabel>
                <Select
                  onBlur={this.handleChange}
                  value={this.state.cvspulseVolume}
                  onChange={this.handlechange}
                  name="cvspulseVolume"
                  fullWidth
                >
                  <MenuItem value={"Normal"}>Normal</MenuItem>
                  <MenuItem value={"Low"}>Low</MenuItem>
                  <MenuItem value={"High"}>High</MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: "100%" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControlAlt}
                >
                  <TextField
                    label="Pulse Character"
                    value={this.state.cvspulseCharacter}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvspulseCharacter"
                    className={classes.textfieldfull}
                    fullWidth
                  />
                </FormControl>
              </div>
              <div style={{ width: "100%" }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControlAlt}
                >
                  <TextField
                    label="Condition Of the Arterial Wall"
                    value={this.state.cvspulseConditionOfTheArterialWall}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvspulseConditionOfTheArterialWall"
                    className={classes.textfieldfull}
                    fullWidth
                  />
                </FormControl>
              </div>
              <FormControl
                component="fieldset"
                className={classes.formControlAlt}
              >
                <div style={{ width: "100%" }} className={classes.bp}>
                  <TextField
                    label="BP Systolic"
                    value={
                      this.checkIfBPIsProperlyAssigned()
                        ? this.state.general_bp_specify.split("/")[0]
                        : this.state.cvsBPSystolic
                    }
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsBPSystolic"
                  />

                  <div>
                    <h1>/</h1>
                  </div>
                  <TextField
                    label="BP Diastolic"
                    value={
                      this.checkIfBPIsProperlyAssigned()
                        ? this.state.general_bp_specify.split("/")[1]
                        : this.state.cvsBPDiastolic
                    }
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsBPDiastolic"
                  />
                </div>
              </FormControl>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl
                  component="fieldset"
                  className={classes.textfieldUp2}
                  margin="normal"
                >
                  <InputLabel htmlFor="age-simple">JVP</InputLabel>
                  <Select
                    label="JVP"
                    onBlur={this.handleChange}
                    value={this.state.generalStatusRaised}
                    onChange={this.handlechange}
                    name="generalStatusRaised"
                  >
                    <MenuItem value={"Normal"}>Normal</MenuItem>
                    <MenuItem value={"Raised"}>Raised</MenuItem>
                  </Select>
                </FormControl>

                <Box
                  style={{ width: "50%" }}
                  display={
                    this.state.generalStatusRaised === "Raised" ? "" : "none"
                  }
                >
                  <TextField
                    label="Raised By Measurement"
                    value={this.state.generalRaisedbymeasurement}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="generalRaisedbymeasurement"
                    fullWidth
                    //className={classes.textfieldUp2}
                  />
                </Box>
              </div>
            </div>

            <div
              style={{
                color: "#757575",
                width: "100%",
                borderBottom: "2px dotted lightgrey",
              }}
            >
              <h2>8.3 Precordium</h2>
            </div>
            <div style={{ margin: "16px" }}>
              <h3>8.3.1 Inspection</h3>
              <TextField
                label="Shape"
                value={this.state.cvsPrecordiumInspectionShape}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="cvsPrecordiumInspectionShape"
                fullWidth
              />

              <div style={{ marginTop: "16px" }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Visible Impulse</FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="cvsPrecordiumInspectionVisibleImpluse"
                    label="cvsPrecordiumInspectionVisibleImpluse"
                    value={this.state.cvsPrecordiumInspectionVisibleImpluse}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"YES"}
                      control={<Radio color="secondary" />}
                      label="PRESENT"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NO"}
                      control={<Radio color="secondary" />}
                      label="ABSENT"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Scar Mark</FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="cvsPrecordiumInspectionScarMark"
                    label="cvsPrecordiumInspectionScarMark"
                    value={this.state.cvsPrecordiumInspectionScarMark}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    row
                  >
                    <FormControlLabel
                      value={"YES"}
                      control={<Radio color="secondary" />}
                      label="PRESENT"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={"NO"}
                      control={<Radio color="secondary" />}
                      label="ABSENT"
                      labelPlacement="end"
                    />

                    <TextField
                      label="Specify"
                      value={this.state.cvsPrecordiumInspectionScarMarkSpecify}
                      onBlur={this.handleChange}
                      onChange={this.handlechange}
                      margin="normal"
                      name="cvsPrecordiumInspectionScarMarkSpecify"
                      className={classes.textfieldUp}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div style={{ margin: "16px" }}>
              <h3>8.3.2 Palpation</h3>

              <TextField
                label="Apex Beat Location"
                value={this.state.cvsPalpationApexBeatLocation}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="cvsPalpationApexBeatLocation"
                fullWidth
              />

              <TextField
                label="Apex Beat Character"
                value={this.state.cvsPalpationApexBeatCharacter}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="cvsPalpationApexBeatCharacter"
                fullWidth
              />

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Thrill</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="cvsPalpationApexBeatThrill"
                  label="cvsPalpationApexBeatThrill"
                  value={this.state.cvsPalpationApexBeatThrill}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  row
                >
                  <FormControlLabel
                    value={"YES"}
                    control={<Radio color="secondary" />}
                    label="PRESENT"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"NO"}
                    control={<Radio color="secondary" />}
                    label="ABSENT"
                    labelPlacement="end"
                  />

                  <TextField
                    label="Specify"
                    value={this.state.cvsPalpationApexBeatThrillSpecify}
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsPalpationApexBeatThrillSpecify"
                    className={classes.textfieldUp}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div style={{ margin: "16px" }}>
              <h3>8.3.3 Auscultation</h3>
              <FormControl component="fieldset">
                <FormLabel component="legend">Heart Sound Mitral</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="cvsAusculationHeartSoundMitralAreaMurmur"
                  label="cvsAusculationHeartSoundMitralAreaMurmur"
                  value={this.state.cvsAusculationHeartSoundMitralAreaMurmur}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  row
                >
                  <FormControlLabel
                    value={"YES"}
                    control={<Radio color="secondary" />}
                    label="PRESENT"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"NO"}
                    control={<Radio color="secondary" />}
                    label="ABSENT"
                    labelPlacement="end"
                  />

                  <TextField
                    label="Specify"
                    value={
                      this.state.cvsAusculationHeartSoundMitralAreaMurmurSpecify
                    }
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsAusculationHeartSoundMitralAreaMurmurSpecify"
                    className={classes.textfieldUp}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Heart Sound Tricuspid</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="cvsAusculationHeartSoundTricuspidAreaMurmur"
                  label="cvsAusculationHeartSoundTricuspidAreaMurmur"
                  value={this.state.cvsAusculationHeartSoundTricuspidAreaMurmur}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  row
                >
                  <FormControlLabel
                    value={"YES"}
                    control={<Radio color="secondary" />}
                    label="PRESENT"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"NO"}
                    control={<Radio color="secondary" />}
                    label="ABSENT"
                    labelPlacement="end"
                  />

                  <TextField
                    label="Specify"
                    value={
                      this.state
                        .cvsAusculationHeartSoundTricuspidAreaMurmurSpecify
                    }
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsAusculationHeartSoundTricuspidAreaMurmurSpecify"
                    className={classes.textfieldUp}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Heart Sound Aortic</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="cvsAusculationHeartSoundAorticAreaMurmur"
                  label="cvsAusculationHeartSoundAorticAreaMurmur"
                  value={this.state.cvsAusculationHeartSoundAorticAreaMurmur}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  row
                >
                  <FormControlLabel
                    value={"YES"}
                    control={<Radio color="secondary" />}
                    label="PRESENT"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"NO"}
                    control={<Radio color="secondary" />}
                    label="ABSENT"
                    labelPlacement="end"
                  />

                  <TextField
                    label="Specify"
                    value={
                      this.state.cvsAusculationHeartSoundAorticAreaMurmurSpecify
                    }
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsAusculationHeartSoundAorticAreaMurmurSpecify"
                    className={classes.textfieldUp}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Heart Sound Pulmonary</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="cvsAusculationHeartSoundPulmonaryAreaMurmur"
                  label="cvsAusculationHeartSoundPulmonaryAreaMurmur"
                  value={this.state.cvsAusculationHeartSoundPulmonaryAreaMurmur}
                  onBlur={this.handleChange}
                  onChange={this.handlechange}
                  row
                >
                  <FormControlLabel
                    value={"YES"}
                    control={<Radio color="secondary" />}
                    label="PRESENT"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"NO"}
                    control={<Radio color="secondary" />}
                    label="ABSENT"
                    labelPlacement="end"
                  />

                  <TextField
                    label="Specify"
                    value={
                      this.state
                        .cvsAusculationHeartSoundPulmonaryAreaMurmurSpecify
                    }
                    onBlur={this.handleChange}
                    onChange={this.handlechange}
                    margin="normal"
                    name="cvsAusculationHeartSoundPulmonaryAreaMurmurSpecify"
                    className={classes.textfieldUp}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default withStyles(style)(CVS);
