export const Urine_Routine_Columns = [
  { field: "visitDate", headerName: "Visit Date", width: 140 },
  { field: "visitType", headerName: "Visit Type", width: 140 },
  { field: "urineProtein", headerName: "Protein", width: 125 },
  { field: "urinePuscul", headerName: "Puscul", width: 125 },
  {
    field: "urineRbc",
    headerName: "RBC",
    width: 105,
  },
  {
    field: "urinePh",
    headerName: "pH",
    width: 100,
  },
  {
    field: "urineSpecificGravity",
    headerName: "Specific Gravity",
    width: 180,
  },
  { field: "urineCSwithC_C", headerName: "Urine CS with C/C", width: 195 },
  {
    field: "urinarycalcium24hrs",
    headerName: "24 Hours Urinary Calcium",
    width: 250,
  },
  {
    field: "Analysis_spotPCR_DOA",
    headerName: "Spot PCR",
    width: 200,
  },
  {
    field: "Analysis_micro_albuminuria_DOA",
    headerName: "Micro Albuminuria",
    width: 200,
  },
  {
    field: "Analysis_UTP_DOA",
    headerName: "24hrs UTP",
    width: 200,
  },
  {
    field: "Urinary_Osmonality",
    headerName: "Urinary Osmonality",
    width: 200,
  },
  {
    field: "urineElectrolytes",
    headerName: "Urinary Electrolytes",
    width: 205,
  },
  {
    field: "spoturinaryratio",
    headerName: "Spot Urinary Calcium Creatinine Ratio",
    width: 320,
  },
  {
    field: "Analysis_24_Hours_Urinary_PO4",
    headerName: "24 Hours Urinary PO4",
    width: 220,
  },
  {
    field: "Analysis_24_Hours_Urinary_calcium",
    headerName: "24 Hour Urinary Calcium",
    width: 235,
  },
  {
    field: "Analysis_24_Hours_Urinary_Oxalate",
    headerName: "24 Hour Urinary Oxalate",
    width: 240,
  },
];
