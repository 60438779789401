import React, { Component } from "react";
import { TextField, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { updateOnProps } from "../../helper/utilities";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  genituorinaryClass: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  spacing: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  spacingNew: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: "32px", //16 initial
  },
  hide: {
    visibility: "hidden",
    margin: theme.spacing(1),
    width: 395,
  },
  formControlnew: {
    margin: theme.spacing(1),
    width: "100%",
  },
  divStyle: {
    width: "100%",
    borderBottom: "2px dotted lightgrey",
    color: "#757575",
    marginBottom: theme.spacing(2),
  },
});

class Genituonary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eyes: this.props.data.eyes,
      teeth: this.props.data.teeth,

      geni_Inspection: this.props.data.geni_Inspection,
      geni_Palpation_kidney: this.props.data.geni_Palpation_kidney,
      geni_Palpation_ub: this.props.data.geni_Palpation_ub,
      geni_Palpation_rt: this.props.data.geni_Palpation_rt,
      geni_Palpation_genitalia: this.props.data.geni_Palpation_genitalia,
      geni_Percussion: this.props.data.geni_Percussion,
      geni_Auscultation: this.props.data.geni_Auscultation,
      geni_renal_bruit: null,

      genituorinary_fluidThrill_result:
        this.props.data.genituorinary_fluidThrill_result,
      genituorinary_fluidThrill_desc:
        this.props.data.genituorinary_fluidThrill_desc,
      genituorinary_shiftingDullness_result:
        this.props.data.genituorinary_shiftingDullness_result,
      genituorinary_shiftingDullness_desc:
        this.props.data.genituorinary_shiftingDullness_desc,
      genituorinary_colorOfUrin_result:
        this.props.data.genituorinary_colorOfUrin_result,
      genituorinary_colorOfUrin_desc:
        this.props.data.genituorinary_colorOfUrin_desc,
      genituorinary_haematuria_result:
        this.props.data.genituorinary_haematuria_result,
      genituorinary_haematuria_desc:
        this.props.data.genituorinary_haematuria_desc,
      genituorinary_frequency_result:
        this.props.data.genituorinary_frequency_result,
      genituorinary_frequency_desc:
        this.props.data.genituorinary_frequency_desc,
      genituorinary_enuresis_result:
        this.props.data.genituorinary_enuresis_result,
      genituorinary_enuresis_desc: this.props.data.genituorinary_enuresis_desc,
      genituorinary_Dysuria_result:
        this.props.data.genituorinary_Dysuria_result,
      genituorinary_Dysuria_desc: this.props.data.genituorinary_Dysuria_desc,
      genituorinary_polyuria_result:
        this.props.data.genituorinary_polyuria_result,
      genituorinary_polyuria_desc: this.props.data.genituorinary_polyuria_desc,
      genituorinary_frothy_result: this.props.data.genituorinary_frothy_result,
      genituorinary_frothy_desc: this.props.data.genituorinary_frothy_desc,
      genituorinary_nocturia_result:
        this.props.data.genituorinary_nocturia_result,
      genituorinary_nocturia_desc: this.props.data.genituorinary_nocturia_desc,
      genituorinary_abnormal_Genitalia_result:
        this.props.data.genituorinary_abnormal_Genitalia_result,
      genituorinary_abnormal_Genitalia_desc:
        this.props.data.genituorinary_abnormal_Genitalia_desc,
      genituorinary_metal_Stenosis_result:
        this.props.data.genituorinary_metal_Stenosis_result,
      genituorinary_metal_Stenosis_desc:
        this.props.data.genituorinary_metal_Stenosis_desc,
      genituorinary_phimosis_result:
        this.props.data.genituorinary_phimosis_result,
      genituorinary_phimosis_desc: this.props.data.genituorinary_phimosis_desc,
      genituorinary_paraphemosis_result:
        this.props.data.genituorinary_paraphemosis_result,
      genituorinary_paraphemosis_desc:
        this.props.data.genituorinary_paraphemosis_desc,
      genituorinary_epispadias_result:
        this.props.data.genituorinary_epispadias_result,
      genituorinary_epispadias_desc:
        this.props.data.genituorinary_epispadias_desc,
      genituorinary_hypospadias_result:
        this.props.data.genituorinary_hypospadias_result,
      genituorinary_hypospadias_desc:
        this.props.data.genituorinary_hypospadias_desc,
      genituorinary_character_of_straem_result:
        this.props.data.genituorinary_character_of_straem_result,
      genituorinary_character_of_straem_desc:
        this.props.data.genituorinary_character_of_straem_desc,
      genituorinary_vaginal_discharge_abnormal_result:
        this.props.data.genituorinary_vaginal_discharge_abnormal_result,
      genituorinary_vaginal_discharge_abnormal_desc:
        this.props.data.genituorinary_vaginal_discharge_abnormal_desc,
      genituorinary_bladder_control_result:
        this.props.data.genituorinary_bladder_control_result,
      genituorinary_bladder_control_desc:
        this.props.data.genituorinary_bladder_control_desc,
      genituorinary_menstural_control_abnormal_result:
        this.props.data.genituorinary_menstural_control_abnormal_result,
      genituorinary_menstural_control_abnormal_desc:
        this.props.data.genituorinary_menstural_control_abnormal_desc,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeGeniRenalBruit =
      this.handleChangeGeniRenalBruit.bind(this);
  }

  componentDidUpdate(prevProps) {
    updateOnProps(
      prevProps,
      [
        "eyes",
        "teeth",
        "geni_Inspection",
        "geni_Palpation",
        "geni_Palpation_kidney",
        "geni_Palpation_ub",
        "geni_Palpation_rt",
        "geni_Palpation_genitalia",
        "geni_Percussion",
        "geni_Auscultation",
        "geni_renal_bruit",
        "genituorinary_fluidThrill_result",
        "genituorinary_fluidThrill_desc",
        "genituorinary_shiftingDullness_result",
        "genituorinary_shiftingDullness_desc",
        "genituorinary_colorOfUrin_result",
        "genituorinary_colorOfUrin_desc",
        "genituorinary_haematuria_result",
        "genituorinary_haematuria_desc",
        "genituorinary_frequency_result",
        "genituorinary_frequency_desc",
        "genituorinary_enuresis_result",
        "genituorinary_enuresis_desc",
        "genituorinary_Dysuria_result",
        "genituorinary_Dysuria_desc",
        "genituorinary_polyuria_result",
        "genituorinary_polyuria_desc",
        "genituorinary_frothy_result",
        "genituorinary_frothy_desc",
        "genituorinary_nocturia_result",
        "genituorinary_nocturia_desc",
        "genituorinary_abnormal_Genitalia_result",
        "genituorinary_abnormal_Genitalia_desc",
        "genituorinary_metal_Stenosis_result",
        "genituorinary_metal_Stenosis_desc",
        "genituorinary_phimosis_result",
        "genituorinary_phimosis_desc",
        "genituorinary_paraphemosis_result",
        "genituorinary_paraphemosis_desc",
        "genituorinary_epispadias_result",
        "genituorinary_epispadias_desc",
        "genituorinary_hypospadias_result",
        "genituorinary_hypospadias_desc",
        "genituorinary_character_of_straem_result",
        "genituorinary_character_of_straem_desc",
        "genituorinary_vaginal_discharge_abnormal_result",
        "genituorinary_vaginal_discharge_abnormal_desc",
        "genituorinary_bladder_control_result",
        "genituorinary_bladder_control_desc",
        "genituorinary_menstural_control_abnormal_result",
        "genituorinary_menstural_control_abnormal_desc",
      ],
      this,
    );
    if (
      this.props.data.geni_Auscultation !== prevProps.data.geni_Auscultation
    ) {
      const value =
        !!this.props.data.geni_Auscultation &&
        this.props.data.geni_Auscultation !== "false"
          ? "YES"
          : "NO";
      this.setState({
        geni_renal_bruit: value,
      });
    }
  }

  handleChange(e) {
    this.props.handleChange(e);
  }

  handlechange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeGeniRenalBruit(event) {
    if (event.target.value === "NO") {
      this.props.handleChange({
        target: { value: false, name: "geni_Auscultation" },
      });
    }
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            margin: "16px",
          }}
        >
          <div className={classes.divStyle}>
            <h2>8.1 Genitourinary</h2>
          </div>
          <br />
        </div>
        <div style={{ margin: "32px", marginBottom: "0px" }}>
          <h3>Inspection :</h3>
        </div>
        <React.Fragment>
          <FormControl
            component="fieldset"
            style={{ width: "100%", margin: "0px 32px 32px 32px" }}
          >
            <TextField
              value={this.state.geni_Inspection}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="geni_Inspection"
              fullWidth
            />
          </FormControl>
        </React.Fragment>
        <div style={{ margin: "32px", marginTop: "0px" }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Abnormal Genitalia</FormLabel>
            <RadioGroup
              aria-label="position"
              name="genituorinary_abnormal_Genitalia_result"
              label="genituorinary_abnormal_Genitalia_result"
              value={this.state.genituorinary_abnormal_Genitalia_result}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="PRESENT"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="ABSENT"
                labelPlacement="end"
              />

              <TextField
                label="description"
                value={this.state.genituorinary_abnormal_Genitalia_desc}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="genituorinary_abnormal_Genitalia_desc"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Meatal Stenosis</FormLabel>
            <RadioGroup
              aria-label="position"
              name="genituorinary_metal_Stenosis_result"
              label="genituorinary_metal_Stenosis_result"
              value={this.state.genituorinary_metal_Stenosis_result}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="PRESENT"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="ABSENT"
                labelPlacement="end"
              />

              <TextField
                label="description"
                value={this.state.genituorinary_metal_Stenosis_desc}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="genituorinary_metal_Stenosis_desc"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <React.Fragment>
          <FormControl
            style={{ width: "100%", margin: "32px", marginTop: "0px" }}
          >
            <InputLabel htmlFor="genituorinary_phimosis_result">
              Phimosis
            </InputLabel>
            <Select
              value={this.state.genituorinary_phimosis_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              name="genituorinary_phimosis_result"
              fullWidth
            >
              <MenuItem value={"Phimosis"}>Phimosis</MenuItem>
              <MenuItem value={"Paraphemosis"}>Paraphemosis</MenuItem>
              <MenuItem value={"Normal"}>Normal</MenuItem>
            </Select>
            {/* <FormHelperText>Please trace albumin for urine</FormHelperText> */}
          </FormControl>
        </React.Fragment>
        <React.Fragment>
          <FormControl
            style={{ width: "100%", margin: "32px", marginTop: "0px" }}
          >
            <InputLabel htmlFor="genituorinary_epispadias_result">
              Epispadias
            </InputLabel>
            <Select
              value={this.state.genituorinary_epispadias_result}
              onChange={this.handlechange}
              onBlur={this.handleChange}
              name="genituorinary_epispadias_result"
              fullWidth
            >
              <MenuItem value={"Epispadias"}>Epispadias</MenuItem>
              <MenuItem value={"Hypospadias"}>Hypospadias</MenuItem>
              <MenuItem value={"Normal"}>Normal</MenuItem>
            </Select>
            {/* <FormHelperText>Please trace albumin for urine</FormHelperText> */}
          </FormControl>
        </React.Fragment>
        <div style={{ margin: "32px", marginTop: "0px", marginBottom: "0px" }}>
          <h3>Palpation :</h3>
        </div>
        <React.Fragment>
          <FormControl
            component="fieldset"
            style={{ width: "100%", margin: "32px", marginTop: "0px" }}
          >
            <TextField
              label="Kidney"
              value={this.state.geni_Palpation_kidney}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="geni_Palpation_kidney"
              fullWidth
            />
          </FormControl>
        </React.Fragment>
        <React.Fragment>
          <FormControl
            component="fieldset"
            style={{ width: "100%", margin: "32px", marginTop: "0px" }}
          >
            <TextField
              label="UB"
              value={this.state.geni_Palpation_ub}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="geni_Palpation_ub"
              fullWidth
            />
          </FormControl>
        </React.Fragment>
        <React.Fragment>
          <FormControl
            component="fieldset"
            style={{ width: "100%", margin: "32px", marginTop: "0px" }}
          >
            <TextField
              label="Renal angle Tenderness"
              value={this.state.geni_Palpation_rt}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="geni_Palpation_rt"
              fullWidth
            />
          </FormControl>
        </React.Fragment>
        <React.Fragment>
          <FormControl
            component="fieldset"
            style={{ width: "100%", margin: "32px", marginTop: "0px" }}
          >
            <TextField
              label="Genitalia"
              value={this.state.geni_Palpation_genitalia}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="geni_Palpation_genitalia"
              fullWidth
            />
          </FormControl>
        </React.Fragment>
        <div
          style={{
            margin: "32px",
            marginTop: "0px",
            marginBottom: "0px",
            width: "100%",
          }}
        >
          <h3>Percussion :</h3>
          <FormControl component="fieldset">
            <FormLabel component="legend">Fluid Thrills</FormLabel>
            <RadioGroup
              aria-label="position"
              name="genituorinary_fluidThrill_result"
              label="Fluid Thrills"
              value={this.state.genituorinary_fluidThrill_result}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="PRESENT"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="ABSENT"
                labelPlacement="end"
              />

              <TextField
                label="description"
                value={this.state.genituorinary_fluidThrill_desc}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="genituorinary_fluidThrill_desc"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Shifting dullness</FormLabel>
            <RadioGroup
              aria-label="position"
              name="genituorinary_shiftingDullness_result"
              label="Shifting dullness"
              value={this.state.genituorinary_shiftingDullness_result}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="PRESENT"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="ABSENT"
                labelPlacement="end"
              />

              <TextField
                label="description"
                value={this.state.genituorinary_shiftingDullness_desc}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                margin="normal"
                name="genituorinary_shiftingDullness_desc"
                className={classes.textField}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div style={{ margin: "32px", marginTop: "0px", marginBottom: "0px" }}>
          <h3>Auscultation :</h3>
        </div>
        <React.Fragment>
          <FormControl
            component="fieldset"
            style={{ width: "100%", margin: "32px", marginTop: "0px" }}
          >
            <FormLabel component="legend">Renal Bruit</FormLabel>
            <RadioGroup
              aria-label="position"
              name="geni_renal_bruit"
              value={this.state.geni_renal_bruit}
              onBlur={this.handleChange}
              onChange={this.handleChangeGeniRenalBruit}
              row
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="secondary" />}
                label="PRESENT"
                labelPlacement="end"
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="secondary" />}
                label="ABSENT"
                labelPlacement="end"
              />
            </RadioGroup>
            {this.state.geni_renal_bruit === "YES" ? (
              <RadioGroup
                aria-label="position"
                name="geni_Auscultation"
                value={this.state.geni_Auscultation}
                onBlur={this.handleChange}
                onChange={this.handlechange}
                row
              >
                <FormControlLabel
                  value={"Left"}
                  control={<Radio color="secondary" />}
                  label="Left"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"Right"}
                  control={<Radio color="secondary" />}
                  label="Right"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"Both"}
                  control={<Radio color="secondary" />}
                  label="Both"
                  labelPlacement="end"
                />
              </RadioGroup>
            ) : null}
            {/* <TextField
              value={this.state.geni_Auscultation}
              onBlur={this.handleChange}
              onChange={this.handlechange}
              margin="normal"
              name="geni_Auscultation"
              fullWidth
            /> */}
          </FormControl>
        </React.Fragment>
        <div className={classes.spacingNew}></div>
      </div>
    );
  }
}

export default withStyles(style)(Genituonary);
