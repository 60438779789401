import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";

const style = (_) => ({
  tabsContainer: {
    width: "100%",
    backgroundColor: "rgba(224,224,224,0.1)",
    display: "flex",
    height: 50,
  },
  tab: {
    width: 176,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  active: {
    color: "#424242",
    borderBottom: "2px solid #8bc34a",
  },
  inactive: {
    color: "#BDBDBD",
  },
});

export const ETabs = {
  indoor: "indoor",
  outdoor: "outdoor",
  other: "other",
};

const FilterTabs = (props) => {
  const { classes, onChangeTab } = props;
  const [selectedTab, setSelectedTab] = useState(props.selectedTab);
  const onSelectTab = (tab) => {
    setSelectedTab(tab);
    onChangeTab(tab);
  };
  return (
    <div className={classes.tabsContainer}>
      {Object.values(ETabs).map((tab) => {
        const tabClassNames = [classes.tab];
        if (tab === selectedTab) {
          tabClassNames.push(classes.active);
        } else {
          tabClassNames.push(classes.inactive);
        }
        return (
          <div
            className={tabClassNames.join(" ")}
            onClick={() => onSelectTab(tab)}
            key={tab}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
};
export default withStyles(style)(FilterTabs);
