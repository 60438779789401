import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import "../patient/Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
    width: "65%",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },

  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  App: {
    display: "flex",
    height: "100%",
  },
  Content: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    margin: theme.spacing(1),
  },
  formContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  buttonsContainer: {
    display: "flex",
  },
});

class ViewPatient extends Component {
  componentDidMount() {
    const id = this.props.patientId;
    axios
      .get(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") + `/api/patient/${id}`
      )
      .then((res) => {
        this.setState({ ...res.data });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      gender: "",
      phone: "",
      height: "",
      weight: "",
      blood_pressure: "",
      nationality: "",
      age: "",
      age_in_months: "",
      age_in_days: "",
      religion: "",
      f_name: "",
      f_occupation: "",
      f_education: "",
      m_name: "",
      m_occupation: "",
      m_education: "",
      address: "",
      p_address: "",
      redirectState: false,
      birthDate: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    axios
      .put(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") + `/api/patient/${this.props.location.state}`,
        this.state
      )
      .then(() => {
        this.setState({ redirectState: true });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const { classes } = this.props;
    const linkStyle = {
      textDecoration: "none",
      color: "white",
    };

    return (
      <div className={classes.App}>
        <div className={classes.Content}>
          <div className="Patient_Create">
            <div
              className="patientAddFormContainedinInfo"
              style={{ background: "white" }}
            >
              <form
                action=""
                className={classes.root}
                onSubmit={this.handleSubmit}
              >
                <div className={classes.formContent}>
                  {/* //personal */}
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ fontSize: "16px" }}
                  >
                    Personal Information :
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "24px 16px 16px 0px",
                      height: "200px",
                    }}
                  >
                    {/* start */}
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="First Name">
                        First Name
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.fname}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Last Name">
                        Last Name
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.lname}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="gender">
                        Gender
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.gender}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Phone">
                        Phone
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.phone}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Height">
                        Height in CM
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.height}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="weight">
                        Weight
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.weight}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="blood_pressure">
                        Blood Pressure
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.blood_pressure}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Nationality">
                        Nationality
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.nationality}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Age">
                        Age in Years
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.age}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Age">
                        Age in Months
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.age_in_months}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Age">
                        Age in Days
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.age_in_days}</p>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Religion">
                        Religion
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.religion}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="BirthDate">
                        Birth Date
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.birthDate}</p>
                    </Box>

                    {/* end */}
                  </div>

                  {/* //parents */}
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ fontSize: "16px" }}
                  >
                    Parents Information :
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "24px 16px 16px 0px",
                      height: "100px",
                    }}
                  >
                    {/* start */}
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Father's Name">
                        Father's Name
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.f_name}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Father's Occupation">
                        Father's Occupation
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.f_occupation}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Father's Education">
                        Father's Education
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.f_education}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Mother's Name">
                        Mother's Name
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.m_name}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Mother's Occupation">
                        Mother's Occupation
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.m_occupation}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Mother's Education">
                        Mother's Education
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.m_education}</p>
                    </Box>
                    {/* end */}
                  </div>

                  {/* //Address */}
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ fontSize: "16px" }}
                  >
                    Address :
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      margin: "24px 16px 16px 0px",
                      height: "150px",
                    }}
                  >
                    {/* start */}
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "350px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Present Address">
                        Present Address
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.address}</p>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "350px",
                        marginTop: "50px",
                      }}
                    >
                      <InputLabel shrink htmlFor="Permanent Address">
                        Permanent Address
                      </InputLabel>
                      <p style={{ margin: 0 }}>{this.state.p_address}</p>
                    </Box>

                    {/* end */}
                  </div>
                </div>

                <div className={classes.buttonsContainer}>
                  <Link
                    to={{
                      pathname: "/patientList",
                      state: "true",
                    }}
                    style={linkStyle}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.button}
                    >
                      back
                    </Button>
                  </Link>

                  <Link
                    to={{
                      pathname: "/editpatient",
                      state: this.props.patientId,
                      prevPath: "/vinfo",
                    }}
                    style={linkStyle}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.submitbutton}
                      type="submit"
                      name="submit"
                    >
                      <span className="white">Edit</span>
                    </Button>
                  </Link>
                </div>
                {/* //end */}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(ViewPatient));
