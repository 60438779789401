import React, { Component } from "react";
import axios from "axios";
import {
  Backdrop,
  Button,
  CircularProgress,
  TextField,
  Select,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import "./Create-patient.css";
import { lightGreen } from "@material-ui/core/colors";
import SearchAppBar from "../layout/Appbar";
import { Link, withRouter } from "react-router-dom";
import NavBar from "../layout/Navbar";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const style = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    background: "white",
    padding: 32,
    width: "90%",
    borderRadius: 8,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1),
    width: 250,
  },
  addressTextField: {
    margin: theme.spacing(1),
    width: 250,
  },
  menu: {
    width: 150,
  },
  submitbutton: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  links: {
    textDecoration: "none",
    color: "white",
  },
  App: {
    display: "flex",
    height: "100%",
  },
  Content: {
    width: "100%",
  },
});

class Patient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      fname: "",
      lname: "",
      gender: "",
      phone: "",
      phoneOptional: "",
      height: "",
      weight: "",
      blood_pressure: "",
      nationality: "",
      age: "",
      age_in_months: "",
      age_in_days: "",
      religion: "",
      f_name: "",
      f_occupation: "",
      f_education: "",
      residence: "",
      socioeconomy: "",
      monthly_income: "",
      m_name: "",
      m_occupation: "",
      m_education: "",
      address: "",
      p_address: "",
      admitted: false,
      redirectState: false,
      editPatient: this.props.location.pathname === "/editpatient",
      loading: this.props.location.pathname === "/editpatient",
      birthDate: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getID = this.getID.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the specific state variable has changed
    if (this.state.birthDate !== prevState.birthDate) {
      const today = moment();

      const birthMoment = moment(this.state.birthDate);
      const years = today.diff(birthMoment, "years");
      birthMoment.add(years, "years");

      const months = today.diff(birthMoment, "months");
      birthMoment.add(months, "months");

      const days = today.diff(birthMoment, "days");
      this.setState({ age: years, age_in_months: months, age_in_days: days });
    }
  }

  componentDidMount() {
    if (this.state.editPatient) {
      const id = this.props.location.state?.id;
      axios
        .get(
          (process.env.NODE_ENV === "development"
            ? "http://localhost:40000"
            : "") + `/api/patient/${id}`,
        )
        .then((res) => {
          const patientData = res.data;
          // Convert null values to empty strings
          Object.keys(patientData).map((key) => {
            if (patientData[key] === null) {
              patientData[key] = "";
            }
          });
          this.setState({ ...patientData });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  formValid = () => {
    // Mandatory fields = First Name, Last Name,  Gender, Phone, Age in Years, Age in Months, Age in Days, Present Address
    return (
      this.state.fname !== "" &&
      this.state.lname !== "" &&
      this.state.gender !== "" &&
      this.state.phone !== "" &&
      this.state.age !== "" &&
      this.state.age_in_months !== "" &&
      this.state.age_in_days !== "" &&
      this.state.address !== ""
    );
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if (this.formValid()) {
      this.setState({ id: this.getID(), loading: true }, () => {
        // Send a POST request
        axios({
          method: this.state.editPatient ? "put" : "post",
          url:
            (process.env.NODE_ENV === "development"
              ? "http://localhost:40000"
              : "") +
            `/api/patient${
              this.state.editPatient ? `/${this.props.location.state?.id}` : ``
            }`,
          data: this.state,
        })
          .then((_) => {
            this.props.history.push({
              pathname: !this.state.editPatient
                ? "/patientList"
                : this.props.location.prevPath,
              state: this.props.location.state || "",
              prevPath: "/editPatient",
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      });
    }
  }

  getID() {
    var idx = Math.round(+new Date() + Math.random() * 1000000);
    const dateNow = new Date();
    const year = dateNow.getFullYear();
    const month =
      (dateNow.getUTCMonth() + 1).toString().length < 2
        ? `0${dateNow.getUTCMonth() + 1}`
        : (dateNow.getUTCMonth() + 1).toString();

    return `BSMMU-PN-${month}${year
      .toString()
      .slice(
        -2,
      )}-${this.state.fname[0].toUpperCase()}${this.state.lname[0].toUpperCase()}${
      this.state.age
    }-${idx % 10000}`;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.App}>
        {/* Full Screen Loader */}
        <Backdrop open={this.state.loading} style={{ zIndex: 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          <NavBar />
        </div>
        <div className={classes.Content}>
          <div className="Patient_Create">
            <div className="layout">
              <div className="containesContent">
                <div className="patientAddFormHasSearch">
                  <SearchAppBar />
                </div>
                <div className="patientAddFormContained">
                  <Paper elevation={1}>
                    <form
                      action=""
                      className={classes.root}
                      onSubmit={this.handleSubmit}
                    >
                      <div className="styleddiv">
                        <h3>{`${
                          this.state.editPatient ? `Edit` : `Create`
                        } Patient`}</h3>
                      </div>
                      <Divider />
                      <div className="patientFormHoldsbasicInfo">
                        <TextField
                          required
                          label="First Name"
                          value={this.state.fname}
                          onChange={this.handleChange}
                          margin="normal"
                          name="fname"
                          className={classes.textField}
                        />
                        <TextField
                          required
                          label="Last Name"
                          value={this.state.lname}
                          onChange={this.handleChange}
                          margin="normal"
                          name="lname"
                          className={classes.textField}
                        />
                        <FormControl required className={classes.formControl}>
                          <InputLabel htmlFor="Gender">Gender</InputLabel>
                          <Select
                            value={this.state.gender}
                            onChange={this.handleChange}
                            name="gender"
                            required
                          >
                            <MenuItem value={"male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                            <MenuItem value={"Others"}>Others</MenuItem>
                            <MenuItem value={"Not Preferred"}>
                              Not Preferred
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Your Gender
                          </FormHelperText>
                        </FormControl>
                        <TextField
                          required
                          label="Phone"
                          value={this.state.phone}
                          onChange={this.handleChange}
                          margin="normal"
                          name="phone"
                          className={classes.textField}
                        />
                        <TextField
                          label="Phone(Optional)"
                          value={this.state.phoneOptional}
                          onChange={this.handleChange}
                          margin="normal"
                          name="phoneOptional"
                          className={classes.textField}
                        />
                        <TextField
                          label="Height"
                          placeholder="In CM"
                          value={this.state.height}
                          onChange={this.handleChange}
                          className={classes.textField}
                          margin="normal"
                          name="height"
                          type="number"
                        />
                        <TextField
                          label="Weight"
                          placeholder="In KG"
                          value={this.state.weight || ""}
                          onChange={this.handleChange}
                          className={classes.textField}
                          margin="normal"
                          name="weight"
                          type="number"
                        />
                        <TextField
                          label="Blood Pressure"
                          value={this.state.blood_pressure}
                          onChange={this.handleChange}
                          margin="normal"
                          name="blood_pressure"
                          className={classes.textField}
                        />
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="Nationality">
                            Nationality
                          </InputLabel>
                          <Select
                            value={this.state.nationality}
                            onChange={this.handleChange}
                            name="nationality"
                          >
                            <MenuItem value={"Bangladeshi"}>
                              Bangladeshi
                            </MenuItem>
                            <MenuItem value={"others"}>others</MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Your Nationality
                          </FormHelperText>
                        </FormControl>
                        <TextField
                          id="date"
                          label="Birthday"
                          type="date"
                          defaultValue="2017-05-24"
                          value={this.state.birthDate}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={this.handleChange}
                          name="birthDate"
                        />

                        <TextField
                          required
                          label="Age in years"
                          value={this.state.age}
                          onChange={this.handleChange}
                          margin="normal"
                          name="age"
                          className={classes.textField}
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                        />

                        <TextField
                          required
                          label="Age in months"
                          value={this.state.age_in_months}
                          onChange={this.handleChange}
                          margin="normal"
                          name="age_in_months"
                          className={classes.textField}
                          type="number"
                          InputProps={{ inputProps: { min: 0, max: 12 } }}
                        />

                        <TextField
                          required
                          label="Age in Days"
                          value={this.state.age_in_days}
                          onChange={this.handleChange}
                          margin="normal"
                          name="age_in_days"
                          className={classes.textField}
                          type="number"
                          InputProps={{ inputProps: { min: 0, max: 31 } }}
                        />

                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="Religion">Religion</InputLabel>
                          <Select
                            value={this.state.religion}
                            onChange={this.handleChange}
                            name="religion"
                          >
                            <MenuItem value={"Muslim"}>Muslim</MenuItem>
                            <MenuItem value={"Hindu"}>Hindu</MenuItem>
                            <MenuItem value={"Buddha"}>Buddha</MenuItem>
                            <MenuItem value={"Christian"}>Christian</MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Your Religion
                          </FormHelperText>
                        </FormControl>
                        <TextField
                          label="Father's Name"
                          value={this.state.f_name}
                          onChange={this.handleChange}
                          margin="normal"
                          name="f_name"
                          className={classes.textField}
                        />
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="f_occupation">
                            Father's Occupation
                          </InputLabel>
                          <Select
                            value={this.state.f_occupation}
                            onChange={this.handleChange}
                            name="f_occupation"
                          >
                            <MenuItem value={"Service Holder"}>
                              Service Holder
                            </MenuItem>
                            <MenuItem value={"Businessman"}>
                              Businessman
                            </MenuItem>
                            <MenuItem value={"Others"}>Others</MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Occupation
                          </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="f_education">
                            Father's Education
                          </InputLabel>
                          <Select
                            value={this.state.f_education}
                            onChange={this.handleChange}
                            name="f_education"
                          >
                            <MenuItem value={"Illiterate"}>Illiterate</MenuItem>
                            <MenuItem value={"Primary"}>Primary</MenuItem>
                            <MenuItem value={"SSC"}>SSC</MenuItem>
                            <MenuItem value={"HSC"}>HSC</MenuItem>
                            <MenuItem value={"Graduate"}>Graduate</MenuItem>
                            <MenuItem value={"Post Graduate"}>
                              Post Graduate
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Education
                          </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="residence">Residence</InputLabel>
                          <Select
                            value={this.state.residence}
                            onChange={this.handleChange}
                            name="residence"
                          >
                            <MenuItem value={"Rural"}>Rural</MenuItem>
                            <MenuItem value={"Urban"}>Urban</MenuItem>
                            <MenuItem value={"Urban Slum"}>Urban Slum</MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Residence
                          </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="socioeconomy">
                            Socioeconomic Status
                          </InputLabel>
                          <Select
                            value={this.state.socioeconomy}
                            onChange={this.handleChange}
                            name="socioeconomy"
                          >
                            <MenuItem value={"Poor"}>Poor</MenuItem>
                            <MenuItem value={"Lower Middle"}>
                              Lower Middle
                            </MenuItem>
                            <MenuItem value={"Upper Middle"}>
                              Upper Middle
                            </MenuItem>
                            <MenuItem value={"Higher"}>Higher</MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Socioeconomic status
                          </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="monthly_income">
                            Monthly Income
                          </InputLabel>
                          <Select
                            value={this.state.monthly_income}
                            onChange={this.handleChange}
                            name="monthly_income"
                          >
                            <MenuItem value={"<5000"}>&lt;5000</MenuItem>
                            <MenuItem value={"5000-10,000"}>
                              5000-10,000
                            </MenuItem>
                            <MenuItem value={"10,000-15,000"}>
                              10,000-15,000
                            </MenuItem>
                            <MenuItem value={">15,000"}>&gt;15,000</MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Monthly Income
                          </FormHelperText>
                        </FormControl>
                        <TextField
                          label="Mother's Name"
                          value={this.state.m_name}
                          onChange={this.handleChange}
                          margin="normal"
                          name="m_name"
                          className={classes.textField}
                        />
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="m_occupation">
                            Mother's Occupation
                          </InputLabel>
                          <Select
                            value={this.state.m_occupation}
                            onChange={this.handleChange}
                            name="m_occupation"
                          >
                            <MenuItem value={"Service Holder"}>
                              Service Holder
                            </MenuItem>
                            <MenuItem value={"Businessman"}>
                              Businessman
                            </MenuItem>
                            <MenuItem value={"Others"}>Housewife</MenuItem>
                            <MenuItem value={"Others"}>Others</MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Occupation
                          </FormHelperText>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="m_education">
                            Mother's Education
                          </InputLabel>
                          <Select
                            value={this.state.m_education}
                            onChange={this.handleChange}
                            name="m_education"
                          >
                            <MenuItem value={"Illiterate"}>Illiterate</MenuItem>
                            <MenuItem value={"Primary"}>Primary</MenuItem>
                            <MenuItem value={"SSC"}>SSC</MenuItem>
                            <MenuItem value={"HSC"}>HSC</MenuItem>
                            <MenuItem value={"Graduate"}>Graduate</MenuItem>
                            <MenuItem value={"Post Graduate"}>
                              Post Graduate
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            Please Select Education
                          </FormHelperText>
                        </FormControl>
                        <TextField
                          required
                          label="Present Address"
                          value={this.state.address}
                          onChange={this.handleChange}
                          margin="normal"
                          name="address"
                          className={classes.addressTextField}
                        />

                        <TextField
                          label="Permanent Address"
                          value={this.state.p_address}
                          onChange={this.handleChange}
                          margin="normal"
                          name="p_address"
                          className={classes.addressTextField}
                        />
                      </div>
                      <br />
                      <div className="patientFormHoldsButtons">
                        <Link
                          to={{
                            pathname: "/patientList",
                            state: "true",
                          }}
                          className={classes.links} //linkStyle
                        >
                          <Button
                            variant="contained"
                            size="large"
                            className={classes.button}
                          >
                            back
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          size="large"
                          className={classes.submitbutton}
                          type="submit"
                          name="submit"
                          disabled={!this.formValid()}
                        >
                          <span className="white">Submit</span>
                        </Button>
                      </div>
                    </form>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(Patient));
