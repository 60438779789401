import React, { Component } from "react";
import PropTypes from "prop-types";
//withStyles
import { withStyles } from "@material-ui/core";
//router
import { withRouter } from "react-router-dom";
//axios
import Axios from "axios";
//redirect
//pagination and footer
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
//table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//paper
import Paper from "@material-ui/core/Paper";
//icons
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/ArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ArrowRight";
//edit and view
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Loader from "../layout/Loader";
import Searchbar from "../layout/TableSearch";
import Icon from "@material-ui/core/Icon";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

class TablePaginationActions extends Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };
  render() {
    const { classes, page, count, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={this.handleNextButtonClick}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: "300px",
  },
  tableWrapper: {
    overflow: "auto",
    minWidth: "300px",
  },
  textDecore: {
    textDecoration: "none",
  },
  cellAction: {
    cursor: "pointer",
  },
});

class CustomPaginationActionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 10,
      currentModalData: "",
      loader: true,
      redirect: false,
      redirectView: false,
      redirectAddVisit: false,
      searched: false,
      count: 0,
      searchBy: "id",
      searchValue: "",
    };
  }
  //
  componentDidMount() {
    if (this.props.diagnosis) {
      this.setState({ loader: true });
      Axios.post(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") + `/api/patient/diagnosis`,
        {
          diagnosis: this.props.diagnosis,
          typeOfEnrollment: this.props.typeOfEnrollment,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        },
      )
        .then((data) => {
          this.setState({
            rows: [...data.data?.patients],
            loader: false,
            count: data.data?.count,
          });
        })
        .catch((err) => {
          this.setState({ loader: false });
        });
    } else {
      this.setState({ loader: true });
      Axios.get(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") + `/api/tvisit?rowsPerPage=${this.state.rowsPerPage}`,
      )
        .then((data) => {
          this.setState({
            rows: [...data.data.allPatients],
            loader: false,
            count: data.data.count,
          });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loader: false });
        });
    }
  }

  handleChangePage = (_, page) => {
    if (this.props.diagnosis) {
      this.setState({ loader: true });
      Axios.post(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") + `/api/patient/diagnosis`,
        {
          diagnosis: this.props.diagnosis,
          typeOfEnrollment: this.props.typeOfEnrollment,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
          page,
        },
      )
        .then((data) => {
          this.setState({
            rows: [...data.data?.patients],
            loader: false,
            page,
          });
        })
        .catch((err) => {
          this.setState({ loader: false });
        });
    } else {
      this.setState({ loader: true }, () => {
        let route =
          (process.env.NODE_ENV === "development"
            ? "http://localhost:40000"
            : "") +
          `/api/tvisit?page=${page}&rowsPerPage=${this.state.rowsPerPage}`;
        if (this.state.searched) {
          route =
            (process.env.NODE_ENV === "development"
              ? "http://localhost:40000"
              : "") +
            `/api/search?q=${this.state.searchValue}&field=${this.state.searchBy}&page=${page}&rowsPerPage=${this.state.rowsPerPage}`;
        }
        Axios.get(route)
          .then((data) => {
            if (data.data.length === 0) {
              this.setState({ loader: false });
            } else {
              this.setState({
                rows: [...data.data.allPatients],
                loader: false,
                page,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            this.setState({ loader: false });
          });
      });
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: Number.parseInt(event.target.value),
    });
    this.handleChangePage(null, 0);
  };

  handlePageChange = () => {
    this.props.history.push(this.props.url);
  };
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleTableIconClick = (path, id, pageAction) => {
    this.props.history.push({
      pathname: path,
      state: { id, pageAction, patientId: id },
      prevPath: `/patientList`,
    });
  };

  handleSearch = (data, field) => {
    this.setState({
      loader: true,
      searchBy: field,
      searchValue: data,
      page: 0,
    });
    try {
      Axios.get(
        (process.env.NODE_ENV === "development"
          ? "http://localhost:40000"
          : "") +
          `/api/search?q=${data}&field=${field}&rowsPerPage=${this.state.rowsPerPage}`,
      )
        .then((res) => {
          if (res.data.length === 0) {
            alert("No such patient found");
            this.setState({ loader: false });
          } else {
            try {
              this.setState({
                rows: [...res.data.allPatients],
                loader: false,
                count: res.data.count,
                searched: true,
              });
            } catch (err) {
              console.error(err);
              alert("No such patient found");
              this.setState({ loader: false });
            }
          }
        })
        .catch((err) => console.error(err));
    } catch {
      this.setState({ loader: false });
    }
  };

  handleClear = () => {
    this.setState({ loader: true, page: 0 });
    Axios.get(
      (process.env.NODE_ENV === "development" ? "http://localhost:40000" : "") +
        `/api/tvisit?rowsPerPage=${this.state.rowsPerPage}`,
    )
      .then((data) => {
        this.setState({
          rows: [...data.data.allPatients],
          loader: false,
          count: data.data.count,
          searched: false,
          searchValue: "",
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loader: false });
      });
  };
  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    if (!this.state.loader) {
      return (
        <Paper className={classes.root} elevation={1}>
          <div className={classes.tableWrapper}>
            {!this.props.diagnosis ? (
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div className="SearchConatiner">
                  <Searchbar
                    count={this.state.count}
                    searchValue={this.state.searchValue}
                    searchBy={this.state.searchBy}
                    handleSearch={this.handleSearch}
                    handleClear={this.handleClear}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                Patients
              </div>
            )}
            <div
              style={{
                minWidth: "300px",
                overflowX: "auto",
                paddingLeft: "16px",
              }}
            >
              <Table className={classes.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">First Name</TableCell>
                    <TableCell align="left">Last Name</TableCell>
                    <TableCell align="left">Age</TableCell>
                    {!this.props.diagnosis && (
                      <TableCell align="left">Contact</TableCell>
                    )}
                    {!this.props.diagnosis && (
                      <TableCell align="left">Total Visits</TableCell>
                    )}
                    <TableCell align="left">View</TableCell>
                    {!this.props.diagnosis && (
                      <TableCell align="left">Edit</TableCell>
                    )}
                    {!this.props.diagnosis && (
                      <TableCell align="left">Add visit</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow hover key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.fname}</TableCell>
                      <TableCell align="left">{row.lname}</TableCell>
                      <TableCell align="left">
                        {row.age} years{" "}
                        {row.age_in_months !== 0 &&
                        row.age_in_months !== undefined
                          ? row.age_in_months + " months"
                          : ""}
                      </TableCell>
                      {!this.props.diagnosis && (
                        <TableCell align="left">{row.phone}</TableCell>
                      )}
                      {!this.props.diagnosis && (
                        <TableCell align="left">{row.visit}</TableCell>
                      )}
                      <TableCell align="left">
                        <VisibilityIcon
                          className={classes.cellAction}
                          onClick={() =>
                            this.handleTableIconClick(`/vinfo`, row._id)
                          }
                        />
                      </TableCell>
                      {!this.props.diagnosis && (
                        <TableCell align="left">
                          <EditIcon
                            className={classes.cellAction}
                            onClick={() =>
                              this.handleTableIconClick(`/editpatient`, row._id)
                            }
                          />
                        </TableCell>
                      )}
                      {!this.props.diagnosis && (
                        <TableCell>
                          <Icon
                            className={classes.cellAction}
                            color="secondary"
                            style={{ fontSize: 30 }}
                            onClick={() =>
                              this.handleTableIconClick(
                                `/addvisit`,
                                row._id,
                                "create",
                              )
                            }
                          >
                            add_circle
                          </Icon>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {{ emptyRows } > 0 && (
                    <TableRow style={{ height: 48 * { emptyRows } }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      colSpan={8}
                      count={this.state.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.root} elevation={0}>
          <div className={classes.tableWrapper}>
            {/* <div className="SearchConatiner">
              <Searchbar
                searchValue={this.state.searchValue}
                searchBy={this.state.searchBy}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </div> */}
            <Loader />
          </div>
        </Paper>
      );
    }
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CustomPaginationActionsTable));
