import {
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";
import React from "react";

const style = (theme) => ({
  filtersContainer: {
    margin: "24px 32px 32px 32px",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    fontSize: "20px",
    fontWeight: "normal",
    color: grey[800],
    fontFamily: "Nunito Sans",
  },
  filtersMenu: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    gap: 64,
  },
  dropdowns: {
    display: "flex",
    gap: 32,
  },
  buttons: {
    display: "flex",
    gap: 24,
  },
  buttonPrimary: {
    color: "white",
    fontFamily: "Nunito Sans",
    fontWeight: "semi-bold",
    paddingLeft: 32,
    paddingRight: 32,
  },
  buttonSecondary: {
    color: grey[600],
    fontFamily: "Nunito Sans",
    fontWeight: "semi-bold",
    paddingLeft: 32,
    paddingRight: 32,
  },
});
export const filtersFormId = "filters-form"
const Filters = (props) => {
  const { classes, onSubmitFilters, clearFilters, startDate, endDate } = props;
  return (
    <div className={classes.filtersContainer}>
      <Typography className={classes.title} variant="h6" noWrap>
        Filter By
      </Typography>
      <form
        id={filtersFormId}
        className={classes.filtersMenu}
        onSubmit={onSubmitFilters}
      >
        <div className={classes.dropdowns}>
          <Select name="group" variant="outlined" value="Diagnosis">
            <MenuItem value="Diagnosis">Diagnosis</MenuItem>
          </Select>
          <TextField
            defaultValue={startDate}
            name="startDate"
            label="Start Date"
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            defaultValue={endDate}
            name="endDate"
            label="End Date"
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.buttons}>
          <Button
            className={classes.buttonPrimary}
            color="secondary"
            variant="contained"
            type="Submit"
            value="Submit"
            name="submit"
          >
            SUBMIT
          </Button>
          <Button
            className={classes.buttonSecondary}
            color="default"
            variant="contained"
            name="clear"
            onClick={clearFilters}
          >
            CLEAR
          </Button>
        </div>
      </form>
    </div>
  );
};
export default withStyles(style)(Filters);
